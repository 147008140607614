import React, { Fragment, useContext, useEffect, useState } from 'react';
import Modal from '../../ui/modal';
import { DesignData } from '../../types';
import Status from '../../ui/status';
import SummaryComponent from '../summary-component';
import { UseAuth } from '../auth-component/auth-provider';
import {
  DesignDataContext,
  DesignDataProvider,
} from '../../common/context/designData';
import Text from '../../ui/text';
import Button from '../../ui/button';

interface DesignQuickViewModalProps {
  triggerShow: number;
  designId: string;
}

const DesignQuickViewModal = ({
  triggerShow,
  designId,
}: DesignQuickViewModalProps) => {
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { handleRelocate } = UseAuth();
  const { designData, loading, error } = useContext(DesignDataContext);

  useEffect(() => {
    if (triggerShow) {
      setShow(true);
    }
  }, [triggerShow]);

  const handleCancel = () => {
    setShow(false);
  };

  const handleSave = () => {
    handleRelocate(`design-view/${designId}`);
  };

  return (
    <Modal
      onClose={handleCancel}
      title={
        <Fragment>
          <Text
            size="m"
            fontWeight="600"
            textAlign="center"
            truncate={true}
            style={{ width: '85%' }}
          >
            {designData.design_details.design_name || 'Design summary'}
          </Text>
        </Fragment>
      }
      buttons={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Button
            variant="secondary"
            onClick={handleCancel}
            style={{ width: 90 }}
          >
            Cancel
          </Button>
          <Button variant="primary" style={{ width: 150 }} onClick={handleSave}>
            Open design
          </Button>
        </div>
      }
      show={show}
      width="700px"
    >
      {loading || error ? (
        <Status
          loading={loading}
          error={error}
          errorMessage={errorMessage}
        ></Status>
      ) : (
        <SummaryComponent design={designData}></SummaryComponent>
      )}
    </Modal>
  );
};

export default DesignQuickViewModal;
