// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__rQAwm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.pricingContainer__WvtF1 {
  display: grid;
  width: 70%;
  grid-template-columns: 2.5fr 2fr 1fr;
  grid-row-gap: 10px;
}

.divider__JLWrb {
  border-bottom: 1px solid var(--background-light-blue);
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 4;
}

.discountTitle__Puib8 {
  display: flex;
  gap: 4px;
  align-items: center;
}

.tooltip__WTdAa {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* Optional style to indicate that this is a tooltip */
}

.tooltip__WTdAa > .tooltiptext__xYbhq {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip__WTdAa:hover > .tooltiptext__xYbhq {
  visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./src/components/total-price-form/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE,qDAAqD;EACrD,WAAW;EACX,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,+BAA+B,EAAE,sDAAsD;AACzF;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;;EAEd,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  flex-direction: column;\n}\n\n.pricingContainer {\n  display: grid;\n  width: 70%;\n  grid-template-columns: 2.5fr 2fr 1fr;\n  grid-row-gap: 10px;\n}\n\n.divider {\n  border-bottom: 1px solid var(--background-light-blue);\n  width: 100%;\n  grid-column-start: 1;\n  grid-column-end: 4;\n}\n\n.discountTitle {\n  display: flex;\n  gap: 4px;\n  align-items: center;\n}\n\n.tooltip {\n  position: relative;\n  display: inline-block;\n  border-bottom: 1px dotted black; /* Optional style to indicate that this is a tooltip */\n}\n\n.tooltip > .tooltiptext {\n  visibility: hidden;\n  width: 120px;\n  background-color: black;\n  color: #fff;\n  text-align: center;\n  border-radius: 6px;\n  padding: 5px 0;\n\n  /* Position the tooltip */\n  position: absolute;\n  z-index: 1;\n  bottom: 100%;\n  left: 50%;\n  margin-left: -60px;\n}\n\n.tooltip:hover > .tooltiptext {\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__rQAwm`,
	"pricingContainer": `pricingContainer__WvtF1`,
	"divider": `divider__JLWrb`,
	"discountTitle": `discountTitle__Puib8`,
	"tooltip": `tooltip__WTdAa`,
	"tooltiptext": `tooltiptext__xYbhq`
};
export default ___CSS_LOADER_EXPORT___;
