import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import styles from './style.module.css';
import Text from '../../ui/text';
import Icon from '../../ui/icon';
import { CSSTransition } from 'react-transition-group';
import './style.css';
import Button from '../button';

interface ToastMessageProps {
  icon?: React.ReactElement;
  variant?: 'info' | 'error';
  text: string;
  duration?: number;
  triggerShow?: number;
  closeable?: boolean;
  hide?: boolean;
  style?: CSSProperties;
}
const ToastMessage = ({
  variant = 'info',
  triggerShow,
  duration,
  icon,
  text,
  closeable,
  style,
  hide,
}: ToastMessageProps): React.ReactElement => {
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    if (triggerShow && duration != null) {
      setShow(true);
      const timer = setTimeout(() => setShow(false), duration);
      return () => clearTimeout(timer);
    }
  }, [triggerShow, duration]);

  const toastStyle = closeable
    ? { paddingRight: '32px' }
    : ({} as CSSProperties);

  const onClose = () => {
    setShow(false);
  };

  if (hide) {
    return <div></div>;
  }

  return (
    <CSSTransition in={show} timeout={300} classNames="fade" unmountOnExit>
      <div className={styles[variant]} style={{ ...style, ...toastStyle }}>
        <div>{icon || <Icon name="warningTriangle" fill="#e75151"></Icon>}</div>
        <Text size="xs" fontWeight="600">
          {text}
        </Text>
        {closeable && (
          <div className="close-button">
            <Button
              onClick={onClose}
              variant="close"
              style={{ backgroundColor: '#c6eaf1', padding: '5px 5px' }}
            >
              <Icon name="closeIcon" height="11px" width="11px"></Icon>
            </Button>
          </div>
        )}
      </div>
    </CSSTransition>
  );
};
export default ToastMessage;
