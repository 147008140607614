import React, { CSSProperties, useContext, useMemo } from 'react';
import Text from '../../ui/text';
import styles from './style.module.css';
import Select, { components } from 'react-select';
import { DropdownOption } from '../../types';
import { noop, upperFirst } from 'lodash';
import classNames from 'classnames';
import { ColorConfigKey, colorsConfig } from '../../common/design/colors';
import { DesignDataContext } from '../../common/context/designData';
import Icon from '../../ui/icon';

const { Option } = components;

interface OrderStatusDropdownProps {
  containerStyle: CSSProperties;
  onChange?: (status: string) => void;
}

export const STATUS_OPTIONS = ['proposal', 'quoted', 'won', 'lost'];

const getOptionFromValue = (status: string) => {
  return {
    value: status,
    label: upperFirst(status),
  };
};

const STATUS_DROPDOWN_OPTIONS = STATUS_OPTIONS.map(getOptionFromValue);

const CustomOption = (props: any) => {
  const optionClass = classNames({
    [styles.option]: true,
    [styles.selected]: props.isSelected,
    [styles[props.data.value]]: true,
  });
  return (
    <Option {...props}>
      <div className={optionClass} style={{ fontWeight: 'normal' }}>
        <Text size="s" color={`status-${props.data.value}`}>
          {props.data.label}
        </Text>
      </div>
    </Option>
  );
};

const CustomDropdownIcon = (props: any) => {
  return (
    <div className={styles.dropdownIconContainer}>
      <Icon
        name="caretV2"
        fill="white"
        height="12px"
        width="auto"
        rotation={props.selectProps.menuIsOpen ? 0 : 180}
      ></Icon>
    </div>
  );
};

const OrderStatusDropdown = ({
  containerStyle,
  onChange = noop,
}: OrderStatusDropdownProps): React.ReactElement => {
  const { designData, updateDesignData } = useContext(DesignDataContext);

  const handleSelect = (option: DropdownOption | null) => {
    if (typeof option?.value !== 'number') {
      updateDesignData({ status: option?.value || undefined });
      if (option?.value) {
        onChange(option?.value);
      }
    }
  };

  const selectedOption = useMemo(() => {
    if (!designData.status) {
      return undefined;
    }
    return getOptionFromValue(designData.status);
  }, [designData.status]);

  const optionBackgroundColor = selectedOption?.value
    ? colorsConfig[
        `status-background-${selectedOption.value}` as ColorConfigKey
      ]
    : 'white';

  return (
    <div style={containerStyle}>
      <Select
        options={STATUS_DROPDOWN_OPTIONS}
        onChange={handleSelect}
        value={selectedOption}
        placeholder="Select status"
        noOptionsMessage={() => {
          const message = 'No status options available';
          return <Text size="m">{message}</Text>;
        }}
        isSearchable={false}
        components={{
          Option: CustomOption,
          IndicatorsContainer: CustomDropdownIcon,
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            fontFamily: "'Helvetica Neue', sans-serif;",
            width: '100%',
            flexWrap: 'nowrap',
            boxShadow: state.isFocused ? 'none' : baseStyles.boxShadow,
            height: 36,
            borderColor: state.isFocused
              ? 'hsl(0, 0%, 80%)'
              : baseStyles.borderColor,
            '&:hover': {
              borderColor: 'hsl(0, 0%, 80%)', // Custom border color on hover
              boxShadow: 'none', // Ensure no shadow on hover
            },
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            fontFamily: "'Helvetica Neue', sans-serif;",
            backgroundColor: optionBackgroundColor,
            color: selectedOption?.value
              ? colorsConfig[`status-${selectedOption.value}` as ColorConfigKey]
              : 'white',
            padding: '4px',
            fontSize: '14px',
            borderRadius: 24,
            display: 'flex',
            justifyContent: 'center',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            height: 36,
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            fontFamily: "'Helvetica Neue', sans-serif;",
            padding: '4px 12px',
            backgroundColor: 'white',
            '&:active': {
              backgroundColor: 'white',
            },
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 100,
          }),
        }}
      ></Select>
    </div>
  );
};

// /#F6F8F9

export default OrderStatusDropdown;
