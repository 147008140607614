import React, { useContext, useEffect, useState } from 'react';
import styles from './style.module.css';
import Button from '../../ui/button';
import Text from '../../ui/text';
import Icon from '../../ui/icon';
import { ItemIcon, ItemIconDetail } from '../../types';
import {
  PART_TYPES,
  SYSTEM_IN_ITEM,
  SYSTEM_OUT_ITEM,
} from '../../common/config/partTypes';
import Status from '../../ui/status';
import { UseAuth } from '../auth-component/auth-provider';
import classNames from 'classnames';
import { ItemIconContext } from '../../common/context/itemIcons';

interface PartTypeRowProps {
  itemIcon: ItemIconDetail;
  onItemIconSelection: (partTypeName: ItemIcon) => void;
  dragImage: HTMLImageElement;
  onItemIconDrop: (
    itemIcon: ItemIcon,
    position: { x: number; y: number }
  ) => void;
}

const blockDrag = (event: React.DragEvent) => {
  event.preventDefault();
};

export const ICON_CENTERING_CONSTANT = 20;

const PartTypeRow = ({
  itemIcon,
  onItemIconSelection,
  onItemIconDrop,
}: PartTypeRowProps) => {
  const { isIpad } = UseAuth(); // Assuming useAuth is a function returning an object containing isIpad
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isFollowing, setIsFollowing] = useState(false);

  const handleTouchMove = (event: TouchEvent) => {
    if (event.touches.length === 1) {
      // Ensure single touch
      const position = getEventPosition(event);
      updatePosition(position);
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const updatePosition = (position: { x: number; y: number }) => {
    setMousePosition(position);
  };

  const setDraggingCursor = () => {
    // set grabbing cursor and prevent highlighting whilst grabbing
    const cursorStyle = document.createElement('style');
    cursorStyle.innerHTML =
      '*{cursor: grabbing!important; user-select: none!important}';
    cursorStyle.id = 'cursor-style';
    document.head.appendChild(cursorStyle);
  };

  const getEventPosition = (
    event: React.MouseEvent | React.TouchEvent | TouchEvent | MouseEvent
  ): { x: number; y: number } => {
    if (event.type === 'touchend' && 'changedTouches' in event) {
      return {
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY,
      };
    } else if ('touches' in event) {
      return {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
    }
    return {
      x: event.clientX,
      y: event.clientY,
    };
  };

  const startFollowing = (event: React.MouseEvent | React.TouchEvent) => {
    const position = getEventPosition(event);
    updatePosition(position);
    setIsFollowing(true);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleTouchMove);
    setDraggingCursor();
  };

  const stopFollowing = (event: MouseEvent | TouchEvent) => {
    setIsFollowing(false);
    const position = getEventPosition(event);
    onItemIconDrop(itemIcon, position); // Casting to 'any' due to mixed event types
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('touchmove', handleTouchMove);
    document.getElementById('cursor-style')?.remove();
  };

  useEffect(() => {
    if (isFollowing) {
      window.addEventListener('mouseup', stopFollowing, { once: true });
      window.addEventListener('touchend', stopFollowing, { once: true });
    }

    return () => {
      if (isFollowing) {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', stopFollowing);
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', stopFollowing);
      }
    };
  }, [isFollowing]);

  return (
    <div
      className={styles['icon-row']}
      onMouseDown={startFollowing}
      onTouchStart={startFollowing}
      draggable="false"
      onDragStart={blockDrag}
    >
      <Text
        textAlign="center"
        fontWeight="500"
        size="xs"
        draggable="false"
        style={{ userSelect: 'none', WebkitUserSelect: 'none' }}
      >
        {itemIcon.name}
      </Text>
      <Icon
        name={itemIcon.partType.iconName}
        height="55px"
        width="60px"
        rotation={itemIcon.partType.iconName === 'systemOut' ? 180 : 0}
      />
      {isFollowing && (
        <div
          style={{
            position: 'fixed',
            cursor: 'grabbing',
            left: mousePosition.x - ICON_CENTERING_CONSTANT,
            top: mousePosition.y - ICON_CENTERING_CONSTANT,
            pointerEvents: 'none', // Ensure the icon doesn't interfere with mouse events
          }}
        >
          <Icon
            name={itemIcon.partType.iconName}
            height="80px"
            width="80px"
            rotation={itemIcon.partType.iconName === 'systemOut' ? 180 : 0}
          />
        </div>
      )}
    </div>
  );
};

interface SideToolbarProps {
  onItemIconSelection: (itemIcon: ItemIcon) => void;
  doTriggerValidateDesign: () => void;
  onItemIconDrop: (
    itemIcon: ItemIcon,
    position: { x: number; y: number }
  ) => void;
  show: boolean;
}

const createTransparentImage = () => {
  const canvas = document.createElement('canvas');
  canvas.width = 1;
  canvas.height = 1;
  return canvas.toDataURL(); // Returns a base64-encoded transparent image
};

const SideToolbar = ({
  onItemIconSelection,
  doTriggerValidateDesign,
  onItemIconDrop,
  show,
}: SideToolbarProps): React.ReactElement => {
  const [displayedItemIcons, setDisplayedItemIcons] = useState<
    ItemIconDetail[] | undefined
  >();
  const [dragImage, setDragImage] = useState<HTMLImageElement>(new Image());
  const { isIpad } = UseAuth();
  const { itemIcons, itemIconsLoading, itemIconsError } =
    useContext(ItemIconContext);

  useEffect(() => {
    if (itemIcons) {
      setDisplayedItemIcons(itemIcons);
    }
  }, [itemIcons]);

  useEffect(() => {
    createTransparentImage();
    const img = new Image();
    img.src = createTransparentImage();
    setDragImage(img);
  }, []);

  const iconContainerClass = classNames({
    [styles.iconContainer]: true,
    [styles.ipadIconContainer]: isIpad,
    [styles.withStatus]: itemIconsLoading || itemIconsError,
  });

  const toolbarContainerClasss = classNames({
    [styles.toolbarContainer]: true,
    [styles.hideToolbar]: !show,
  });

  return (
    <div
      className={toolbarContainerClasss}
      draggable="false"
      onDragStart={blockDrag}
    >
      <div
        className={iconContainerClass}
        draggable="false"
        onDragStart={blockDrag}
      >
        {itemIconsLoading || itemIconsError ? (
          <Status
            style={{ gridColumn: '1/-1' }}
            loading={itemIconsLoading}
            error={itemIconsError}
          ></Status>
        ) : (
          displayedItemIcons?.map((itemIcon, index) => (
            <PartTypeRow
              itemIcon={itemIcon}
              dragImage={dragImage}
              key={index.toString()}
              onItemIconSelection={onItemIconSelection}
              onItemIconDrop={onItemIconDrop}
            ></PartTypeRow>
          ))
        )}
      </div>
      <Button
        onClick={doTriggerValidateDesign}
        variant="primary"
        style={{ width: '100%', height: 40, minHeight: 40 }}
      >
        Add design details
      </Button>
    </div>
  );
};

export default SideToolbar;
