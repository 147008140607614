import React, { useContext } from 'react';
import styles from './style.module.css';
import { formatPrice } from '../../common/helpers/number-helpers';
import { nanoid } from 'nanoid';
import Button from '../../ui/button';
import Icon from '../../ui/icon';
import ValueCard from '../../ui/value-card';
import { TransportCard } from './transport-card';
import { DesignDataContext } from '../../common/context/designData';

interface LogisticsFormProps {}

const LogisticsForm = () => {
  const { designData, getTransportOptions, updateLogisticsDataValue } =
    useContext(DesignDataContext);

  const handleAddTransportOption = () => {
    updateLogisticsDataValue('transport', {
      ...getTransportOptions(),
      [nanoid()]: {},
    });
  };

  const handleDeleteTransportOption = (key: string) => () => {
    const newTransportOptions = { ...getTransportOptions() };
    delete newTransportOptions[key];
    updateLogisticsDataValue('transport', newTransportOptions);
  };

  return (
    <div className={styles.form}>
      <div className={styles.transportContainer}>
        {Object.entries(getTransportOptions()).map(([key, transportOption]) => (
          <TransportCard
            transportOption={transportOption}
            transportOptions={getTransportOptions()}
            onDelete={handleDeleteTransportOption}
            key={key}
            id={key}
          ></TransportCard>
        ))}
      </div>

      <div className={styles.addAnotherButton}>
        <Button onClick={handleAddTransportOption} variant="secondary">
          <Icon name="addIconV2" fill="white" width="22px" height="22px"></Icon>
          Add another transport method
        </Button>
      </div>

      <ValueCard
        price={formatPrice(
          designData.logistics_data.transportation_cost?.price
        )}
        label="Total transport price:"
      ></ValueCard>
    </div>
  );
};

export default LogisticsForm;
