import React, { useCallback } from 'react';
import styles from './style.module.css';
import { ReactSVG } from 'react-svg';
import Status from '../status';
import Text from '../text';

// This should be done better, don't want to load html for all icons by default
// Maybe dynamic importing
// https://stackoverflow.com/a/65452698

interface IconProps {
  name: string;
  height?: string;
  width?: string;
  fill?: string;
  showError?: boolean;
  label?: string;
  labelStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  rotation?: number;
}

const Icon = ({
  name,
  height,
  width,
  fill,
  showError,
  label,
  labelStyle,
  iconStyle,
  rotation = 0,
}: IconProps) => {
  const style = {
    '--icon-height': height,
    '--icon-width': width,
    '--fill': fill,
    '--rotation': `rotate(${rotation}deg)`,
    ...iconStyle,
  } as React.CSSProperties;

  return (
    <div className={styles['icon-container']} style={style}>
      {label && (
        <div className={styles['label']} style={labelStyle}>
          <Text
            size="sm"
            fontWeight="500"
            textAlign="center"
            alignSelf="center"
          >
            {label}
          </Text>
        </div>
      )}
      {showError && (
        <div className={styles['warning-container']}>
          <ReactSVG src="/static/assets/warningTriangle.svg" />
        </div>
      )}
      <ReactSVG src={`/static/assets/${name}.svg`} />
    </div>
  );
};

export default Icon;
