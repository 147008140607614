import React, { MutableRefObject, useContext, useRef } from 'react';
import styles from './style.module.css';
import Text from '../../ui/text';
import Search from '../../ui/search';
import Input from '../../ui/input';
import { DesignDataContext } from '../../common/context/designData';
import { Editor } from '@tinymce/tinymce-react';
import { cleanNumberInput } from '../../common/helpers/number-helpers';
import OrderStatusDropdown from '../order-status-dropdown';
import { UseAuth } from '../auth-component/auth-provider';

interface DesignDetailsProps {}

const DesignDetails = ({}: DesignDetailsProps): React.ReactElement => {
  const { designData, updateDesignDetails } = useContext(DesignDataContext);
  const { isIpad } = UseAuth();
  const editorRef = useRef<any>(null);

  const handleOnBlur = () => {
    if (isIpad) {
      setTimeout(() => {
        // This prevents the bug that caused the PWA ipad app to occasionally freeze all inputs
        // when the keyboard was hidden.
        // Check if the newly focused element is not an input
        if (
          !(
            document?.activeElement?.tagName === 'INPUT' ||
            document?.activeElement?.tagName === 'TEXTAREA' ||
            document?.activeElement?.tagName === 'IFRAME'
          )
        ) {
          // Force the page to scroll back into normal view if it isn't
          if (document.documentElement.scrollTop > 1) {
            document.documentElement.scrollTop = 100;
          }
        }
      }, 300); // Delay to allow focus to shift to the next element
    }
  };

  const handleInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    const keyToUpdate = event.target.name;
    updateDesignDetails(keyToUpdate, value);
  };

  const handleNumberInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = cleanNumberInput(event.target.value);
    const keyToUpdate = event.target.name;
    updateDesignDetails(keyToUpdate, value);
  };

  const handleEditorChange = (richText: string) => {
    updateDesignDetails('scope_notes', richText);
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.textInputs}>
        <div className={styles.titleInputMainContainer}>
          <div className={styles.text}>
            <Text color="dark-grey">Project Name</Text>
          </div>
          <div className={styles.inputContainer}>
            <Input
              variant="standard"
              name="design_name"
              style={{ width: 230 }}
              value={designData.design_details?.design_name || ''}
              onChange={handleInput}
            ></Input>
          </div>
        </div>
        <div className={styles.titleInputMainContainer}>
          <div className={styles.textDos}>
            <Text color="dark-grey">Reference Nº</Text>
          </div>
          <div className={styles.inputContainerDos}>
            <Input
              variant="standard"
              name="reference_number"
              style={{ width: 223 }}
              value={designData.design_details?.reference_number || ''}
              onChange={handleInput}
            ></Input>
          </div>
        </div>
      </div>

      <div className={styles.conditionsContainer}>
        <div className={styles.column}>
          <div className={styles.title}>
            <Text size="m" color="dark-grey">
              Capacity (kW)
            </Text>
          </div>
          <div>
            <Input
              className={styles.conditionInput}
              style={{ width: 50 }}
              name="capacity"
              value={designData.design_details.capacity || ''}
              onChange={handleNumberInput}
            ></Input>
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.title}>
            <Text size="m" color="dark-grey">
              Ambient (°C)
            </Text>
          </div>
          <div>
            <Input
              className={styles.conditionInput}
              name="ambient_temp"
              style={{ width: 50 }}
              value={designData.design_details.ambient_temp || ''}
              onChange={handleNumberInput}
            ></Input>
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.title}>
            <Text size="m" variant="standard" color="dark-grey">
              Water (°C)
            </Text>
          </div>
          <div>
            <Input
              className={styles.conditionInput}
              name="water_temp"
              style={{ width: 50 }}
              value={designData.design_details.water_temp || ''}
              onChange={handleNumberInput}
            ></Input>
          </div>
        </div>
      </div>
      <div className={styles.statusContainer}>
        <Text fontWeight="600" alignSelf="start">
          Order status
        </Text>
        <OrderStatusDropdown
          containerStyle={{ width: 200 }}
        ></OrderStatusDropdown>
      </div>

      <div className={styles['scope-notes-main-container']}>
        <div className={styles['scope-container']}>
          <Text color="dark-grey">Scope of work notes:</Text>
        </div>
        <Editor
          onInit={(evt, editor) => {
            editorRef.current = editor;
          }}
          value={designData.design_details.scope_notes}
          onBlur={handleOnBlur}
          init={{
            height: 400,
            width: 617,
            resize: false,
            branding: false,
            statusbar: false,
            menubar: false,
            plugins: ['lists', 'advlist'],
            toolbar:
              'undo redo | blocks | bold italic underline | bullist | forecolor',
            block_formats:
              'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6',
            paste_as_text: true,
          }}
          onEditorChange={handleEditorChange}
        />
      </div>
    </div>
  );
};

export default DesignDetails;
