// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal__ixeYh {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.modalContent__pdoOM {
  position: relative;
  width: var(--modal-width);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 22%);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-height: 85dvh;
}

.titleContainer__S_Pdx {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.contentContainer__BkUyc {
  max-height: 80dvh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4px;
}

.errorContainer__Sd6Nl {
  position: absolute;
  bottom: 69px;
  left: 27px;
  width: 590px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/modal/style.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;EACpC,eAAe;EACf,OAAO;EACP,MAAM;EACN,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,wCAAwC;EACxC,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".modal {\n  z-index: 5;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.3);\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n}\n\n.modalContent {\n  position: relative;\n  width: var(--modal-width);\n  background-color: #fff;\n  border-radius: 10px;\n  box-shadow: 0px 2px 5px rgb(0 0 0 / 22%);\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n  max-height: 85dvh;\n}\n\n.titleContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 60px;\n}\n\n.contentContainer {\n  max-height: 80dvh;\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding: 4px;\n}\n\n.errorContainer {\n  position: absolute;\n  bottom: 69px;\n  left: 27px;\n  width: 590px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `modal__ixeYh`,
	"modalContent": `modalContent__pdoOM`,
	"titleContainer": `titleContainer__S_Pdx`,
	"contentContainer": `contentContainer__BkUyc`,
	"errorContainer": `errorContainer__Sd6Nl`
};
export default ___CSS_LOADER_EXPORT___;
