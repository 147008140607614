// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer__KV8uh {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 10px;
       column-gap: 10px;
}

.detailsMainContainer__yFwZj {
  display: flex;
  grid-row-gap: 5px;
  align-items: flex-start;
  padding-top: 12px;
  flex-direction: column;
}

.inputContainer__sbFTT {
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/summary-company-component/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAgB;OAAhB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[".mainContainer {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 10px;\n}\n\n.detailsMainContainer {\n  display: flex;\n  grid-row-gap: 5px;\n  align-items: flex-start;\n  padding-top: 12px;\n  flex-direction: column;\n}\n\n.inputContainer {\n  display: flex;\n  align-items: center;\n  overflow-wrap: anywhere;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `mainContainer__KV8uh`,
	"detailsMainContainer": `detailsMainContainer__yFwZj`,
	"inputContainer": `inputContainer__sbFTT`
};
export default ___CSS_LOADER_EXPORT___;
