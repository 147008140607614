import React, { CSSProperties } from 'react';
import Icon from '../icon';
import Text from '../text';
import styles from './style.module.css';

interface StatusProps {
  loading?: boolean;
  error?: boolean;
  errorMessage?: string;
  message?: string;
  hideIcon?: boolean;
  style?: CSSProperties;
}

const Status = ({
  loading,
  error,
  errorMessage,
  hideIcon,
  message,
  style,
}: StatusProps): React.ReactElement | null => {
  if (error) {
    return (
      <div className={styles['container']} style={style}>
        <div className={styles['error-container']}>
          {!hideIcon && (
            <Icon
              name="warningTriangle"
              width="22px"
              height="22px"
              fill="#f27157"
            ></Icon>
          )}
          <Text textAlign="center">
            {errorMessage || 'An unknown error has occurred.'}
          </Text>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={styles['container']} style={style}>
        <div className={styles['spinner']}></div>
        {message && (
          <Text style={{ marginTop: 12 }} textAlign="center">
            {message}
          </Text>
        )}
      </div>
    );
  }
  return null;
};

export default Status;
