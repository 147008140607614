import React from 'react';
import { Handle, Position } from 'reactflow';
import Icon from '../../ui/icon';
import { createSharedHandleStyle } from '../../common/config/globalHandleStyle';
import { ItemIconNodeData } from '../../types';

const NodeWaterCooledScrew = ({ data }: { data: ItemIconNodeData }) => {
  const sharedHandleStyle = createSharedHandleStyle(data, {});
  const rightBottomStyle = { ...sharedHandleStyle, top: 58, right: 3 };
  const rightTopStyle = { ...sharedHandleStyle, top: 30, right: 3 };
  const leftBottomStyle = { ...sharedHandleStyle, top: 58, left: 4 };
  const leftTopStyle = { ...sharedHandleStyle, top: 30, left: 4 };

  return (
    <div>
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={rightBottomStyle}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="c"
        style={rightTopStyle}
      />
      <Icon
        name="waterCooledScrew"
        height="86px"
        width="86px"
        showError={data.showError}
        label={data.item?.name}
      ></Icon>
      <Handle
        type="source"
        position={Position.Left}
        id="a"
        style={leftBottomStyle}
      />
      <Handle
        type="source"
        position={Position.Left}
        id="d"
        style={leftTopStyle}
      />
    </div>
  );
};

export default NodeWaterCooledScrew;
