// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer__tAwzt {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 45px;
  padding-bottom: 8px;
}

.titleContainer__rQqFB {
  display: flex;
}

.finalTitleContainer__xlfag {
  display: flex;
}

.titleAndInputContainer__Eebel,
.inputMainContainer__tupID {
  display: grid;
  grid-row-gap: 15px;
}

.mainAllContainer__Uphcz {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.valueCardContainer__G7uwi {
  display: flex;
}

.valuePriceContainer__VOYsv {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0;
}

@media (max-width: 1200px) {
  .value-price-container__evZDg {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
}

.inputContainer__Vab0s {
  display: flex;
  justify-content: flex-start;
}

.finalInputContainer__AcRis {
  display: flex;
  justify-content: flex-start;
}

.mainGridContainer__xU91l {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.finalPrice__Y8lXI {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.finalPriceContainer__iUfjd {
  display: grid;
  grid-template-columns: 81px auto;
  grid-column-gap: 45px;
  border-top: 1px solid var(--background-light-blue);
  padding-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/summary-final-price-component/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;IACb,uBAAuB;IACvB,QAAQ;EACV;AACF;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,qBAAqB;EACrB,kDAAkD;EAClD,gBAAgB;AAClB","sourcesContent":[".mainContainer {\n  display: grid;\n  grid-template-columns: auto auto;\n  grid-column-gap: 45px;\n  padding-bottom: 8px;\n}\n\n.titleContainer {\n  display: flex;\n}\n\n.finalTitleContainer {\n  display: flex;\n}\n\n.titleAndInputContainer,\n.inputMainContainer {\n  display: grid;\n  grid-row-gap: 15px;\n}\n\n.mainAllContainer {\n  display: flex;\n  flex-direction: column;\n  row-gap: 30px;\n}\n\n.valueCardContainer {\n  display: flex;\n}\n\n.valuePriceContainer {\n  display: flex;\n  justify-content: space-between;\n  padding: 0 10px 0;\n}\n\n@media (max-width: 1200px) {\n  .value-price-container {\n    display: flex;\n    justify-content: center;\n    gap: 8px;\n  }\n}\n\n.inputContainer {\n  display: flex;\n  justify-content: flex-start;\n}\n\n.finalInputContainer {\n  display: flex;\n  justify-content: flex-start;\n}\n\n.mainGridContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.finalPrice {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0 10px;\n}\n\n.finalPriceContainer {\n  display: grid;\n  grid-template-columns: 81px auto;\n  grid-column-gap: 45px;\n  border-top: 1px solid var(--background-light-blue);\n  padding-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `mainContainer__tAwzt`,
	"titleContainer": `titleContainer__rQqFB`,
	"finalTitleContainer": `finalTitleContainer__xlfag`,
	"titleAndInputContainer": `titleAndInputContainer__Eebel`,
	"inputMainContainer": `inputMainContainer__tupID`,
	"mainAllContainer": `mainAllContainer__Uphcz`,
	"valueCardContainer": `valueCardContainer__G7uwi`,
	"valuePriceContainer": `valuePriceContainer__VOYsv`,
	"value-price-container": `value-price-container__evZDg`,
	"inputContainer": `inputContainer__Vab0s`,
	"finalInputContainer": `finalInputContainer__AcRis`,
	"mainGridContainer": `mainGridContainer__xU91l`,
	"finalPrice": `finalPrice__Y8lXI`,
	"finalPriceContainer": `finalPriceContainer__iUfjd`
};
export default ___CSS_LOADER_EXPORT___;
