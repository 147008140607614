import React from 'react';
import { Handle, Position } from 'reactflow';
import Icon from '../../ui/icon';
import { createSharedHandleStyle } from '../../common/config/globalHandleStyle';
import { ItemIconNodeData } from '../../types';

const NodeDryAirCooler = ({ data }: { data: ItemIconNodeData }) => {
  const sharedHandleStyle = createSharedHandleStyle(data, {});
  const rightStyle = { ...sharedHandleStyle, top: 34, left: 50 };
  const leftStyle = { ...sharedHandleStyle, top: 21, left: 12 };
  return (
    <div>
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={rightStyle}
      />
      <Icon
        name="dryAirCooler"
        height="100px"
        width="100px"
        showError={data.showError}
        label={data.item?.name}
      ></Icon>
      <Handle type="source" position={Position.Left} id="a" style={leftStyle} />
    </div>
  );
};

export default NodeDryAirCooler;
