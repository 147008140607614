import React from 'react';
import { Handle, Node, Position } from 'reactflow';
import Icon from '../../ui/icon';
import { createSharedHandleStyle } from '../../common/config/globalHandleStyle';
import { ItemIconNodeData } from '../../types';

type CustomNode = Node<ItemIconNodeData>;

const NodeBufferTank = ({ data }: { data: ItemIconNodeData }) => {
  const sharedHandleStyle = createSharedHandleStyle(data, {});
  const topStyle = { ...sharedHandleStyle, top: '49px', left: '17px' };
  const bottomStyle = { ...sharedHandleStyle, top: '22px', right: '14px' };
  return (
    <div>
      <Handle
        type="source"
        position={Position.Left}
        id="b"
        style={topStyle}
      ></Handle>
      <Icon
        name="bufferTank"
        height="86px"
        width="86px"
        showError={data.showError}
        label={data.item?.name}
        labelStyle={{
          top: '-40px',
          whiteSpace: 'break-spaces',
          width: '120px',
        }}
      ></Icon>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        style={bottomStyle}
      />
    </div>
  );
};

export default NodeBufferTank;
