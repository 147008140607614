// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standard__rrzCv > input {
  width: 100%;
  font-family: 'Helvetica Neue', sans-serif;
  height: 35px;
  background-color: white;
  border: none;
  padding: 0 10px;
  font-size: 16px;
  border-radius: 18px;
  margin: 0 15px;
  box-sizing: border-box;
}

.standard__rrzCv > input:focus {
  outline: none; /* Optional: Removes the default focus outline */
}

.standard__rrzCv {
  display: grid;
  grid-template-columns: 24px 1fr 20px 25px;
  justify-items: center;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 18px;
  padding: 0 15px;
  width: calc(100% - 100px);
  max-width: 500px;
}

.line__uWx1w {
  background-color: #b3b3b3;
  height: 26px;
  width: 1px;
  margin: 0 15px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/search/style.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yCAAyC;EACzC,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,aAAa,EAAE,gDAAgD;AACjE;;AAEA;EACE,aAAa;EACb,yCAAyC;EACzC,qBAAqB;EACrB,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,UAAU;EACV,cAAc;AAChB","sourcesContent":[".standard > input {\n  width: 100%;\n  font-family: 'Helvetica Neue', sans-serif;\n  height: 35px;\n  background-color: white;\n  border: none;\n  padding: 0 10px;\n  font-size: 16px;\n  border-radius: 18px;\n  margin: 0 15px;\n  box-sizing: border-box;\n}\n\n.standard > input:focus {\n  outline: none; /* Optional: Removes the default focus outline */\n}\n\n.standard {\n  display: grid;\n  grid-template-columns: 24px 1fr 20px 25px;\n  justify-items: center;\n  align-items: center;\n  border: 1px solid #cccccc;\n  border-radius: 18px;\n  padding: 0 15px;\n  width: calc(100% - 100px);\n  max-width: 500px;\n}\n\n.line {\n  background-color: #b3b3b3;\n  height: 26px;\n  width: 1px;\n  margin: 0 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"standard": `standard__rrzCv`,
	"line": `line__uWx1w`
};
export default ___CSS_LOADER_EXPORT___;
