import React from 'react';
import styles from './style.module.css';

import Text from '../../ui/text';
import Icon from '../../ui/icon';
import { LogisticsData, SummarySectionProps } from '../../types';
import SummarySectionCardComponent from '../summary-section-card-component';
import ValueCard from '../../ui/value-card';
import {
  formatNumberPrice,
  formatPrice,
  pricingToDiscountedPrice,
} from '../../common/helpers/number-helpers';

interface SummaryFinalPriceComponetProps extends SummarySectionProps {
  logisticsData?: Partial<LogisticsData>;
  canOpen?: boolean;
}

const SummaryFinalPriceComponet = ({
  logisticsData,
  toggleOpen,
  open,
  canOpen = true,
}: SummaryFinalPriceComponetProps) => {
  return (
    <SummarySectionCardComponent
      toggleOpen={toggleOpen}
      open={open}
      canOpen={canOpen}
      title="Final price"
      infoText={`£${formatPrice(logisticsData?.total_price)}`}
      header={
        <div className={styles.summaryMainContainer}>
          <div className={styles.containerSpaceBetween}>
            <div className={styles.summaryTitleContainer}>
              <Text fontWeight="400" size="m">
                Final price
              </Text>
            </div>
            <div className={styles.boxRight}>
              <Text size="m" fontWeight="400">
                Total: £{formatPrice(logisticsData?.total_price)}
              </Text>
            </div>
          </div>
        </div>
      }
    >
      <div className={styles.mainAllContainer}>
        <div className={styles.mainGridContainer}>
          <div className={styles.mainContainer}>
            <div className={styles.titleAndInputContainer}>
              <div className={styles.titleContainer}>
                <Text fontWeight="400" size="m" color="dark-grey">
                  Equipment
                </Text>
              </div>
              <div className={styles.titleContainer}>
                <Text fontWeight="400" size="m" color="dark-grey">
                  Delivery
                </Text>
              </div>
              <div className={styles.titleContainer}>
                <Text fontWeight="400" size="m" color="dark-grey">
                  Labour
                </Text>
              </div>
              <div className={styles.titleContainer}>
                <Text fontWeight="400" size="m" color="dark-grey">
                  Extras
                </Text>
              </div>
            </div>

            <div className={styles.inputMainContainer}>
              <div className={styles.inputContainer}>
                <Text>
                  £{pricingToDiscountedPrice(logisticsData?.equipment_cost)}
                </Text>
              </div>

              <div className={styles.inputContainer}>
                <Text>
                  £
                  {pricingToDiscountedPrice(logisticsData?.transportation_cost)}
                </Text>
              </div>

              <div className={styles.inputContainer}>
                <Text>
                  £{pricingToDiscountedPrice(logisticsData?.labour_cost)}
                </Text>
              </div>
              <div className={styles.inputContainer}>
                <Text>
                  £
                  {pricingToDiscountedPrice(
                    logisticsData?.other_weekly_rentals_cost
                  )}
                </Text>
              </div>
            </div>
          </div>
          <div className={styles.finalPriceContainer}>
            <Text fontWeight="400" size="m" color="dark-grey">
              Total
            </Text>

            <Text fontWeight="600" color="dark-grey">
              £{formatPrice(logisticsData?.total_price)}
            </Text>
          </div>
        </div>
      </div>
    </SummarySectionCardComponent>
  );
};

export default SummaryFinalPriceComponet;
