import React, { useContext } from 'react';
import styles from './style.module.css';

import Text from '../../ui/text';
import Icon from '../../ui/icon';
import SummarySectionCardComponent from '../summary-section-card-component';
import { DesignDataContext } from '../../common/context/designData';

const SummaryLabourAndTransportComponent = () => {
  const { derivedData } = useContext(DesignDataContext);
  return (
    <SummarySectionCardComponent
      canOpen={false}
      open={false}
      title="Transport and labour"
      infoText={`£${derivedData.labourAndTransportPrice}`}
    ></SummarySectionCardComponent>
  );
};

export default SummaryLabourAndTransportComponent;
