import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  MouseEventHandler,
} from 'react';
import { useParams } from 'react-router-dom';
import styles from './style.module.css';
import { ItemIconNode, User } from '../../types';
import { ReactFlowProvider, Edge } from 'reactflow';
import SummaryComponent from '../../components/summary-component';
import { DesignDataContext } from '../../common/context/designData';
import { toBlob } from 'html-to-image';

import Status from '../../ui/status';
import FlowViewComponent from '../../components/flow-view-component';
import Icon from '../../ui/icon';
import Text from '../../ui/text';
import GenerateV2PDFModal from '../../components/generate-v2-pdf-modal';
import ToastMessage from '../../ui/toast-message';
import { UseAuth } from '../../components/auth-component/auth-provider';
import Button from '../../ui/button';
import { getStringOrDefault } from '../../common/helpers/pdf-data-helper';
import OrderStatusDropdown from '../../components/order-status-dropdown';
import classNames from 'classnames';

const DesignViewApp = () => {
  const [nodesReady, setNodesReady] = useState<boolean>();
  const [selectedViewMode, setSelectedViewMode] = useState<
    'internal' | 'customer'
  >('customer');
  const [newNodes, setNewNodes] = useState<ItemIconNode[]>([]);
  const [newEdges, setNewEdges] = useState<Edge[]>([]);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [triggerFitView, setTriggerFitView] = useState(0);
  const [showReactFlowControls, setShowReactFlowControls] = useState(true);
  const [triggerOpenV2PDFModal, setTriggerOpenV2PDFModal] = useState(0);
  const [designImageFile, setDesignImageFile] = useState<File>();
  const designView = useRef<HTMLDivElement>(null);
  const designImageView = useRef<HTMLDivElement>(null);
  const { id } = useParams();

  const { designData, loading, error } = useContext(DesignDataContext);
  const { user, isIpad, handleRelocate, getCsrf } = UseAuth();

  useEffect(() => {
    // Set default view mode based on user role
    setSelectedViewMode(
      user?.job_role === 'sales_support' ? 'internal' : 'customer'
    );
  }, [user]);

  useEffect(() => {
    window.addEventListener('afterprint', () => {
      window.location.reload();
    });

    return () => {
      window.addEventListener('afterprint', () => {
        window.location.reload();
      });
    };
  }, []);

  useEffect(() => {
    if (
      designData?.design_json &&
      designData?.design_json.nodes &&
      designData?.design_json.edges
    ) {
      setNodesReady(false);
    }
  }, [designData?.design_json]);

  useEffect(() => {
    if (!nodesReady) {
      setNewNodes(designData?.design_json.nodes || []);
      setNodesReady(true);
    } else {
      setTimeout(() => {
        setNewEdges(designData?.design_json.edges || []);
      }, 200); // Adjust the delay as needed
    }
  }, [nodesReady]);

  const handleOpenV2PDFModal = async () => {
    setTriggerOpenV2PDFModal((prev) => prev + 1);
    setShowReactFlowControls(false);
    // Generate design image
    document
      .querySelector('div#design-view')
      ?.setAttribute('style', 'width: 595px; height: 842px');
    setTimeout(() => {
      setTriggerFitView((prev) => prev + 1);
    }, 500);
  };

  const resetDesignSize = () => {
    document
      .querySelector('div#design-view')
      ?.setAttribute('style', 'width: unset; height: unset');
    setShowReactFlowControls(true);
  };

  const handleToggleSelectedViewMode: MouseEventHandler<
    HTMLDivElement
  > = () => {
    setSelectedViewMode((prevSelectedView) => {
      return prevSelectedView === 'internal' ? 'customer' : 'internal';
    });
  };

  const getDesignBlob = async (currentDesignView: HTMLDivElement) => {
    if (!isIpad) {
      return await toBlob(currentDesignView, {
        skipFonts: true,
        pixelRatio: 3,
        backgroundColor: 'white',
      });
    } else {
      // According to https://github.com/bubkoo/html-to-image/issues/361
      // this is a way to prevent the issue of no imgs on ipad
      await toBlob(currentDesignView, {
        skipFonts: true,
        pixelRatio: 3,
        backgroundColor: 'white',
      });
      await toBlob(currentDesignView, {
        skipFonts: true,
        pixelRatio: 3,
        backgroundColor: 'white',
      });
      await toBlob(currentDesignView, {
        skipFonts: true,
        pixelRatio: 3,
        backgroundColor: 'white',
      });
      return await toBlob(currentDesignView, {
        skipFonts: true,
        pixelRatio: 3,
        backgroundColor: 'white',
      });
    }
  };

  const v2PDFImageGen = async () => {
    if (!designView.current) {
      return;
    }
    const blob = await getDesignBlob(designView.current);
    if (!blob) {
      console.error('Failed to generate react flow image blob');
      return;
    }
    const file = new File([blob], 'design_image.png', { type: 'image/png' });
    setDesignImageFile(file);
    resetDesignSize();
  };

  useEffect(() => {
    if (triggerFitView >= 1) {
      const timer = setTimeout(async () => {
        // We then need a delay to generate the image after it has fit
        v2PDFImageGen();
      }, 1000);

      return () => clearTimeout(timer); // Clean up the timer.
    }
  }, [triggerFitView]);

  useEffect(() => {
    if (isPrinting) {
      const timer = setTimeout(() => window.print(), 1000);
      return () => clearTimeout(timer); // Clean up the timer.
    }
  }, [isPrinting]);

  const handleStatusChange = async (status: string) => {
    const currentCsrf = await getCsrf();
    const requestHeaders = new Headers();
    requestHeaders.set('X-CSRFToken', currentCsrf || '');
    requestHeaders.set('Content-Type', 'application/json');
    const request = await fetch(`/api/order/status/${id}/`, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: requestHeaders,
      body: JSON.stringify({ status: status }),
    });
    const response = await request.json();
  };

  if (loading || error) {
    return (
      <div className={styles.page}>
        <Status
          loading={loading}
          style={{ gridRow: '1/4' }}
          error={error}
          errorMessage={'Unable to load project'}
        ></Status>
      </div>
    );
  }

  const handleClass = classNames({
    [styles.toggleHandle]: true,
    [styles.internalView]: selectedViewMode === 'internal',
  });

  return (
    <div className={styles.page}>
      {designData.design_details?.prices_adjusted_since_creation && (
        <div className={styles.toastMessage}>
          <ToastMessage
            icon={
              <Icon
                fill="#1979ec"
                name="infoIcon"
                height="18px"
                width="18px"
              ></Icon>
            }
            text="The prices in this order have been adjusted to match latest item pricing updates"
            closeable
          ></ToastMessage>
        </div>
      )}
      <div className={styles.topBar}>
        <Button
          onClick={() => handleRelocate('search')}
          variant="secondary"
          size="lg"
        >
          <Icon name="backIcon" height="16px" width="10px"></Icon>Back
        </Button>

        <div className={styles.title}>
          <Text
            fontWeight="600"
            size="m"
            textAlign="center"
            truncate={true}
            style={{ width: '90%' }}
          >
            {designData.design_details.design_name}
          </Text>
          <Text size="s" color="light-grey" style={{ marginTop: '10px' }}>
            Reference Nº:{' '}
            {getStringOrDefault(designData.design_details.reference_number)}
          </Text>
        </div>
        <OrderStatusDropdown
          containerStyle={{ width: 150 }}
          onChange={handleStatusChange}
        ></OrderStatusDropdown>
      </div>
      <div className={styles.body}>
        <div className={styles.designView} id="design-view">
          <div style={{ height: '100%', width: '100%' }} ref={designView}>
            <ReactFlowProvider>
              <FlowViewComponent
                newNodes={newNodes}
                newEdges={newEdges}
                triggerFitView={triggerFitView}
                showControls={showReactFlowControls}
              ></FlowViewComponent>
            </ReactFlowProvider>
          </div>
        </div>
        <div className={styles.designSummaryComponent}>
          <div className={styles.toggle} onClick={handleToggleSelectedViewMode}>
            <Text
              size="s"
              color={selectedViewMode === 'customer' ? 'white' : 'light-grey'}
            >
              Customer view
            </Text>
            <Text
              size="s"
              color={selectedViewMode === 'internal' ? 'white' : 'light-grey'}
            >
              Internal view
            </Text>
            <div className={handleClass}></div>
          </div>
          <div className={styles.summaryComponentContainer}>
            <SummaryComponent
              selectedViewMode={selectedViewMode}
            ></SummaryComponent>
          </div>
          <div className={styles.summaryButtons}>
            <div className={styles.summaryButtonsLeft}>
              <Button
                onClick={() => handleRelocate(`design-create-variant/${id}`)}
                variant="secondary"
              >
                Create Variant
              </Button>
              <Button
                onClick={() => handleRelocate(`design-edit/${id}`)}
                variant="secondary"
              >
                Edit Design
              </Button>
            </div>
            <Button
              onClick={handleOpenV2PDFModal}
              variant="primary"
              style={{ width: 100 }}
            >
              <Icon
                name="pdfIcon"
                height="16px"
                width="16px"
                fill="white"
              ></Icon>
              Print
            </Button>
          </div>
        </div>
      </div>
      <GenerateV2PDFModal
        triggerShow={triggerOpenV2PDFModal}
        designImageFile={designImageFile}
      ></GenerateV2PDFModal>
    </div>
  );
};

export default DesignViewApp;
