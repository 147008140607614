import React, { useState, useMemo, useEffect } from 'react';
import {
  Address,
  Contact,
  DesignData,
  Item,
  ItemOrder,
  LabourData,
  LogisticsFieldName,
  OneOffItem,
  OtherWeeklyRental,
  Pricing,
  SelectedItems,
  TransportData,
} from './../../types';
import { isEmpty, transform } from 'lodash';
import { nanoid } from 'nanoid';
import { useParams } from 'react-router-dom';
import {
  formatNumberPrice,
  pricingToDiscountedPriceNumber,
} from '../helpers/number-helpers';

const LABOUR_FIELDS = [
  'installation_labour',
  'commissioning_labour',
  'decommissioning_labour',
  'deinstallation_labour',
] as const;

// This is data that is rounded, discount applied and ready to display
export type DerivedData = {
  labourAndTransportPrice: string;
  equipmentPrice: string;
  equipmentWeeklyPrice: string;
  totalEquipmentPrice: string; // All equipment + other weekly rentals
  totalEquipmentWeeklyPrice: string; // All equipment + other weekly rentals
  extrasPrice: string;
};

type DesignDataContextType = {
  designData: DesignData;
  derivedData: DerivedData;
  designId?: string;
  triggerDesignUpdate: number;
  loading?: boolean;
  error?: boolean;
  updateDesignData: (partialData: Partial<DesignData>) => void;
  updateSelectedItems: (
    item: Item,
    quantityDelta: number,
    cleanEmpties?: boolean
  ) => void;
  updateSelectedItemsQuantity: (
    item: Item | ItemOrder,
    newQuantity: number,
    cleanEmpties?: boolean
  ) => void;
  updateClientData: (
    formValues: Partial<Address | Contact> | boolean,
    fieldName: string
  ) => void;
  updateDesignDetails: (fieldName: string, formValue: string) => void;
  updateLogisticsDataObject: (
    objectName: LogisticsFieldName,
    fieldName: string,
    fieldValue: string | number
  ) => void;
  cleanEmptySelectedItems: () => void;
  cleanDataForSave: () => void;
  updateLogisticsDataValue: (
    fieldName: string,
    fieldValue:
      | string
      | number
      | null
      | Partial<TransportData>
      | LabourData
      | Pricing
  ) => void;
  getTransportOptions: () => Record<string, Partial<TransportData>>;
  getOneOffItems: () => Record<string, Partial<OneOffItem>>;
};

const DEFAULT_DESIGN_DATA: DesignData = {
  selected_items: {},
  client_data: {},
  logistics_data: {
    one_off_items: {
      [nanoid()]: {
        price: '',
        name: '',
      },
      [nanoid()]: {
        price: '',
        name: '',
      },
      [nanoid()]: {
        price: '',
        name: '',
      },
    },
    other_weekly_rentals: {
      [nanoid()]: {
        weekly_rental_price: '',
        name: '',
      },
      [nanoid()]: {
        weekly_rental_price: '',
        name: '',
      },
      [nanoid()]: {
        weekly_rental_price: '',
        name: '',
      },
    },
  },
  design_details: {},
  design_json: {},
  status: 'proposal',
};

const DEFAULT_DERIVED_DATA: DerivedData = {
  labourAndTransportPrice: '',
  equipmentPrice: '',
  equipmentWeeklyPrice: '',
  extrasPrice: '',
  totalEquipmentPrice: '',
  totalEquipmentWeeklyPrice: '',
};

export const DesignDataContext = React.createContext<DesignDataContextType>({
  designData: DEFAULT_DESIGN_DATA,
  derivedData: DEFAULT_DERIVED_DATA,
  designId: undefined,
  triggerDesignUpdate: 0,
  loading: false,
  error: false,
  cleanEmptySelectedItems: () => {},
  updateDesignData: () => {},
  updateSelectedItems: () => {},
  updateSelectedItemsQuantity: () => {},
  updateClientData: () => {},
  cleanDataForSave: () => {},
  updateDesignDetails: () => {},
  updateLogisticsDataValue: () => {},
  updateLogisticsDataObject: () => {},
  getTransportOptions: () => ({}),
  getOneOffItems: () => ({}),
});

export const DesignDataProvider = ({
  designMode = 'create',
  children,
  usePropDesignId,
  propDesignId,
}: {
  children: React.ReactNode;
  designMode?: 'edit' | 'view' | 'create' | 'create-variant';
  usePropDesignId?: boolean;
  propDesignId?: string;
}) => {
  const [designData, setDesignData] = useState<DesignData>(DEFAULT_DESIGN_DATA);
  const [derivedData, setDerivedData] =
    useState<DerivedData>(DEFAULT_DERIVED_DATA);
  const [triggerDesignUpdate, setTriggerDesignUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const selectedItems = useMemo(() => {
    return designData.selected_items;
  }, [designData.selected_items]);
  const { id } = useParams();

  const initializeLogisticsData = (
    key: 'transport' | 'one_off_items' | 'other_weekly_rentals'
  ) => {
    if (
      !designData.logistics_data?.[key] &&
      isEmpty(designData.logistics_data?.[key])
    ) {
      const startingObj = { [nanoid()]: {} };
      updateLogisticsDataValue(key, startingObj);
    }
  };

  const fetchDesign = async (designId: string): Promise<void> => {
    setLoading(true);
    setError(false);
    const response = await fetch(`/api/order/get/${designId}/`);
    if (!response.ok) {
      // Handle this
      setError(true);
      return;
    }
    const data = (await response.json()) as DesignData;
    setDesignData(data);
    setTriggerDesignUpdate(triggerDesignUpdate + 1);
    setLoading(false);
  };

  const shouldFetchDesign = () => {
    const correctMode = ['edit', 'view', 'create-variant'].includes(designMode);
    return correctMode && !usePropDesignId;
  };

  useEffect(() => {
    if (shouldFetchDesign() && id) {
      fetchDesign(id);
    }
  }, []);

  useEffect(() => {
    // Used in design-quick-view-modal where we need to dynamically change designData
    if (propDesignId && usePropDesignId) {
      fetchDesign(propDesignId);
    }
  }, [propDesignId]);

  useEffect(() => {
    initializeLogisticsData('transport');
    initializeLogisticsData('one_off_items');
    initializeLogisticsData('other_weekly_rentals');
  }, [initializeLogisticsData]);

  // SETTING OF TOTAL PRICES

  useEffect(() => {
    const labourPrice = LABOUR_FIELDS.reduce((total, labourField) => {
      return total + (designData.logistics_data?.[labourField]?.cost || 0);
    }, 0).toFixed(2);
    updateLogisticsDataObject('labour_cost', 'price', labourPrice);
  }, [
    designData.logistics_data?.installation_labour,
    designData.logistics_data?.commissioning_labour,
    designData.logistics_data?.decommissioning_labour,
    designData.logistics_data?.deinstallation_labour,
  ]);

  useEffect(() => {
    const transportPrice = Object.values(getTransportOptions())
      .reduce((p, c) => (Number(c.total_price) || 0) + p, 0)
      .toFixed(2);
    updateLogisticsDataObject('transportation_cost', 'price', transportPrice);
  }, [designData.logistics_data?.transport]);

  useEffect(() => {
    const oneOffItemsPrice = Object.values(getOneOffItems())
      .reduce((p, c) => {
        return (Number(c?.price) || 0) + p;
      }, 0)
      .toFixed(2);
    updateLogisticsDataObject('one_off_items_cost', 'price', oneOffItemsPrice);
  }, [designData.logistics_data?.one_off_items]);

  useEffect(() => {
    const otherWeeklyRentals =
      designData.logistics_data.other_weekly_rentals || {};
    const totalOtherRentalsPriceWeeklyPrice = Object.values(
      otherWeeklyRentals
    ).reduce((p, c) => {
      return (Number(c?.weekly_rental_price) || 0) + p;
    }, 0);
    const totalOtherRentalsPrice =
      totalOtherRentalsPriceWeeklyPrice *
      (designData.logistics_data?.number_of_weeks || 0);
    updateLogisticsDataObject(
      'other_weekly_rentals_cost',
      'price',
      totalOtherRentalsPrice.toFixed(2)
    );
  }, [
    designData.logistics_data.other_weekly_rentals,
    designData.logistics_data.number_of_weeks,
  ]);

  useEffect(() => {
    let weeklyEquipmentPrice = 0;
    Object.values(selectedItems).forEach((itemOrder) => {
      weeklyEquipmentPrice +=
        itemOrder.quantity * itemOrder.weekly_rental_price;
    });
    updateLogisticsDataValue(
      'weekly_equipment_price',
      weeklyEquipmentPrice.toFixed(2)
    );
  }, [designData.selected_items]);

  useEffect(() => {
    const totalEquipmentPrice =
      (Number(designData.logistics_data?.weekly_equipment_price) || 0) *
      (designData.logistics_data?.number_of_weeks || 0);
    updateLogisticsDataObject(
      'equipment_cost',
      'price',
      totalEquipmentPrice.toFixed(2)
    );
  }, [
    designData.logistics_data?.weekly_equipment_price,
    designData.logistics_data.number_of_weeks,
  ]);

  useEffect(() => {
    const priceFields = [
      'equipment_cost',
      'one_off_items_cost',
      'transportation_cost',
      'labour_cost',
      'other_weekly_rentals_cost',
    ] as const;
    const totalCost = priceFields.reduce((p, fieldName) => {
      return (Number(designData.logistics_data?.[fieldName]?.price) || 0) + p;
    }, 0);
    const discountedCost = priceFields.reduce((p, fieldName) => {
      const discountedCost =
        (Number(designData.logistics_data?.[fieldName]?.price) || 0) *
        (1 -
          (Number(designData.logistics_data?.[fieldName]?.discount) || 0) /
            100);
      return p + discountedCost;
    }, 0);
    updateLogisticsDataValue('total_price_pre_discount', totalCost.toFixed(2));
    updateLogisticsDataValue('total_price', discountedCost.toFixed(2));
  }, [
    designData.logistics_data?.equipment_cost,
    designData.logistics_data?.one_off_items_cost,
    designData.logistics_data?.transportation_cost,
    designData.logistics_data?.labour_cost,
    designData.logistics_data?.other_weekly_rentals_cost,
  ]);

  useEffect(() => {
    const { number_of_weeks, start_date } = designData.logistics_data;
    if (number_of_weeks !== undefined && start_date) {
      const endDate = new Date(start_date);
      endDate.setDate(endDate.getDate() + number_of_weeks * 7);
      updateLogisticsDataValue('end_date', endDate.toISOString());
    }
  }, [
    designData.logistics_data.start_date,
    designData.logistics_data.number_of_weeks,
  ]);

  // UPDATE DERIVED DATA
  useEffect(() => {
    const labourDiscountedPrice = pricingToDiscountedPriceNumber(
      designData.logistics_data.labour_cost
    );
    const transportDiscountPrice = pricingToDiscountedPriceNumber(
      designData.logistics_data.transportation_cost
    );
    const labourAndTransportPrice = formatNumberPrice(
      labourDiscountedPrice + transportDiscountPrice
    );
    setDerivedData((prev) => ({
      ...prev,
      labourAndTransportPrice,
    }));
  }, [
    designData.logistics_data.transportation_cost,
    designData.logistics_data.labour_cost,
  ]);

  useEffect(() => {
    const equipmentDiscountedPrice = pricingToDiscountedPriceNumber(
      designData.logistics_data.equipment_cost
    );
    const equipmentPrice = formatNumberPrice(equipmentDiscountedPrice);
    const equipmentWeeklyPriceNumber = designData?.logistics_data
      ?.number_of_weeks
      ? equipmentDiscountedPrice / designData?.logistics_data?.number_of_weeks
      : 0;
    const otherWeeklyRentalsDiscountedPrice = pricingToDiscountedPriceNumber(
      designData.logistics_data.other_weekly_rentals_cost
    );
    const totalEquipmentPriceNumber =
      equipmentDiscountedPrice + otherWeeklyRentalsDiscountedPrice;
    const totalEquipmentPrice = formatNumberPrice(totalEquipmentPriceNumber);
    const totalEquipmentWeeklyPriceNumber = designData?.logistics_data
      ?.number_of_weeks
      ? totalEquipmentPriceNumber / designData.logistics_data.number_of_weeks
      : 0;
    const totalEquipmentWeeklyPrice = formatNumberPrice(
      totalEquipmentWeeklyPriceNumber
    );
    const equipmentWeeklyPrice = formatNumberPrice(equipmentWeeklyPriceNumber);
    setDerivedData((prev) => ({
      ...prev,
      equipmentPrice,
      equipmentWeeklyPrice,
      totalEquipmentPrice,
      totalEquipmentWeeklyPrice,
    }));
  }, [
    designData.logistics_data.equipment_cost,
    designData.logistics_data.other_weekly_rentals_cost,
  ]);

  useEffect(() => {
    const oneOffItemsDiscountedPrice = pricingToDiscountedPriceNumber(
      designData.logistics_data.one_off_items_cost
    );
    const otherWeeklyRentalsDiscountedPrice = pricingToDiscountedPriceNumber(
      designData.logistics_data.other_weekly_rentals_cost
    );
    const totalExtraPrice = formatNumberPrice(
      oneOffItemsDiscountedPrice + otherWeeklyRentalsDiscountedPrice
    );
    setDerivedData((prev) => ({
      ...prev,
      extrasPrice: totalExtraPrice,
    }));
  }, [
    designData.logistics_data.one_off_items_cost,
    designData.logistics_data.other_weekly_rentals_cost,
  ]);

  const getTransportOptions = (): Record<string, Partial<TransportData>> => {
    return designData.logistics_data?.transport || {};
  };

  const getOneOffItems = (): Record<string, Partial<OneOffItem>> => {
    return designData.logistics_data?.one_off_items || {};
  };

  const updateDesignDetails = (
    fieldName: LogisticsFieldName | string,
    formValue: string
  ) => {
    setDesignData((prevDesignData) => {
      return {
        ...prevDesignData,
        design_details: {
          ...prevDesignData.design_details,
          [fieldName]: formValue,
        },
      };
    });
  };

  const updateLogisticsDataObject = (
    objectName: LogisticsFieldName,
    fieldName: string,
    fieldValue: string | number
  ) => {
    // Use this one to update SINGLE FIELDS in third level objects
    setDesignData((prevDesignData) => {
      const logisticsDataObj = prevDesignData.logistics_data[objectName] as
        | LabourData
        | Pricing
        | Record<string, TransportData>
        | undefined;
      return {
        ...prevDesignData,
        logistics_data: {
          ...prevDesignData.logistics_data,
          [objectName]: {
            ...logisticsDataObj,
            [fieldName]: fieldValue,
          },
        },
      };
    });
  };

  const updateLogisticsDataValue = (
    fieldName: LogisticsFieldName | string,
    formValue:
      | string
      | number
      | null
      | Partial<TransportData>
      | LabourData
      | Pricing
  ) => {
    // Use this one to update the whole object or a single field in the second level
    setDesignData((prevDesignData) => {
      return {
        ...prevDesignData,
        logistics_data: {
          ...prevDesignData.logistics_data,
          [fieldName]: formValue,
        },
      };
    });
  };

  const updateClientData = (
    formValues: Partial<Address | Contact> | boolean,
    fieldName: string
  ) => {
    setDesignData((prevDesignData) => {
      return {
        ...prevDesignData,
        client_data: {
          ...prevDesignData.client_data,
          [fieldName]: formValues,
        },
      };
    });
  };

  const cleanEmptySelectedItems = () => {
    setDesignData((prevDesignData) => {
      const prevSelectedItems = prevDesignData.selected_items;
      return {
        ...prevDesignData,
        selected_items: Object.entries(prevSelectedItems).reduce(
          (newSelectedItems, [id, itemOrder]) => {
            if (itemOrder.quantity > 0) {
              newSelectedItems[id] = itemOrder;
            }
            return newSelectedItems;
          },
          {} as SelectedItems
        ),
      };
    });
  };

  const updateSelectedItemsQuantity = (
    item: Item | ItemOrder,
    newQuantity: number,
    cleanEmpties = true
  ) => {
    setDesignData((prevDesignData) => {
      const prevSelectedItems = prevDesignData.selected_items;
      if (!newQuantity && cleanEmpties) {
        const { [item.id]: removedItem, ...newSelectedItems } =
          prevSelectedItems;
        return {
          ...prevDesignData,
          selected_items: newSelectedItems,
        };
      }
      return {
        ...prevDesignData,
        selected_items: {
          ...prevSelectedItems,
          [item.id]: {
            ...item,
            quantity: newQuantity,
          },
        },
      };
    });
  };

  const updateSelectedItems = (
    item: Item,
    quantityDelta: number,
    removeEmpties = true
  ) => {
    // updates selectedItems by adding or subtracting a certain
    // quantity of a specific item. If the resulting quantity is 0 or less,
    // it removes the item from the selectedItems object.

    // Add cleanEmpties = true arg to this and conditionally remove items with 0 quantity.
    // this can then be used for additional items modal to make sure we don't delet 0 values
    // rows whilst editing. Can then call a cleanSelectedItems function that removes all
    // items with 0 quantity on a changing page
    setDesignData((prevDesignData) => {
      const prevSelectedItems = prevDesignData.selected_items;
      const itemToUpdate = prevSelectedItems[item.id];
      const newQuantity = (itemToUpdate?.quantity || 0) + quantityDelta;
      if (newQuantity <= 0 && removeEmpties) {
        if (!itemToUpdate) {
          return prevDesignData;
        }
        const { [item.id]: removedItem, ...newSelectedItems } =
          prevSelectedItems;
        return {
          ...prevDesignData,
          selected_items: newSelectedItems,
        };
      }
      return {
        ...prevDesignData,
        selected_items: {
          ...prevSelectedItems,
          [item.id]: {
            ...item,
            quantity: newQuantity,
          },
        },
      };
    });
  };

  const updateDesignData = (partialData: Partial<DesignData>): void => {
    setDesignData((prevDesignData) => {
      return {
        ...prevDesignData,
        ...partialData,
      };
    });
  };

  const cleanDataForSave = (): void => {
    // Clean extras to remove entries with '' for name and price
    const oneOffItems = designData.logistics_data.one_off_items;
    if (oneOffItems) {
      const cleanOneOffItems = transform(
        oneOffItems,
        (result, value, key) => {
          if (value.name || value.price) {
            result[key] = value;
          }
        },
        {} as Record<string, OneOffItem>
      );
      updateLogisticsDataValue('one_off_items', cleanOneOffItems);
    }
    const otherWeeklyRentals = designData.logistics_data.other_weekly_rentals;
    if (otherWeeklyRentals) {
      const cleanOtherWeeklyRentals = transform(
        otherWeeklyRentals,
        (result, value, key) => {
          if (value.name || value.weekly_rental_price) {
            result[key] = value;
          }
        },
        {} as Record<string, OtherWeeklyRental>
      );
      updateLogisticsDataValue('other_weekly_rentals', cleanOtherWeeklyRentals);
    }
    return;
  };

  return (
    <DesignDataContext.Provider
      value={{
        designData,
        derivedData,
        designId: id,
        loading,
        error,
        triggerDesignUpdate,
        updateDesignData,
        cleanEmptySelectedItems,
        cleanDataForSave,
        updateSelectedItems,
        updateSelectedItemsQuantity,
        updateClientData,
        updateLogisticsDataObject,
        updateLogisticsDataValue,
        updateDesignDetails,
        getTransportOptions,
        getOneOffItems,
      }}
    >
      {children}
    </DesignDataContext.Provider>
  );
};
