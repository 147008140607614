import React, { useContext, useEffect } from 'react';
import Text from '../../ui/text';
import styles from './style.module.css';
import ContactForm from '../contact-form';
import CompanyForm from '../company-form';
import { DesignDataContext } from '../../common/context/designData';
import { isEmpty, some } from 'lodash';

const ClientInfo = () => {
  const { designData, updateClientData } = useContext(DesignDataContext);
  useEffect(() => {
    const hasSiteContactInfo = some(
      designData.client_data.site_contact,
      (field) => !isEmpty(field)
    );
    if (!designData?.client_data?.showSiteContact && hasSiteContactInfo) {
      updateClientData(true, 'showSiteContact');
    }
  }, [designData.client_data.site_contact]);

  useEffect(() => {
    const hasCompanySiteInfo = some(
      designData.client_data.company_site,
      (field) => !isEmpty(field)
    );
    if (!designData?.client_data?.showCompanySite && hasCompanySiteInfo) {
      updateClientData(true, 'showCompanySite');
    }
  }, [designData.client_data.company_site]);

  return (
    <div>
      <CompanyForm
        title="Company"
        nameLabel="Company name"
        fieldName="company"
        formValues={designData.client_data.company}
        onFormChange={updateClientData}
      ></CompanyForm>
      <div className={styles.checkBox}>
        <Text size="sm">Use this address for delivery too</Text>
        <label>
          <input
            type="radio"
            name="use-address-delivery"
            value="yes"
            style={{ fontFamily: "'Helvetica Neue', sans-serif" }}
            checked={!designData.client_data.showCompanySite}
            onChange={() => updateClientData(false, 'showCompanySite')}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="use-address-delivery"
            value="no"
            style={{ fontFamily: "'Helvetica Neue', sans-serif" }}
            checked={!!designData.client_data.showCompanySite}
            onChange={() => updateClientData(true, 'showCompanySite')}
          />
          No
        </label>
      </div>

      <div className="result">
        {designData.client_data.showCompanySite && (
          <CompanyForm
            title="Site address"
            nameLabel="Site name"
            fieldName="company_site"
            formValues={designData.client_data.company_site}
            onFormChange={updateClientData}
          ></CompanyForm>
        )}
      </div>
    </div>
  );
};

export default ClientInfo;
