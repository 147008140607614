import React from 'react';
import Icon from '../icon';
import styles from './style.module.css';

const Footer = (): React.ReactElement => {
  return (
    <div className={styles.footer}>
      <Icon name="traneTecnologies"></Icon>
    </div>
  );
};

export default Footer;
