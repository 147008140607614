import { CSSProperties } from 'react';
import { ItemIconNodeData } from '../../types';

export const globalHandleStyle = {
  backgroundColor: '#47ff4761',
  border: '2px dashed #1bff1b',
  borderRadius: '4px',
  cursor: 'crosshair',
  opacity: 1,
  height: '15px',
  width: '30px',
  zIndex: 99,
};

export const createSharedHandleStyle = (
  data: ItemIconNodeData,
  style: CSSProperties
) => {
  const newStyle = {
    ...globalHandleStyle,
    ...style,
  };
  if (!data.displayHandles) {
    const hiddenHandleStyle = {
      height: '1px',
      width: '1px',
      opacity: 0,
      cursor: 'grab',
      pointerEvents: 'none' as const,
    };
    return { ...newStyle, ...hiddenHandleStyle };
  }
  return newStyle;
};
