import React, { useEffect } from 'react';
import styles from './style.module.css';
import { UseAuth } from '../../components/auth-component/auth-provider';

type ButtonVariant =
  | 'secondary'
  | 'trane-primary'
  | 'trane-wide'
  | 'trane-icon'
  | 'add-icon'
  | 'close'
  | 'toolbar'
  | 'icon-and-text'
  // REAL ONES
  | 'primary'
  | 'secondary'
  | 'directory'
  | 'filter'
  | 'toolbar'
  | 'empty';

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  variant?: ButtonVariant;
  size?: string;
  listenEnter?: boolean;
  onClick: () => void;
  active?: boolean;
  style?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({
  active,
  variant = 'primary',
  children,
  onClick,
  size = '',
  style = {},
  listenEnter,
  disabled = false,
}) => {
  const { isIpad } = UseAuth();
  const handleEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && onClick) {
      onClick();
    }
  };

  useEffect(() => {
    if (listenEnter) {
      window.addEventListener('keyup', handleEnter);
    }

    return () => {
      window.removeEventListener('keyup', handleEnter);
    };
  }, [listenEnter, handleEnter]);

  const buttonClass = `${styles[variant]} ${active ? styles.active : ''}`;

  return (
    <button
      style={style}
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
