// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkBox__DHStx {
  font-family: 'Helvetica Neue';
  display: flex;
  gap: 24px;
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/client-info/style.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,aAAa;EACb,SAAS;EACT,gBAAgB;AAClB","sourcesContent":[".checkBox {\n  font-family: 'Helvetica Neue';\n  display: flex;\n  gap: 24px;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkBox": `checkBox__DHStx`
};
export default ___CSS_LOADER_EXPORT___;
