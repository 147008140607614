import React, { useContext, useEffect, useMemo, useState } from 'react';
import Text from '../../ui/text';
import Button from '../../ui/button';
import Icon from '../../ui/icon';
import styles from './style.module.css';
import { DropdownOption, TransportData } from '../../types';
import Dropdown from '../../ui/dropdown';
import { DesignDataContext } from '../../common/context/designData';
import { Address } from '../../types';
import { isEmpty, some } from 'lodash';
import Input from '../../ui/input';

type VehicleConfig = {
  label: string;
  base_cost: number;
  cost_per_mile: number;
};

export const VEHICLE_CONFIGS: Record<string, VehicleConfig> = {
  hiab: { label: 'Hiab', base_cost: 700, cost_per_mile: 3.5 },
  wragon_and_drag: {
    label: 'Wagon & Drag',
    base_cost: 700,
    cost_per_mile: 3.5,
  },
  flat_bed_artic: {
    label: 'Flat Bed Artic 40ft',
    base_cost: 600,
    cost_per_mile: 3,
  },
  four_by_four: { label: '4x4 3.5t', base_cost: 400, cost_per_mile: 2 },
  short_flatbed: { label: 'Short Flatbed', base_cost: 500, cost_per_mile: 2.5 },
};

const VehicleOptions = [
  { value: 'hiab', label: 'Hiab' },
  { value: 'wragon_and_drag', label: 'Wagon & Drag' },
  { value: 'flat_bed_artic', label: 'Flat Bed Artic 40ft' },
  { value: 'four_by_four', label: '4x4 3.5t' },
  { value: 'short_flatbed', label: 'Short Flatbed' },
];

type TransportCardProps = {
  transportOption: Partial<TransportData>;
  id: string;
  transportOptions: Record<string, Partial<TransportData>>;
  onDelete: (id: string) => () => void;
};

export const TransportCard = ({
  transportOption,
  transportOptions,
  id,
  onDelete,
}: TransportCardProps) => {
  const { updateLogisticsDataValue, designData } =
    useContext(DesignDataContext);
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
  const config = transportOption?.vehicle_type
    ? VEHICLE_CONFIGS[transportOption.vehicle_type]
    : VEHICLE_CONFIGS['hiab'];

  useEffect(() => {
    // Only allow destinations to be selected if they have been filled in
    const options: Record<string, DropdownOption> = {
      company: { label: 'Company Address', value: 'company' },
      company_site: { label: 'Site Address', value: 'company_site' },
    };
    const availableOptions = (['company_site', 'company'] as const).reduce(
      (dropdownOptions: DropdownOption[], destination) => {
        if (
          some(designData.client_data[destination], (field) => !isEmpty(field))
        ) {
          dropdownOptions.push(options[destination]);
        }
        return dropdownOptions;
      },
      []
    );
    setDropdownOptions(availableOptions);
  }, [designData.client_data]);

  const calculateCostAndTotalCost = (
    transportOptions: Partial<TransportData>
  ) => {
    if (!transportOptions.vehicle_type) {
      return { cost: undefined, total_price: undefined };
    }
    let costNumber = VEHICLE_CONFIGS[transportOptions.vehicle_type].base_cost;
    if (
      transportOptions.number_of_miles &&
      transportOptions.number_of_miles > 200
    ) {
      costNumber +=
        VEHICLE_CONFIGS[transportOptions.vehicle_type].cost_per_mile *
        (transportOptions.number_of_miles - 200);
    }
    if (transportOptions.is_return_trip) {
      costNumber *= 2;
    }
    const total_price = (costNumber * 1.1).toFixed(2);
    const cost = costNumber.toFixed(2);
    return { cost, total_price };
  };

  const handleTransportChange = (
    partTransportData: Partial<TransportData>,
    key: string
  ) => {
    const updatedTransportOptions = {
      ...transportOptions,
      [key]: {
        ...transportOptions[key],
        ...partTransportData,
        ...calculateCostAndTotalCost({
          ...transportOptions[key],
          ...partTransportData,
        }),
      },
    };
    updateLogisticsDataValue('transport', updatedTransportOptions);
  };

  const handleMileageInput = (
    event: React.FormEvent<HTMLInputElement>,
    key: string
  ) => {
    const newMileage = Number(event.currentTarget.value.replace(/\D/, ''));
    if (newMileage < 99999) {
      handleTransportChange({ number_of_miles: newMileage }, key);
    }
  };

  const getSelectedDestination = (
    transportOption: Partial<TransportData>
  ): DropdownOption | undefined => {
    const dropdownOptions: Record<string, DropdownOption> = {
      company: { label: 'Company Address', value: 'company' },
      company_site: { label: 'Site Address', value: 'company_site' },
    };
    if (transportOption.destination === null) {
      return undefined;
    }
    // Two key cases here. The case the transportOption.destination was filled by the FE and it will be a string
    // or the case the transportOption.destination was filled by the BE and it will be an Address object
    if (typeof transportOption.destination === 'string') {
      return dropdownOptions[transportOption.destination];
    }
    if (typeof transportOption.destination === 'object') {
      // We can check if the company id matches the company id of the destination to know the destination
      const destination = transportOption.destination as Address;
      const { company } = designData.client_data;
      if (destination.id === company?.id) {
        return dropdownOptions['company'];
      } else {
        return dropdownOptions['company_site'];
      }
    }
  };

  return (
    <div className={styles.transportRow} key={id}>
      <div className={styles.vehicleDestinationDistanceReturnContainer}>
        <div className={styles.vehicleDestinationContainer}>
          <div className={styles.transportVehicle}>
            <Text size="m" fontWeight="400">
              Vehicle type
            </Text>
            <div className={styles.transportDropdown}>
              <Dropdown
                dropdownWidth="150px"
                options={VehicleOptions}
                onSelect={(option) =>
                  handleTransportChange({ vehicle_type: `${option.value}` }, id)
                }
                selectedValue={
                  transportOption.vehicle_type
                    ? {
                        label: config.label,
                        value: transportOption.vehicle_type,
                      }
                    : undefined
                }
              ></Dropdown>
            </div>
          </div>
          <div className={styles.transportDestination}>
            <Text size="m" fontWeight="400">
              Destination
            </Text>
            <div className={styles.destinationDropdown}>
              <Dropdown
                dropdownWidth="250px"
                options={dropdownOptions}
                noOptionsMessages="No address added"
                onSelect={(option) =>
                  handleTransportChange({ destination: `${option.value}` }, id)
                }
                selectedValue={getSelectedDestination(transportOption)}
              ></Dropdown>
            </div>
          </div>
        </div>
        <div className={styles.distanceReturnContainer}>
          <div className={styles.transportDistance}>
            <Text size="m" fontWeight="400">
              Distance (miles)
            </Text>
            <div className={styles.distanceInput}>
              <Input
                className={styles.quantityInput}
                style={{ width: 85 }}
                value={transportOption.number_of_miles || ''}
                onChange={(event) => handleMileageInput(event, id)}
              ></Input>
            </div>
          </div>
          <div className={styles.returnTrip}>
            <Text size="m" fontWeight="400">
              Return
            </Text>
            <div className={styles.returnInput}>
              <label>
                <input
                  type="radio"
                  name={`return-trip-${id}`}
                  value="yes"
                  checked={!!transportOption.is_return_trip}
                  style={{ fontFamily: "'Helvetica Neue', sans-serif" }}
                  onChange={() =>
                    handleTransportChange({ is_return_trip: true }, id)
                  }
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name={`return-trip-${id}`}
                  value="no"
                  checked={!transportOption.is_return_trip}
                  style={{ fontFamily: "'Helvetica Neue', sans-serif" }}
                  onChange={() =>
                    handleTransportChange({ is_return_trip: false }, id)
                  }
                />
                No
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.costTotalCostContainer}>
        <div className={styles.row}>
          <div className={styles.titles}>
            <Text size="m" fontWeight="400" color="dark-grey">
              Cost
            </Text>
          </div>

          <div className={styles.price}>
            <Text size="m" variant="standard">
              {`£${transportOption.cost || 0}`}
            </Text>
          </div>
        </div>

        <div className={styles.row2}>
          <div className={styles.titles}>
            <Text size="m" fontWeight="400" color="dark-grey">
              Total Price
            </Text>
          </div>

          <div className={styles.price}>
            <Text size="m" variant="standard">
              {`£${transportOption.total_price || 0}`}
            </Text>
          </div>
        </div>
      </div>
      <Button
        variant="empty"
        onClick={onDelete(id)}
        style={{ position: 'absolute', top: '2px', right: '2px' }}
      >
        <Icon name="binIconV2" height="25px" width="19px"></Icon>
      </Button>
    </div>
  );
};
