import React from 'react';
import { Handle, Node, Position } from 'reactflow';
import Icon from '../../ui/icon';
import { createSharedHandleStyle } from '../../common/config/globalHandleStyle';
import { ItemIconNodeData } from '../../types';

const NodeSystemIn = ({ data }: { data: ItemIconNodeData }) => {
  const sharedHandleStyle = createSharedHandleStyle(data, {});
  const rightStyle = { ...sharedHandleStyle, top: 6, left: 21 };
  return (
    <div>
      <Handle type="source" position={Position.Top} id="b" style={rightStyle} />
      <Icon
        name="systemIn"
        height="42px"
        width="42px"
        showError={data.showError}
        label="SYSTEM IN"
        labelStyle={{ top: '41px' }}
      ></Icon>
    </div>
  );
};

export default NodeSystemIn;
