// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer__BfCbP {
  display: flex;
  flex-direction: column;
}

.titleContainer___5dTk {
  display: grid;
  grid-template-columns: 1fr 65px 143px 107px;
  grid-template-rows: 40px;
  width: 100%;
  justify-items: start;
  padding-left: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #f5f5f5;
  gap: 15px;
}

.rowContainer__w_eHz {
  display: grid;
  grid-template-columns: 1fr 65px 143px 107px;
  grid-template-rows: minmax(32px, auto);
  align-items: center;
  padding: 4px 0 4px 10px;
  gap: 15px;
  box-sizing: border-box;
  border-top: none;
}

.quantityRow__kCJXM,
.unitPricePerWeekRow__cykRz,
.totalPricePerWeekRow__me6JF {
  display: flex;
  justify-content: center;
}

.mainContainer__BfCbP:last-of-type {
  border-top: none;
  border-radius: 0 0 6px 6px;
}

.selectedItemsTitle__pYyNy {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.rowDivider__QHpGA {
  background-color: #f5f5f5;
  height: 1px;
  grid-column: 1 / 5;
}
`, "",{"version":3,"sources":["webpack://./src/components/selected-items-table/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,2CAA2C;EAC3C,wBAAwB;EACxB,WAAW;EACX,oBAAoB;EACpB,kBAAkB;EAClB,sBAAsB;EACtB,gCAAgC;EAChC,SAAS;AACX;;AAEA;EACE,aAAa;EACb,2CAA2C;EAC3C,sCAAsC;EACtC,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;;;EAGE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".mainContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.titleContainer {\n  display: grid;\n  grid-template-columns: 1fr 65px 143px 107px;\n  grid-template-rows: 40px;\n  width: 100%;\n  justify-items: start;\n  padding-left: 10px;\n  box-sizing: border-box;\n  border-bottom: 1px solid #f5f5f5;\n  gap: 15px;\n}\n\n.rowContainer {\n  display: grid;\n  grid-template-columns: 1fr 65px 143px 107px;\n  grid-template-rows: minmax(32px, auto);\n  align-items: center;\n  padding: 4px 0 4px 10px;\n  gap: 15px;\n  box-sizing: border-box;\n  border-top: none;\n}\n\n.quantityRow,\n.unitPricePerWeekRow,\n.totalPricePerWeekRow {\n  display: flex;\n  justify-content: center;\n}\n\n.mainContainer:last-of-type {\n  border-top: none;\n  border-radius: 0 0 6px 6px;\n}\n\n.selectedItemsTitle {\n  display: flex;\n  justify-content: center;\n  padding-bottom: 20px;\n}\n\n.rowDivider {\n  background-color: #f5f5f5;\n  height: 1px;\n  grid-column: 1 / 5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `mainContainer__BfCbP`,
	"titleContainer": `titleContainer___5dTk`,
	"rowContainer": `rowContainer__w_eHz`,
	"quantityRow": `quantityRow__kCJXM`,
	"unitPricePerWeekRow": `unitPricePerWeekRow__cykRz`,
	"totalPricePerWeekRow": `totalPricePerWeekRow__me6JF`,
	"selectedItemsTitle": `selectedItemsTitle__pYyNy`,
	"rowDivider": `rowDivider__QHpGA`
};
export default ___CSS_LOADER_EXPORT___;
