// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabsContainer__p7K2Y {
  margin-top: 25px;
}

.oneOffItemsContainer__jPwMx {
  display: grid;
  grid-template-columns: 2.5fr 1fr 50px;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  width: 90%;
  margin-top: 10px;
}

.otherWeeklyRentalsContainer__zDqGZ {
  display: grid;
  grid-template-columns: 2.5fr 1.5fr 1fr 50px;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  width: 90%;
  margin-top: 10px;
}

.divider__hdcKc {
  width: 100%;
  grid-column: 1 / 5;
  background-color: var(--border-grey);
  height: 1px;
  margin: 5px 0 15px 0;
}

.rowDivider__xxLuX {
  margin: 10px 0;
  grid-column: 1 / 5;
}
`, "",{"version":3,"sources":["webpack://./src/components/extras-form/style.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,wBAAwB;EACxB,qBAAqB;EACrB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,2CAA2C;EAC3C,wBAAwB;EACxB,qBAAqB;EACrB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,oCAAoC;EACpC,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".tabsContainer {\n  margin-top: 25px;\n}\n\n.oneOffItemsContainer {\n  display: grid;\n  grid-template-columns: 2.5fr 1fr 50px;\n  grid-template-rows: auto;\n  grid-column-gap: 20px;\n  width: 90%;\n  margin-top: 10px;\n}\n\n.otherWeeklyRentalsContainer {\n  display: grid;\n  grid-template-columns: 2.5fr 1.5fr 1fr 50px;\n  grid-template-rows: auto;\n  grid-column-gap: 20px;\n  width: 90%;\n  margin-top: 10px;\n}\n\n.divider {\n  width: 100%;\n  grid-column: 1 / 5;\n  background-color: var(--border-grey);\n  height: 1px;\n  margin: 5px 0 15px 0;\n}\n\n.rowDivider {\n  margin: 10px 0;\n  grid-column: 1 / 5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsContainer": `tabsContainer__p7K2Y`,
	"oneOffItemsContainer": `oneOffItemsContainer__jPwMx`,
	"otherWeeklyRentalsContainer": `otherWeeklyRentalsContainer__zDqGZ`,
	"divider": `divider__hdcKc`,
	"rowDivider": `rowDivider__xxLuX`
};
export default ___CSS_LOADER_EXPORT___;
