// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --icon-height: 16px;
  --icon-width: 16px;
  --fill: #4f4d4d;
  --rotation: rotate(0deg);
}

.icon-container__JabpE {
  width: var(--icon-width);
  height: var(--icon-height);
}

.icon-container__JabpE > div {
  font-size: 0;
}

.icon-container__JabpE > div > div {
  display: inline-block;
  font-size: 0;
  width: 100%;
}

.label__yejbY {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  background-color: #ffffffd6;
}

.icon-container__JabpE > div > div > svg {
  height: 100%;
  width: 100%;
  fill: var(--fill);
  max-height: var(--icon-height);
  max-width: var(--icon-width);
  transform: var(--rotation);
}

.warning-container__ltDP2 {
  position: absolute;
  top: -12px;
  right: -10px;
  height: 16px;
  width: 16px;
}

.warning-container__ltDP2 > div > div {
  display: flex;
}

.warning-container__ltDP2 > div > div > svg {
  height: 100%;
  width: 100%;
  fill: #ff1212;
  max-height: var(--icon-height);
  max-width: var(--icon-width);
}
`, "",{"version":3,"sources":["webpack://./src/ui/icon/style.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,2BAA2B;EAC3B,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,8BAA8B;EAC9B,4BAA4B;EAC5B,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,4BAA4B;AAC9B","sourcesContent":[":root {\n  --icon-height: 16px;\n  --icon-width: 16px;\n  --fill: #4f4d4d;\n  --rotation: rotate(0deg);\n}\n\n.icon-container {\n  width: var(--icon-width);\n  height: var(--icon-height);\n}\n\n.icon-container > div {\n  font-size: 0;\n}\n\n.icon-container > div > div {\n  display: inline-block;\n  font-size: 0;\n  width: 100%;\n}\n\n.label {\n  position: absolute;\n  top: -25px;\n  left: 50%;\n  transform: translateX(-50%);\n  white-space: nowrap;\n  background-color: #ffffffd6;\n}\n\n.icon-container > div > div > svg {\n  height: 100%;\n  width: 100%;\n  fill: var(--fill);\n  max-height: var(--icon-height);\n  max-width: var(--icon-width);\n  transform: var(--rotation);\n}\n\n.warning-container {\n  position: absolute;\n  top: -12px;\n  right: -10px;\n  height: 16px;\n  width: 16px;\n}\n\n.warning-container > div > div {\n  display: flex;\n}\n\n.warning-container > div > div > svg {\n  height: 100%;\n  width: 100%;\n  fill: #ff1212;\n  max-height: var(--icon-height);\n  max-width: var(--icon-width);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-container": `icon-container__JabpE`,
	"label": `label__yejbY`,
	"warning-container": `warning-container__ltDP2`
};
export default ___CSS_LOADER_EXPORT___;
