import React, { Fragment } from 'react';
import styles from './style.module.css';

import Text from '../../ui/text';
import { SelectedItemsBreakdownObject, SummarySectionProps } from '../../types';
import ValueCard from '../../ui/value-card';
import SummarySectionCardComponent from '../summary-section-card-component';
import { getDiscountedDisplayPrice } from '../../common/helpers/number-helpers';

interface SummaryItemsComponentProps extends SummarySectionProps {
  config?: SelectedItemsBreakdownObject;
  discount?: string;
}

const SummaryItemsComponent = ({
  config,
  discount,
  toggleOpen,
  open,
}: SummaryItemsComponentProps) => {
  const selectedItems = Object.values(config?.selectedItems || {});
  return (
    <SummarySectionCardComponent
      toggleOpen={toggleOpen}
      open={open}
      title={config?.title}
      infoText={{
        lineOne: `Weekly price: £${config?.weeklyRentalPrice}`,
        lineTwo: `Total price: £${config?.totalPrice}`,
      }}
    >
      {!config || selectedItems.length === 0 ? (
        <Text color="light-grey">-</Text>
      ) : (
        <>
          <div className={styles.itemsContainer}>
            <Text fontWeight="600" style={{ justifySelf: 'start' }}>
              Item name
            </Text>
            <Text fontWeight="600">Quantity</Text>
            <Text fontWeight="600">Unit price</Text>
            <Text fontWeight="600">Weekly price</Text>
            <div className={styles.divider} style={{ marginTop: 3 }}></div>
            {Object.values(config?.selectedItems).map((itemOrder, index) => {
              const discountedDisplayWeeklyPrice = getDiscountedDisplayPrice(
                itemOrder.weekly_rental_price,
                discount
              );
              const discountedDisplayTotalPrice = getDiscountedDisplayPrice(
                itemOrder.weekly_rental_price * itemOrder.quantity,
                discount
              );
              return (
                <Fragment key={index}>
                  {index === 0 && <div className={styles.spacer}></div>}
                  <Text fontWeight="500">{itemOrder.name}</Text>
                  <Text>{itemOrder.quantity}</Text>
                  <Text>£{discountedDisplayWeeklyPrice}</Text>
                  <Text>£{discountedDisplayTotalPrice}</Text>
                  <div className={styles.spacer}></div>
                </Fragment>
              );
            })}
            <div className={styles.divider} style={{ marginBottom: 5 }}></div>
            <Text fontWeight="400" style={{ gridColumn: '1/4' }}>
              Total
            </Text>
            <Text fontWeight="400">£{config.weeklyRentalPrice}</Text>
          </div>
          <ValueCard
            innerStyle={{ width: '100%' }}
            style={{ marginTop: 30 }}
            price={config?.totalPrice}
            label="Total Price:"
          ></ValueCard>
        </>
      )}
    </SummarySectionCardComponent>
  );
};

export default SummaryItemsComponent;
