import React, { useContext } from 'react';
import Text from '../../ui/text';
import styles from './style.module.css';
import { DesignDataContext } from '../../common/context/designData';
import {
  formatNumberPrice,
  formatPrice,
} from '../../common/helpers/number-helpers';
import ValueCard from '../../ui/value-card';
import NumberInput from '../../ui/number-input';

export const LabourFormCard = () => {
  const { updateLogisticsDataValue, designData } =
    useContext(DesignDataContext);

  const handleHoursInput = (field: string) => (value: number) => {
    const labourObj = {
      number_of_hours: value,
      cost: value * 72,
    };
    updateLogisticsDataValue(field, labourObj);
  };

  return (
    <div className={styles.labourCostsFormContainer}>
      <div className={styles.labourCostsForm}>
        <Text
          fontWeight="600"
          size="m"
          color="dark-grey"
          style={{ paddingLeft: '4px' }}
        >
          Labour type
        </Text>
        <Text fontWeight="600" size="m" color="dark-grey">
          Hours
        </Text>
        <Text fontWeight="600" size="m" color="dark-grey">
          Price
        </Text>
        <div className={styles.divider}></div>
        <Text size="m" fontWeight="400" style={{ paddingLeft: '4px' }}>
          Installation
        </Text>
        <NumberInput
          value={
            designData.logistics_data?.installation_labour?.number_of_hours
          }
          onChange={handleHoursInput('installation_labour')}
        ></NumberInput>
        <Text fontWeight="400" size="m" style={{ paddingRight: '4px' }}>
          £
          {formatNumberPrice(
            designData.logistics_data?.installation_labour?.cost
          )}
        </Text>

        <div className={styles.divider}></div>

        <Text size="m" fontWeight="400" style={{ paddingLeft: '4px' }}>
          Commissioning
        </Text>
        <NumberInput
          value={
            designData.logistics_data?.commissioning_labour?.number_of_hours
          }
          onChange={handleHoursInput('commissioning_labour')}
        ></NumberInput>
        <Text fontWeight="400" size="m" style={{ paddingRight: '4px' }}>
          £
          {formatNumberPrice(
            designData.logistics_data?.commissioning_labour?.cost
          )}
        </Text>

        <div className={styles.divider}></div>

        <Text size="m" fontWeight="400" style={{ paddingLeft: '4px' }}>
          De-commissioning
        </Text>
        <NumberInput
          value={
            designData.logistics_data?.decommissioning_labour?.number_of_hours
          }
          onChange={handleHoursInput('decommissioning_labour')}
        ></NumberInput>
        <Text fontWeight="400" size="m" style={{ paddingRight: '4px' }}>
          £
          {formatNumberPrice(
            designData.logistics_data?.decommissioning_labour?.cost
          )}
        </Text>

        <div className={styles.divider}></div>

        <Text size="m" fontWeight="400" style={{ paddingLeft: '4px' }}>
          De-installation
        </Text>
        <NumberInput
          value={
            designData.logistics_data?.deinstallation_labour?.number_of_hours
          }
          onChange={handleHoursInput('deinstallation_labour')}
        ></NumberInput>
        <Text fontWeight="400" size="m" style={{ paddingRight: '4px' }}>
          £
          {formatNumberPrice(
            designData.logistics_data?.deinstallation_labour?.cost
          )}
        </Text>
        <div className={styles.divider}></div>
      </div>
    </div>
  );
};

const LabourForm = () => {
  const { designData } = useContext(DesignDataContext);

  return (
    <div className={styles.sectionContainer}>
      <LabourFormCard></LabourFormCard>
      <ValueCard
        price={formatPrice(designData.logistics_data.labour_cost?.price)}
        label="Total labour price:"
      ></ValueCard>
    </div>
  );
};

export default LabourForm;
