import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import DirectoryApp from '../directory-app';
import LoginApp from '../login-app';
import { DesignDataProvider } from '../../common/context/designData';
import { colorsConfig } from '../../common/design/colors';

import AuthProvider from '../../components/auth-component';
import ProtectedRoute from '../../components/protected-route';
import DesignApp from '../design-app';
import SearchApp from '../search-app';
import DesignViewApp from '../design-view-app';
import { fontsConfig } from '../../common/design/fonts';

export const AuthContext = React.createContext<string | undefined>(undefined);

function injectColorsAsCSSVariables(colors: Record<string, string>) {
  const root = document.documentElement;

  Object.entries(colors).forEach(([name, value]) => {
    root.style.setProperty(`--${name}`, value);
  });
}

function injectFontFaces(fonts: any) {
  const styleElement = document.createElement('style');
  styleElement.type = 'text/css';

  const fontFaceStyles = fonts
    .map(
      (font: any) => `
    @font-face {
      font-family: '${font.fontFamily}';
      src: ${font.src};
      font-weight: ${font.fontWeight};
      font-style: ${font.fontStyle};
    }
  `
    )
    .join('');

  styleElement.textContent = fontFaceStyles;
  document.head.appendChild(styleElement);
}

const App = () => {
  const [csrf, setCsrf] = React.useState<string | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    injectColorsAsCSSVariables(colorsConfig);
    injectFontFaces(fontsConfig);
  }, []);

  const handleLogin = (csrf?: string) => {
    setCsrf(csrf);
    handleRelocate('directory');
  };

  const handleRelocate = (location: string) => {
    navigate(location);
  };

  // Keys used in context providers when we want to ensure remounting when changing between states
  return (
    <AuthProvider>
      <Routes>
        <Route index element={<LoginApp />} />
        <Route
          path="directory"
          element={
            <ProtectedRoute>
              <DirectoryApp></DirectoryApp>
            </ProtectedRoute>
          }
        />
        <Route
          path="design"
          element={
            <ProtectedRoute key="design">
              <DesignDataProvider key="design">
                <DesignApp designMode="create"></DesignApp>
              </DesignDataProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="search"
          element={
            <ProtectedRoute>
              <SearchApp></SearchApp>
            </ProtectedRoute>
          }
        />
        <Route
          path="design-view/:id"
          element={
            <ProtectedRoute key="design-view">
              <DesignDataProvider designMode="view" key="design-view">
                <DesignViewApp></DesignViewApp>
              </DesignDataProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="design-edit/:id"
          element={
            <ProtectedRoute key="design-edit">
              <DesignDataProvider designMode="edit" key="design-edit">
                <DesignApp designMode="edit"></DesignApp>
              </DesignDataProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="design-create-variant/:id"
          element={
            <ProtectedRoute key="design-create-variant">
              <DesignDataProvider designMode="create-variant" key="design-edit">
                <DesignApp designMode="create-variant"></DesignApp>
              </DesignDataProvider>
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<p>No page</p>} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
