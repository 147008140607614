import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  FitViewOptions,
  applyNodeChanges,
  applyEdgeChanges,
  Controls,
  Background,
  Node,
  Edge,
  NodeChange,
  EdgeChange,
  Connection,
  useReactFlow,
  ConnectionMode,
  useUpdateNodeInternals,
} from 'reactflow';
import 'reactflow/dist/style.css';
import './style.css';

// ****************************
// NODE IMPORTS GO HERE
// ****************************
import NodeSinglePump from '../node-single-pump';
import NodeBufferTank from '../node-buffer-tank';
import NodeAirCooledModularScrew from '../node-air-cooled-modular-screw';
import NodeAirCooledScrew from '../node-air-cooled-screw';
import NodeAirHandlingUnit from '../node-air-handling-unit';
import NodeColdStore from '../node-cold-store';
import NodeDryAirCooler from '../node-dry-air-cooler';
import NodeFanCooledUnit from '../node-fan-cooled-unit';
import NodeLowTempCooler from '../node-low-temp-cooler';
import NodePlateHeatExchanger from '../node-plate-heat-exchanger';
import NodeRemoteConnectivityBox from '../node-remote-connectivity-box';
import NodeRoofTopAirHandler from '../node-roof-top-air-handler';
import NodeScrollCompressor from '../node-scroll-compressor';
import NodeTwinPump from '../node-twin-pump';
import NodeWaterCooledScrew from '../node-water-cooled-screw';
import NodeSystemIn from '../node-system-in';
import NodeSystemOut from '../node-system-out';
import NodeChannel from '../node-channel';
import { ItemIconNode } from '../../types';

// Define custom node type outside of flow component
const nodeTypes = {
  nodeSinglePump: NodeSinglePump,
  nodeBufferTank: NodeBufferTank,
  nodeAirCooledModularScrew: NodeAirCooledModularScrew,
  nodeAirCooledScrew: NodeAirCooledScrew,
  nodeAirHandlingUnit: NodeAirHandlingUnit,
  nodeColdStore: NodeColdStore,
  nodeDryAirCooler: NodeDryAirCooler,
  nodeFanCooledUnit: NodeFanCooledUnit,
  nodeLowTempCooler: NodeLowTempCooler,
  nodePlateHeatExchanger: NodePlateHeatExchanger,
  nodeRemoteConnectivityBox: NodeRemoteConnectivityBox,
  nodeRoofTopAirHandler: NodeRoofTopAirHandler,
  nodeScrollCompressor: NodeScrollCompressor,
  nodeTwinPump: NodeTwinPump,
  nodeWaterCooledScrew: NodeWaterCooledScrew,
  nodeSystemIn: NodeSystemIn,
  nodeSystemOut: NodeSystemOut,
  nodeChannel: NodeChannel,
};

const fitViewOptions: FitViewOptions = {
  padding: 0.2,
  minZoom: 0.1,
};

const edgeStyling = {
  strokeWidth: 4,
  stroke: '#000000',
};

interface FlowComponentProps {
  newNodes: Node[];
  newEdges: Edge[];
  triggerFitView: number;
  showControls: boolean;
}

function FlowViewComponent({
  newNodes,
  newEdges,
  triggerFitView,
  showControls,
}: FlowComponentProps) {
  const { addNodes, addEdges, fitView } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const [nodes, setNodes] = useState<ItemIconNode[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  useEffect(() => {
    addNodes(newNodes);
  }, [newNodes]);

  useEffect(() => {
    addEdges(newEdges);
    fitView({ padding: 0.25 });
  }, [newEdges]);

  useEffect(() => {
    triggerFitView && fitView({ padding: 0.25 });
  }, [triggerFitView]);

  // Any functions passed to react flow should be wrapped in useCallback
  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  return (
    <ReactFlow
      defaultEdgeOptions={{ style: edgeStyling, type: 'smoothstep' }}
      connectionLineStyle={edgeStyling}
      nodes={nodes}
      edges={edges}
      nodesDraggable={false}
      nodesConnectable={false}
      // Prevents backspace deleting
      deleteKeyCode={null}
      connectionMode={ConnectionMode.Loose}
      nodeTypes={nodeTypes as any}
      fitView
      maxZoom={20}
      fitViewOptions={fitViewOptions}
      proOptions={{ hideAttribution: true }}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
    >
      <Background></Background>
      {showControls && <Controls></Controls>}
    </ReactFlow>
  );
}

export default FlowViewComponent;
