import React, { useState, useEffect, useContext } from 'react';
import 'react-toggle/style.css';

import styles from './style.module.css';
import InternalViewComponent from './internal-view';
import { DesignData, SelectedItemsBreakdown } from '../../types';
import {
  formatNumberPrice,
  getDiscountQuotient,
} from '../../common/helpers/number-helpers';
import {
  filterAdditionalItems,
  filterUnitItems,
  getSelectedItemsWeeklyRentalPrice,
} from '../../common/helpers/item-filters';
import { ADDITIONAL_ITEMS_CONFIGS } from '../../common/config/partTypes';
import { DesignDataContext } from '../../common/context/designData';
import CustomerViewComponent from './customer-view';

export const defaultOpenStatus = {
  customerOpen: false,
  companyOpen: false,
  rentalPeriodOpen: false,
  transportOpen: false,
  labourOpen: false,
  extrasOpen: false,
  otherWeeklyItemRentalsOpen: false,
  finalPriceOpen: false,
  mainUnitsOpen: false,
  hoseConnectorsAdaptersOpen: false,
  valvesManifoldsReducersOpen: false,
  electricsOpen: false,
  otherAccessoriesOpen: false,
  notesOpen: false,
};

export type OpenStatus = typeof defaultOpenStatus;
export type OpenStatusKey = keyof OpenStatus;

interface SummaryComponentProps {
  design?: DesignData;
  toOpen?: OpenStatusKey[];
  triggerPrepareForPDF?: number;
  selectedViewMode?: 'internal' | 'customer';
  saveMode?: boolean;
}

const SummaryComponent = ({
  toOpen,
  saveMode,
  selectedViewMode = 'internal',
}: SummaryComponentProps) => {
  const [openStatus, setOpenStatus] = useState(defaultOpenStatus);
  const [splitSelectedItems, setSplitSelectedItems] =
    useState<SelectedItemsBreakdown>();
  const { designData } = useContext(DesignDataContext);

  useEffect(() => {
    if (toOpen && toOpen.length) {
      bulkUpdateOpenStatus(toOpen as (keyof OpenStatus)[]);
    }
  }, [toOpen]);

  const bulkUpdateOpenStatus = (toOpen: (keyof OpenStatus)[]) => {
    const updatedOpenStatus = Object.keys(defaultOpenStatus).reduce(
      (acc, key) => {
        acc[key as keyof OpenStatus] = toOpen.includes(key as keyof OpenStatus);
        return acc;
      },
      {} as OpenStatus
    );
    setOpenStatus(updatedOpenStatus);
  };

  const handleToggleOpen = (openStatusKey: OpenStatusKey) => () => {
    setOpenStatus((prev) => ({
      ...prev,
      [openStatusKey]: !prev[openStatusKey],
    }));
  };

  const createAdditionalItemsBreakdown = (
    design: DesignData,
    equipmentDiscountCoefficient: number
  ) => {
    return Object.entries(ADDITIONAL_ITEMS_CONFIGS).reduce(
      (breakdown, keyValue) => {
        const [key, config] = keyValue;
        const filteredSelectedItems = filterAdditionalItems(
          design?.selected_items,
          config.unitSeries
        );
        const weeklyRentalPrice = getSelectedItemsWeeklyRentalPrice(
          filteredSelectedItems
        );
        const weeklyRentalPriceWithDiscount =
          weeklyRentalPrice * equipmentDiscountCoefficient;
        const totalPriceWithDiscount =
          weeklyRentalPriceWithDiscount *
          (Number(design?.logistics_data.number_of_weeks) || 0);
        breakdown[key as keyof SelectedItemsBreakdown] = {
          weeklyRentalPrice: formatNumberPrice(weeklyRentalPriceWithDiscount),
          totalPrice: formatNumberPrice(totalPriceWithDiscount),
          title: config.name,
          selectedItems: filteredSelectedItems,
        };
        return breakdown;
      },
      {} as SelectedItemsBreakdown
    );
  };

  const createUnitItemBreakdown = (
    design: DesignData,
    equipmentDiscountCoefficient: number
  ) => {
    const filteredSelectedItems = filterUnitItems(design?.selected_items);
    const weeklyRentalPrice = getSelectedItemsWeeklyRentalPrice(
      filteredSelectedItems
    );
    const weeklyRentalPriceWithDiscount =
      weeklyRentalPrice * equipmentDiscountCoefficient;
    const totalPrice =
      weeklyRentalPriceWithDiscount *
      (design?.logistics_data.number_of_weeks || 0);
    return {
      weeklyRentalPrice: formatNumberPrice(weeklyRentalPriceWithDiscount),
      totalPrice: formatNumberPrice(totalPrice),
      title: 'Main units',
      selectedItems: filteredSelectedItems,
    };
  };

  useEffect(() => {
    if (!designData) {
      return;
    }
    const equipmentDiscountCoefficient = getDiscountQuotient(
      designData.logistics_data.equipment_cost?.discount
    );
    const additionaItemsBreakdowns = createAdditionalItemsBreakdown(
      designData,
      equipmentDiscountCoefficient
    );
    const unitItemsBreakdown = createUnitItemBreakdown(
      designData,
      equipmentDiscountCoefficient
    );
    setSplitSelectedItems({
      ...additionaItemsBreakdowns,
      mainUnits: unitItemsBreakdown,
    });
  }, [designData]);

  return (
    <div className={styles['summary-main-container']}>
      {selectedViewMode === 'internal' ? (
        <InternalViewComponent
          handleToggleOpen={handleToggleOpen}
          openStatus={openStatus}
          splitSelectedItems={splitSelectedItems}
          saveMode={saveMode}
        ></InternalViewComponent>
      ) : (
        <CustomerViewComponent
          handleToggleOpen={handleToggleOpen}
          openStatus={openStatus}
          saveMode={saveMode}
        ></CustomerViewComponent>
      )}
    </div>
  );
};

export default SummaryComponent;
