import React from 'react';
import { Handle, Position } from 'reactflow';
import Icon from '../../ui/icon';
import { createSharedHandleStyle } from '../../common/config/globalHandleStyle';
import { ItemIconNodeData } from '../../types';

const NodePlateHeatExchanger = ({ data }: { data: ItemIconNodeData }) => {
  const sharedHandleStyle = createSharedHandleStyle(data, {
    height: 30,
    width: 15,
  });
  const rightBottomStyle = {
    ...sharedHandleStyle,
    top: 55,
    left: 32,
  };
  const rightTopStyle = {
    ...sharedHandleStyle,
    top: 20,
    left: 32,
  };
  const leftBottomStyle = {
    ...sharedHandleStyle,
    top: 55,
    left: 4,
  };
  const leftTopStyle = {
    ...sharedHandleStyle,
    top: 20,
    left: 4,
  };
  return (
    <div>
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={rightBottomStyle}
        isConnectable={data.displayHandles}
      />

      <Handle
        type="source"
        position={Position.Right}
        id="c"
        style={rightTopStyle}
        isConnectable={data.displayHandles}
      />
      <Icon
        name="plateHeatExchanger"
        height="76px"
        width="76px"
        showError={data.showError}
        label={data.item?.name}
      ></Icon>
      <Handle
        type="source"
        position={Position.Left}
        id="a"
        style={leftBottomStyle}
        isConnectable={data.displayHandles}
      />
      <Handle
        type="source"
        position={Position.Left}
        id="d"
        style={leftTopStyle}
        isConnectable={data.displayHandles}
      />
    </div>
  );
};

export default NodePlateHeatExchanger;
