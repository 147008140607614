// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__b2gr6 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    #ffffff00 0%,
    rgba(209, 223, 225, 1) 100%
  );
}

.footer__b2gr6 > div {
  position: absolute;
  right: 25px;
  bottom: 10px;

  --icon-height: auto;
  --icon-width: 80px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/footer/style.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oBAAiB;KAAjB,iBAAiB;EACjB,8BAA8B;EAC9B;;;;GAIC;AACH;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;;EAEZ,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".footer {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 60px;\n  object-fit: cover;\n  background: rgb(255, 255, 255);\n  background: linear-gradient(\n    180deg,\n    #ffffff00 0%,\n    rgba(209, 223, 225, 1) 100%\n  );\n}\n\n.footer > div {\n  position: absolute;\n  right: 25px;\n  bottom: 10px;\n\n  --icon-height: auto;\n  --icon-width: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer__b2gr6`
};
export default ___CSS_LOADER_EXPORT___;
