// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__IDf0e {
  width: 100%;
  display: grid;
  grid-template-rows: min-content 1fr 80px;
  height: 100%;
  box-sizing: border-box;
}

.hide__BHQKG {
  display: none;
}

.body__BP9S6 {
  box-sizing: border-box;
  padding: 0 5px 0 20px;
  margin-right: 5px;
  overflow-y: auto;
}

.footer__KTP7T {
  position: relative;
  display: grid;
  padding: 0 42px;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  align-self: center;
}

html[is-ipad='true'] .footer__KTP7T {
  margin-bottom: 15px;
}

.rightFooterButtons__XlVB3 {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}

.header__zVmnR {
  margin-top: 25px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
}

.header__zVmnR.summary__gOeVE {
  margin-top: 30px;
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/save-modal/style.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,wCAAwC;EACxC,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".container {\n  width: 100%;\n  display: grid;\n  grid-template-rows: min-content 1fr 80px;\n  height: 100%;\n  box-sizing: border-box;\n}\n\n.hide {\n  display: none;\n}\n\n.body {\n  box-sizing: border-box;\n  padding: 0 5px 0 20px;\n  margin-right: 5px;\n  overflow-y: auto;\n}\n\n.footer {\n  position: relative;\n  display: grid;\n  padding: 0 42px;\n  grid-template-columns: 1fr 1fr;\n  box-sizing: border-box;\n  align-self: center;\n}\n\nhtml[is-ipad='true'] .footer {\n  margin-bottom: 15px;\n}\n\n.rightFooterButtons {\n  display: flex;\n  justify-content: flex-end;\n  gap: 24px;\n}\n\n.header {\n  margin-top: 25px;\n  margin-bottom: 60px;\n  display: flex;\n  justify-content: center;\n}\n\n.header.summary {\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__IDf0e`,
	"hide": `hide__BHQKG`,
	"body": `body__BP9S6`,
	"footer": `footer__KTP7T`,
	"rightFooterButtons": `rightFooterButtons__XlVB3`,
	"header": `header__zVmnR`,
	"summary": `summary__gOeVE`
};
export default ___CSS_LOADER_EXPORT___;
