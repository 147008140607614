// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stageMainContainer__nRdIO {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tailContainer__BH2iD {
  background-color: #c2c2c2;
  width: 60px;
  height: 2px;
}

.tailProgress__YaguU {
  background-color: var(--progress-bar-blue);
  height: 100%;
  width: 0;
  transition: width 0.1s ease-out;
}

.tailProgress__YaguU.full__nIk9P {
  width: 100%;
}

.mainContainer__IuXOJ {
  display: flex;
  flex-direction: row;
}

.stageContainer__CgJNY {
  position: relative;
  display: flex;
}

.stageBallContainer__H6Dk4 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--progress-bar-blue);
  position: relative;
}

.stageBallContainer__H6Dk4.incomplete__RjN7s {
  background-color: var(--background-grey);
  border: 2px solid var(--border-grey);
}

.stageBallCenter__V9j9C {
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--progress-bar-blue);
  border-radius: 50%;
  transition: all 0.1s ease-out;
  transition-property: width, height;
}

.stageBallCenter__V9j9C.full__nIk9P {
  transition-delay: 100ms;
  width: 41px;
  height: 41px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/progress-bar/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,0CAA0C;EAC1C,YAAY;EACZ,QAAQ;EACR,+BAA+B;AACjC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,0CAA0C;EAC1C,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;EACxC,oCAAoC;AACtC;;AAEA;EACE,QAAQ;EACR,SAAS;EACT,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,gCAAgC;EAChC,0CAA0C;EAC1C,kBAAkB;EAClB,6BAA6B;EAC7B,kCAAkC;AACpC;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd","sourcesContent":[".stageMainContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.tailContainer {\n  background-color: #c2c2c2;\n  width: 60px;\n  height: 2px;\n}\n\n.tailProgress {\n  background-color: var(--progress-bar-blue);\n  height: 100%;\n  width: 0;\n  transition: width 0.1s ease-out;\n}\n\n.tailProgress.full {\n  width: 100%;\n}\n\n.mainContainer {\n  display: flex;\n  flex-direction: row;\n}\n\n.stageContainer {\n  position: relative;\n  display: flex;\n}\n\n.stageBallContainer {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  border: 2px solid var(--progress-bar-blue);\n  position: relative;\n}\n\n.stageBallContainer.incomplete {\n  background-color: var(--background-grey);\n  border: 2px solid var(--border-grey);\n}\n\n.stageBallCenter {\n  width: 0;\n  height: 0;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  background-color: var(--progress-bar-blue);\n  border-radius: 50%;\n  transition: all 0.1s ease-out;\n  transition-property: width, height;\n}\n\n.stageBallCenter.full {\n  transition-delay: 100ms;\n  width: 41px;\n  height: 41px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stageMainContainer": `stageMainContainer__nRdIO`,
	"tailContainer": `tailContainer__BH2iD`,
	"tailProgress": `tailProgress__YaguU`,
	"full": `full__nIk9P`,
	"mainContainer": `mainContainer__IuXOJ`,
	"stageContainer": `stageContainer__CgJNY`,
	"stageBallContainer": `stageBallContainer__H6Dk4`,
	"incomplete": `incomplete__RjN7s`,
	"stageBallCenter": `stageBallCenter__V9j9C`
};
export default ___CSS_LOADER_EXPORT___;
