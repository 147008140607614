import { format, parseISO } from 'date-fns';
//converts from YYYY-MM-DD to DD/MM/YYYY

export const convertDateFormat = (
  date?: string,
  dateFormat = 'dd/MM/yyyy',
  defaultString = '-'
): string => {
  if (!date) {
    return defaultString;
  }
  const dateObj = parseISO(date);
  return format(dateObj, dateFormat);
};
