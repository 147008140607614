import React from 'react';
import styles from './style.module.css';
import Button from '../../ui/button';
import Icon from '../../ui/icon';
import Text from '../../ui/text';
import classNames from 'classnames';

type InfoTextObject = {
  lineOne: string;
  lineTwo: string;
};

const InfoText = ({ infoText }: { infoText?: string | InfoTextObject }) => {
  if (typeof infoText === 'string' || !infoText) {
    return <Text>{infoText}</Text>;
  } else {
    const { lineOne, lineTwo } = infoText;
    return (
      <div className={styles.infoTextContainer}>
        <Text alignSelf="flex-end">{lineOne}</Text>
        <Text alignSelf="flex-end">{lineTwo}</Text>
      </div>
    );
  }
};

interface SummarySectionCardComponentProps {
  icon?: React.ReactElement;
  title?: string;
  infoText?: string | InfoTextObject;
  header?: React.ReactElement;
  open?: boolean;
  toggleOpen?: () => void;
  children?: React.ReactElement;
  canOpen?: boolean;
}

const SummarySectionCardComponent = ({
  open,
  title,
  infoText,
  toggleOpen,
  children,
  canOpen = true,
}: SummarySectionCardComponentProps): React.ReactElement => {
  const mainContainerClass = classNames({
    [styles.summaryMainContainer]: true,
    [styles.open]: open,
  });
  const headerClass = classNames({
    [styles.summaryHeader]: true,
    [styles.open]: open,
    [styles.double]: typeof infoText === 'object',
  });
  const buttonClass = classNames({
    [styles.summaryButton]: true,
    [styles.open]: open,
  });
  return (
    <div className={mainContainerClass}>
      <div className={styles.summaryCardMainContainer}>
        <div className={headerClass}>
          <Text>{title}</Text>
          <InfoText infoText={infoText}></InfoText>

          {canOpen && toggleOpen && (
            <div className={buttonClass}>
              <Button variant="close" onClick={toggleOpen}>
                {' '}
                <Icon
                  name="downArrowIconV2"
                  fill="transparent"
                  width="20px"
                  height="auto"
                ></Icon>
              </Button>
            </div>
          )}
        </div>
      </div>
      {open && <div className={styles.summaryBody}>{children}</div>}
    </div>
  );
};

export default SummarySectionCardComponent;
