import React from 'react';
import styles from './style.module.css';
import Text from '../../ui/text';
import Icon from '../../ui/icon';
import { ClientData, SummarySectionProps } from '../../types';
import SummarySectionCardComponent from '../summary-section-card-component';

interface SummaryCompanyComponentProps extends SummarySectionProps {
  clientData?: Partial<ClientData>;
}

const SummaryCompanyComponent = ({
  clientData,
  toggleOpen,
  open,
}: SummaryCompanyComponentProps) => {
  return (
    <SummarySectionCardComponent
      toggleOpen={toggleOpen}
      open={open}
      title="Company details"
    >
      <div className={styles.mainContainer}>
        <div className={styles.companyAndSiteAddressContainer}>
          <div className={styles.titleCustomer}>
            <Text fontWeight="600" size="m">
              Company
            </Text>
          </div>

          <div className={styles.detailsMainContainer}>
            <div className={styles.inputContainer}>
              <Text>{clientData?.company?.name || '-'}</Text>
            </div>
            <div className={styles.inputContainer}>
              <Text>{clientData?.company?.street_address || '-'}</Text>
            </div>
            <div className={styles.inputContainer}>
              <Text>{clientData?.company?.city || '-'}</Text>
            </div>
            <div className={styles.inputContainer}>
              <Text>{clientData?.company?.country || '-'}</Text>
            </div>
            <div className={styles.inputContainer}>
              <Text>{clientData?.company?.postcode || '-'}</Text>
            </div>
          </div>
        </div>
        <div className={styles.companyAndSiteAddressContainer}>
          <div className={styles.titleSiteContact}>
            <Text fontWeight="600" size="m">
              Site address
            </Text>
          </div>

          <div className={styles.detailsMainContainer}>
            <div className={styles.inputContainer}>
              <Text>
                {clientData?.company_site?.name ||
                  clientData?.company?.name ||
                  '-'}
              </Text>
            </div>
            <div className={styles.inputContainer}>
              <Text>
                {clientData?.company_site?.street_address ||
                  clientData?.company?.street_address ||
                  '-'}
              </Text>
            </div>
            <div className={styles.inputContainer}>
              <Text>
                {clientData?.company_site?.city ||
                  clientData?.company?.city ||
                  '-'}
              </Text>
            </div>
            <div className={styles.inputContainer}>
              <Text>
                {clientData?.company_site?.country ||
                  clientData?.company?.country ||
                  '-'}
              </Text>
            </div>
            <div className={styles.inputContainer}>
              <Text>
                {clientData?.company_site?.postcode ||
                  clientData?.company?.postcode ||
                  '-'}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </SummarySectionCardComponent>
  );
};

export default SummaryCompanyComponent;
