import Text from '../../ui/text';
import React, { useContext, useEffect } from 'react';
import styles from './style.module.css';
import { DesignDataContext } from '../../common/context/designData';
import { LogisticsFieldName } from '../../types';
import { UseAuth } from '../auth-component/auth-provider';
import {
  cleanNumberInput,
  formatPrice,
  pricingToDiscountedPrice,
} from '../../common/helpers/number-helpers';
import Input from '../../ui/input';
import ValueCard from '../../ui/value-card';
import Icon from '../../ui/icon';
import Tooltip from '../../ui/tooltip';

export const TotalPriceForm = () => {
  const { updateLogisticsDataObject, designData } =
    useContext(DesignDataContext);
  const { getUser } = UseAuth();
  const [maxDiscount, setMaxDiscount] = React.useState(0);

  const getAndSetDiscount = async () => {
    const user = await getUser();
    if (user) {
      setMaxDiscount(parseFloat(user.user_discount));
    }
  };

  useEffect(() => {
    getAndSetDiscount();
  }, []);

  const handleDiscountInput = (event: React.FormEvent<HTMLInputElement>) => {
    // We handle negatives by 1) not letting them proceed through save modal with '-'
    // value set and 2) Converting non numbers to 0 in getDiscountQuotient to prevent
    // NaN values for prices
    const inputDiscount = cleanNumberInput(event.currentTarget.value);
    const comparableDiscount = Number(inputDiscount);
    const decimalPart = (inputDiscount.split('.')[1] || '').length;
    const field = event.currentTarget.name as LogisticsFieldName;
    if (event.currentTarget.value === '-') {
      updateLogisticsDataObject(field, 'discount', '-');
    }
    if (
      comparableDiscount <= maxDiscount &&
      comparableDiscount <= 100 &&
      comparableDiscount >= -1000 &&
      decimalPart <= 2
    ) {
      updateLogisticsDataObject(field, 'discount', inputDiscount);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.pricingContainer}>
        <Text fontWeight="600">Breakdown</Text>
        <div className={styles.discountTitle}>
          <Text fontWeight="600">Discount</Text>
          <Tooltip
            content={
              <Text
                color="white"
                size="xs"
              >{`You are permitted to set up to a ${maxDiscount}% discount. To increase this limit, speak to an admin.`}</Text>
            }
          >
            <Icon name="infoIconV2" width="12px" height="auto"></Icon>
          </Tooltip>
        </div>
        <Text fontWeight="600">Total</Text>
        <div className={styles.divider}></div>
        <Text>Equipment</Text>
        <Input
          style={{ height: 25, width: 60 }}
          onChange={handleDiscountInput}
          name="equipment_cost"
          value={designData.logistics_data.equipment_cost?.discount}
          icon={<Text>%</Text>}
          iconContainerStyle={{ right: 124, bottom: 4, left: 'unset' }}
        ></Input>
        <Text>
          £{pricingToDiscountedPrice(designData.logistics_data.equipment_cost)}
        </Text>
        <div className={styles.divider}></div>

        <Text style={{ gridColumn: '1/3' }}>Delivery</Text>
        <Text>
          £
          {pricingToDiscountedPrice(
            designData.logistics_data.transportation_cost
          )}
        </Text>
        <div className={styles.divider}></div>

        <Text style={{ gridColumn: '1/3' }}>Labour</Text>
        <Text>
          £{pricingToDiscountedPrice(designData.logistics_data.labour_cost)}
        </Text>
        <div className={styles.divider}></div>

        <Text style={{ gridColumn: '1/3' }}>Other weekly rentals</Text>
        <Text>
          £
          {pricingToDiscountedPrice(
            designData.logistics_data.other_weekly_rentals_cost
          )}
        </Text>
        <div className={styles.divider}></div>

        <Text style={{ gridColumn: '1/3' }}>One off items</Text>
        <Text>
          £
          {pricingToDiscountedPrice(
            designData.logistics_data.one_off_items_cost
          )}
        </Text>
      </div>
      <ValueCard
        price={formatPrice(designData.logistics_data.total_price)}
        label="Total price:"
        innerStyle={{ width: '100%' }}
      ></ValueCard>
    </div>
  );
};

export default TotalPriceForm;
