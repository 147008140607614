import React, { Fragment } from 'react';
import styles from './style.module.css';

import Text from '../../ui/text';
import SummarySectionCardComponent from '../summary-section-card-component';
import {
  LogisticsData,
  OneOffItem,
  OtherWeeklyRental,
  SummarySectionProps,
} from '../../types';
import {
  formatNumberPrice,
  getDiscountedDisplayPrice,
  pricingToDiscountedPrice,
  pricingToDiscountedPriceNumber,
} from '../../common/helpers/number-helpers';
import { isEmpty } from 'lodash';
import { DerivedData } from '../../common/context/designData';
import ValueCard from '../../ui/value-card';

interface SummaryOneOffItemsProps {
  oneOffItemsArray: OneOffItem[];
  noOneOffItems?: boolean;
  logisticsData?: Partial<LogisticsData>;
}

const SummaryOneOffItems = ({
  oneOffItemsArray,
  noOneOffItems,
  logisticsData,
}: SummaryOneOffItemsProps) => {
  const discount = logisticsData?.one_off_items_cost?.discount;
  return (
    <>
      <Text size="ml" fontWeight="600" style={{ marginTop: 50 }}>
        One Off Items
      </Text>
      <div className={styles.oneOffItemsContainer}>
        <Text fontWeight="600">Item</Text>
        <Text fontWeight="600">Price</Text>
        <div className={styles.divider}></div>
        {noOneOffItems ? (
          <Text
            size="m"
            style={{ fontStyle: 'italic', padding: '12px', gridColumn: '1/-1' }}
          >
            No one off items for this order
          </Text>
        ) : (
          oneOffItemsArray.map((oneOffItem, index) => {
            const price = getDiscountedDisplayPrice(oneOffItem.price, discount);
            return (
              <Fragment key={index}>
                {index === 0 && <div className={styles.spacer}></div>}
                <Text fontWeight="400">{oneOffItem.name}</Text>
                <Text fontWeight="400">£{price}</Text>
                <div className={styles.spacer}></div>
              </Fragment>
            );
          })
        )}
        <div className={styles.divider} style={{ marginBottom: 5 }}></div>
        <Text fontWeight="400">Total</Text>
        <Text fontWeight="400">
          £{pricingToDiscountedPrice(logisticsData?.one_off_items_cost)}
        </Text>
      </div>
    </>
  );
};

interface OtherWeeklyRentalProps {
  otherWeeklyRentalsArray: OtherWeeklyRental[];
  noOtherWeeklyRentals?: boolean;
  logisticsData?: Partial<LogisticsData>;
}

const SummaryOtherWeeklyRentals = ({
  otherWeeklyRentalsArray,
  noOtherWeeklyRentals,
  logisticsData,
}: OtherWeeklyRentalProps) => {
  const discount = logisticsData?.other_weekly_rentals_cost?.discount;
  const numberOfWeeks = logisticsData?.number_of_weeks || 0;
  const totalWeeklyPrice = numberOfWeeks
    ? pricingToDiscountedPriceNumber(logisticsData?.other_weekly_rentals_cost) /
      numberOfWeeks
    : 0;
  return (
    <>
      <Text size="ml" fontWeight="600">
        Other weekly rentals
      </Text>
      <div className={styles.otherWeeklyRentalsContainer}>
        <Text fontWeight="600">Item</Text>
        <Text fontWeight="600">Weekly price</Text>
        <Text fontWeight="600">Price</Text>
        <div className={styles.divider}></div>
        {noOtherWeeklyRentals ? (
          <Text
            size="m"
            style={{ fontStyle: 'italic', padding: '12px', gridColumn: '1/-1' }}
          >
            No other weekly rentals for this order
          </Text>
        ) : (
          otherWeeklyRentalsArray.map((otherWeeklyRental, index) => {
            const weeklyPrice = getDiscountedDisplayPrice(
              otherWeeklyRental.weekly_rental_price,
              discount
            );
            const totalPriceNumber =
              numberOfWeeks *
              Number(otherWeeklyRental.weekly_rental_price || 0);
            const totalPrice = getDiscountedDisplayPrice(
              totalPriceNumber,
              discount
            );
            return (
              <Fragment key={index}>
                {index === 0 && <div className={styles.spacer}></div>}
                <Text fontWeight="400">{otherWeeklyRental.name}</Text>
                <Text fontWeight="400">£{weeklyPrice}</Text>
                <Text fontWeight="400">£{totalPrice}</Text>
                <div className={styles.spacer}></div>
              </Fragment>
            );
          })
        )}
        <div className={styles.divider} style={{ marginBottom: 5 }}></div>
        <Text fontWeight="400">Total</Text>
        <Text fontWeight="400">£{formatNumberPrice(totalWeeklyPrice)}</Text>
        <Text fontWeight="400">
          £{pricingToDiscountedPrice(logisticsData?.other_weekly_rentals_cost)}
        </Text>
      </div>
    </>
  );
};

interface SummaryOneOffItemsComponentProps extends SummarySectionProps {
  logisticsData?: Partial<LogisticsData>;
  canOpen?: boolean;
  derivedData: DerivedData;
}

const SummaryExtrasComponent = ({
  logisticsData,
  toggleOpen,
  open,
  derivedData,
  canOpen = true,
}: SummaryOneOffItemsComponentProps) => {
  const oneOffItemsArray = Object.values(logisticsData?.one_off_items || {});
  const noItems =
    oneOffItemsArray.length === 0 ||
    (oneOffItemsArray.length === 1 && isEmpty(oneOffItemsArray[0]));
  const otherWeeklyRentalsArray = Object.values(
    logisticsData?.other_weekly_rentals || {}
  );
  const noOtherWeeklyRentals =
    otherWeeklyRentalsArray.length === 0 ||
    (otherWeeklyRentalsArray.length === 1 &&
      isEmpty(otherWeeklyRentalsArray[0]));
  return (
    <SummarySectionCardComponent
      toggleOpen={toggleOpen}
      open={open}
      canOpen={canOpen}
      title="Extras"
      infoText={`£${derivedData.extrasPrice}`}
    >
      <>
        {noItems && noOtherWeeklyRentals ? (
          <Text>No extras for this order.</Text>
        ) : (
          <>
            <SummaryOtherWeeklyRentals
              logisticsData={logisticsData}
              noOtherWeeklyRentals={noOtherWeeklyRentals}
              otherWeeklyRentalsArray={otherWeeklyRentalsArray}
            ></SummaryOtherWeeklyRentals>
            <SummaryOneOffItems
              oneOffItemsArray={oneOffItemsArray}
              noOneOffItems={noItems}
              logisticsData={logisticsData}
            ></SummaryOneOffItems>
            <ValueCard
              price={derivedData.extrasPrice}
              label="Total extras price:"
              innerStyle={{ width: '100%' }}
            ></ValueCard>
          </>
        )}
      </>
    </SummarySectionCardComponent>
  );
};

export default SummaryExtrasComponent;
