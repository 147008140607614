// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdownContainer__HcisK {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formRow__CRN6j {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 16px 0 2px 0;
  gap: 5px;
}

.inputContainer__cVYNo {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
}

.inputBottomRow__YYi_A {
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-rows: 1fr;
  margin-top: 8px;
}

.exclusionCheckbox___uirC,
.checkbox__mcPXH {
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.exclusionCheckbox___uirC {
  margin-top: 4px;
}

.contactContainer__aU_YO {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
}

.exclusions__ZeEXZ {
  margin-top: 18px;
}

.customExclusion__FetZa {
  margin-top: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.customerDataNotice__NaWRs {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.divider__gJwlD {
  width: 100%;
  margin-left: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: auto;
  height: 1px;
  background-color: #a4a4a4;
}
`, "",{"version":3,"sources":["webpack://./src/components/generate-v2-pdf-modal/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,OAAO;AACT;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB;;AAEA;;EAEE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,yBAAyB;AAC3B","sourcesContent":[".dropdownContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.formRow {\n  display: grid;\n  grid-template-columns: 50% 50%;\n  padding: 16px 0 2px 0;\n  gap: 5px;\n}\n\n.inputContainer {\n  display: grid;\n  grid-template-columns: 49% 49%;\n  gap: 2%;\n}\n\n.inputBottomRow {\n  grid-column-start: 1;\n  grid-column-end: 3;\n  display: grid;\n  grid-template-rows: 1fr;\n  margin-top: 8px;\n}\n\n.exclusionCheckbox,\n.checkbox {\n  margin-top: 8px;\n  display: flex;\n  align-items: flex-start;\n  gap: 4px;\n}\n\n.exclusionCheckbox {\n  margin-top: 4px;\n}\n\n.contactContainer {\n  margin-top: 4px;\n  display: flex;\n  flex-direction: column;\n}\n\n.exclusions {\n  margin-top: 18px;\n}\n\n.customExclusion {\n  margin-top: 12px;\n  display: flex;\n  gap: 12px;\n  align-items: center;\n  justify-content: center;\n}\n\n.customerDataNotice {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.divider {\n  width: 100%;\n  margin-left: auto;\n  margin-top: 24px;\n  margin-bottom: 24px;\n  margin-right: auto;\n  height: 1px;\n  background-color: #a4a4a4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownContainer": `dropdownContainer__HcisK`,
	"formRow": `formRow__CRN6j`,
	"inputContainer": `inputContainer__cVYNo`,
	"inputBottomRow": `inputBottomRow__YYi_A`,
	"exclusionCheckbox": `exclusionCheckbox___uirC`,
	"checkbox": `checkbox__mcPXH`,
	"contactContainer": `contactContainer__aU_YO`,
	"exclusions": `exclusions__ZeEXZ`,
	"customExclusion": `customExclusion__FetZa`,
	"customerDataNotice": `customerDataNotice__NaWRs`,
	"divider": `divider__gJwlD`
};
export default ___CSS_LOADER_EXPORT___;
