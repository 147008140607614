import React from 'react';
import styles from './style.module.css';

import Text from '../../ui/text';
import Icon from '../../ui/icon';
import { LogisticsData, SummarySectionProps } from '../../types';
import SummarySectionCardComponent from '../summary-section-card-component';
import { convertDateFormat } from '../../common/helpers/date-helpers';

interface SummaryRentalDatesComponentProps extends SummarySectionProps {
  logisticsData?: Partial<LogisticsData>;
  saveMode?: boolean;
}

const SummaryRentalDatesComponent = ({
  logisticsData,
  saveMode,
  toggleOpen,
  open,
}: SummaryRentalDatesComponentProps) => {
  return (
    <SummarySectionCardComponent
      toggleOpen={toggleOpen}
      open={open}
      title="Rental period"
      infoText={`${convertDateFormat(
        logisticsData?.start_date
      )} - ${convertDateFormat(logisticsData?.end_date)}`}
      header={
        <div className={styles.summaryMainContainer}>
          <div className={styles.summaryTitleMainContainer}>
            <div className={styles.containerRentalDates}>
              <div className={styles.summaryTitleContainer}>
                <Text fontWeight="500" size="lg">
                  Rental period
                </Text>
              </div>
            </div>

            <div className={styles.summaryDatesContainer}>
              <div className={styles.boxLeft}>
                <Text size="md" fontWeight="500">
                  Weeks: {logisticsData?.number_of_weeks}
                </Text>
              </div>

              <div className={styles.boxRight}>
                <Text size="md" fontWeight="500">
                  Start date: {convertDateFormat(logisticsData?.start_date)}
                </Text>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div className={styles.mainAllContainer}>
        <div className={styles.mainContainer}>
          <div className={styles.titleAndInputContainer}>
            <div className={styles.titleContainer}>
              <Text fontWeight="600">Start rental date:</Text>
            </div>
            <div className={styles.titleContainer}>
              <Text fontWeight="600">End rental date:</Text>
            </div>
            <div className={styles.titleContainer}>
              <Text fontWeight="600">Number of weeks:</Text>
            </div>
          </div>
          <div className={styles.inputMainContainer}>
            <Text>{convertDateFormat(logisticsData?.start_date)}</Text>
            <Text>{convertDateFormat(logisticsData?.end_date)}</Text>
            <Text>{logisticsData?.number_of_weeks || '-'}</Text>
          </div>
        </div>
      </div>
    </SummarySectionCardComponent>
  );
};

export default SummaryRentalDatesComponent;
