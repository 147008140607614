import React from 'react';
import { Handle, Position } from 'reactflow';
import Icon from '../../ui/icon';
import { createSharedHandleStyle } from '../../common/config/globalHandleStyle';
import { ItemIconNodeData } from '../../types';

const NodeLowTempCooler = ({ data }: { data: ItemIconNodeData }) => {
  const sharedHandleStyle = createSharedHandleStyle(data, {});
  const rightStyle = { ...sharedHandleStyle, top: 24, right: 2 };
  const leftStyle = { ...sharedHandleStyle, top: 24, left: 2 };
  return (
    <div>
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={rightStyle}
      />
      <Icon
        name="lowTempCooler"
        height="86px"
        width="86px"
        showError={data.showError}
        label={data.item?.name}
      ></Icon>
      <Handle type="source" position={Position.Left} id="a" style={leftStyle} />
    </div>
  );
};

export default NodeLowTempCooler;
