import React, { CSSProperties } from 'react';
import styles from './style.module.css';
import Text from '../text';
import Icon from '../icon';
import Button from '../button';
import { noop } from 'lodash';

const handleClick = () => {};

interface ModalProps {
  children: React.ReactNode;
  onClose?: () => void;
  buttons: React.ReactNode;
  title?: React.ReactNode;
  style?: CSSProperties;
  show?: boolean;
  errorMessage?: string;
  width?: string;
}

const Modal = ({
  children,
  buttons,
  show,
  style,
  onClose = noop,
  errorMessage,
  title,
  width = '500px',
}: ModalProps): React.ReactElement => {
  if (!show) {
    return <div></div>;
  }
  const fullStyle = {
    ...style,
    '--modal-width': width,
  } as React.CSSProperties;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent} style={fullStyle}>
        <Button
          variant="empty"
          onClick={onClose}
          style={{ position: 'absolute', top: '8px', right: '10px', zIndex: 6 }}
        >
          <Icon
            name="closedIconV2"
            fill="white"
            height="12px"
            width="12px"
          ></Icon>
        </Button>
        {!!title && <div className={styles.titleContainer}>{title}</div>}
        <div className={styles.contentContainer}>{children}</div>
        {buttons}
        {errorMessage && (
          <div className={styles.errorContainer}>
            <Icon
              name="warningTriangle"
              width="14px"
              height="14px"
              fill="#f27157"
            ></Icon>
            <Text size="s" color="error-red">
              {errorMessage}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
