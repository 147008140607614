// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formRowThree__be8ZP,
.formRowTwo__N_iMB,
.formRow__vLiSf {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 15px 0 3px 0;
  gap: 5px;
}

.inputContainerThree__DDmFk,
.inputContainerTwo__xGVem {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
}

.inputContainer__HfqHj {
  display: grid;
  grid-template-columns: 100%;
}

.option__ltO15 {
  padding: 6px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownIconContainer__XNUHC {
  width: 25px;
  display: flex;
  justify-content: center;
  padding-right: 8px;
  align-items: center;
  box-sizing: border-box;
}

.proposal__VqBNT {
  background-color: var(--status-background-proposal);
}

.selected__so1_u.proposal__VqBNT {
  border: 2px solid #fe951d75;
}

.quoted__ox2TT {
  background-color: var(--status-background-quoted);
}

.selected__so1_u.quoted__ox2TT {
  border: 2px solid #4695f075;
}

.won__yHJ0F {
  background-color: var(--status-background-won);
}

.selected__so1_u.won__yHJ0F {
  border: 2px solid #06b65175;
}

.lost__EPRe0 {
  background-color: var(--status-background-lost);
}

.selected__so1_u.lost__EPRe0 {
  border: 2px solid #f5777775;
}
`, "",{"version":3,"sources":["webpack://./src/components/order-status-dropdown/style.module.css"],"names":[],"mappings":"AAAA;;;EAGE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,QAAQ;AACV;;AAEA;;EAEE,aAAa;EACb,8BAA8B;EAC9B,OAAO;AACT;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".formRowThree,\n.formRowTwo,\n.formRow {\n  display: grid;\n  grid-template-columns: 50% 50%;\n  padding: 15px 0 3px 0;\n  gap: 5px;\n}\n\n.inputContainerThree,\n.inputContainerTwo {\n  display: grid;\n  grid-template-columns: 49% 49%;\n  gap: 2%;\n}\n\n.inputContainer {\n  display: grid;\n  grid-template-columns: 100%;\n}\n\n.option {\n  padding: 6px;\n  border-radius: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.dropdownIconContainer {\n  width: 25px;\n  display: flex;\n  justify-content: center;\n  padding-right: 8px;\n  align-items: center;\n  box-sizing: border-box;\n}\n\n.proposal {\n  background-color: var(--status-background-proposal);\n}\n\n.selected.proposal {\n  border: 2px solid #fe951d75;\n}\n\n.quoted {\n  background-color: var(--status-background-quoted);\n}\n\n.selected.quoted {\n  border: 2px solid #4695f075;\n}\n\n.won {\n  background-color: var(--status-background-won);\n}\n\n.selected.won {\n  border: 2px solid #06b65175;\n}\n\n.lost {\n  background-color: var(--status-background-lost);\n}\n\n.selected.lost {\n  border: 2px solid #f5777775;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRowThree": `formRowThree__be8ZP`,
	"formRowTwo": `formRowTwo__N_iMB`,
	"formRow": `formRow__vLiSf`,
	"inputContainerThree": `inputContainerThree__DDmFk`,
	"inputContainerTwo": `inputContainerTwo__xGVem`,
	"inputContainer": `inputContainer__HfqHj`,
	"option": `option__ltO15`,
	"dropdownIconContainer": `dropdownIconContainer__XNUHC`,
	"proposal": `proposal__VqBNT`,
	"selected": `selected__so1_u`,
	"quoted": `quoted__ox2TT`,
	"won": `won__yHJ0F`,
	"lost": `lost__EPRe0`
};
export default ___CSS_LOADER_EXPORT___;
