import React, { useEffect, useState, useContext, useRef } from 'react';
import styles from './style.module.css';
import ToolbarComponent from '../../components/toolbar-component';
import FlowComponent from '../../components/flow-component';
import SideToolbar from '../../components/side-toolbar';
import Modal from '../../ui/modal';
import Text from '../../ui/text';
import { Item, ItemIcon, ItemIconNode, ItemIconNodeData } from '../../types';
import {
  ReactFlowProvider,
  Edge,
  ReactFlowJsonObject,
  ReactFlowInstance,
} from 'reactflow';
import SaveModal from '../../components/save-modal';
import { DesignDataContext } from '../../common/context/designData';
import {
  CHANNEL_ICON,
  PART_TYPES,
  ROTATABLE_NODES,
  SPECIAL_NODES,
} from '../../common/config/partTypes';
import { UseAuth } from '../../components/auth-component/auth-provider';
import { randomIntFromInterval } from '../../common/helpers/number-helpers';
import classNames from 'classnames';
import Button from '../../ui/button';
import Icon from '../../ui/icon';
import {
  MENU_HEIGHT,
  MENU_WIDTH,
  SpecificationMenuProps,
} from '../../components/flow-component/specification-menu';
import { ItemIconProvider } from '../../common/context/itemIcons';
import { colorsConfig } from '../../common/design/colors';
import Status from '../../ui/status';

const getNodeId = (): string => Math.random().toString(16).slice(2);

const isSpecialNode = (itemIcon: ItemIcon): boolean => {
  return SPECIAL_NODES.includes(itemIcon.componentConfigName);
};

const isRotatableNode = (itemIcon: ItemIcon): boolean => {
  return ROTATABLE_NODES.includes(itemIcon.componentConfigName);
};

interface DesignAppProps {
  designMode?: 'edit' | 'create' | 'create-variant';
}

const initialNodes: ItemIconNode[] = [];

// Available modes for the design app, these will be checked on nearly all events
//  and change the behaviour
enum MODES {
  DEFAULT = 'default',
  CONNECT = 'connect',
  SPECIFICATION = 'specification',
  DELETE = 'delete',
  CHANNEL = 'channel',
}

export type Mode = `${MODES}`;

const DesignApp = ({
  designMode = 'create',
}: DesignAppProps): React.ReactElement => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();
  // FLOW AND DESIGN STATE
  const [newNodes, setNewNodes] = useState<ItemIconNode[]>(initialNodes);
  const [newEdges, setNewEdges] = useState<Edge[]>([]);
  const [elementsToDelete, setElementsToDelete] = useState<{
    nodes?: ItemIconNode[];
    edges?: Edge[];
  }>({});
  const [triggerValidateDesign, setTriggerValidateDesign] = useState(0);
  const [nodeToUpdate, setNodeToUpdate] = useState<
    { node: ItemIconNode; newData: Partial<ItemIconNodeData> } | undefined
  >();

  // MODE BASED STATE
  const [mode, setMode] = useState<Mode>(MODES.DEFAULT);
  const [nodesDraggable, setNodesDraggable] = useState(true);
  const [displayHandles, setDisplayHandles] = useState(false);

  // OTHER
  const [errorMessage, setErrorMessage] = useState<string>();
  // Design id returned from request after saving
  const [newDesignId, setNewDesignId] = useState<string>();
  const [saving, setSaving] = useState(false);
  const [specificationMenu, setSpecificationMenu] = useState<Omit<
    SpecificationMenuProps,
    'getNode'
  > | null>(null);
  const [showDesignDetailsPanel, setShowDesignDetailsPanel] = useState(false);
  const [triggerFitView, setTriggerFitView] = useState(0);
  const [overideToolSelection, SetOverideToolSelection] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const { handleRelocate, isIpad, getCsrf } = UseAuth();

  const {
    updateSelectedItems,
    updateDesignData,
    designId,
    triggerDesignUpdate,
    designData,
  } = useContext(DesignDataContext);

  const setStateBasedOnMode = () => {
    switch (mode) {
      case MODES.CHANNEL:
        setNodesDraggable(true);
        setDisplayHandles(false);
      case MODES.DEFAULT:
        setNodesDraggable(true);
        setDisplayHandles(false);
        break;
      case MODES.CONNECT:
        setNodesDraggable(true);
        setDisplayHandles(true);
        break;
      case MODES.SPECIFICATION:
        // this may need to be different on ipad and PC
        setNodesDraggable(!isIpad);
        setDisplayHandles(false);
        break;
      case MODES.DELETE:
        setNodesDraggable(false);
        setDisplayHandles(false);
        break;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTriggerFitView((prev) => prev + 1);
    }, 300);
  }, [showDesignDetailsPanel]);

  // To draw the design on edit request fulfilment
  const normaliseNodesFromJson = (nodes?: ItemIconNode[]): ItemIconNode[] => {
    // Obviously JSON can't hold things such as functions, so we add them in here.
    // Rotation isn't stored on BE unless it is explicitly set, this can cause a bug
    // if rotation is never set, we don't consider it rotatable
    // KEY INFO - don't rely on just checking rotation value. Check via the componentConfigName
    const normalisedNodes =
      nodes?.map((node) => {
        if (isRotatableNode(node.data.itemIcon)) {
          return {
            ...node,
            data: {
              ...node.data,
              onRotation: handleRotation,
            },
          };
        }
        return node;
      }) || [];
    return normalisedNodes;
  };

  useEffect(() => {
    const normalisedNodes = normaliseNodesFromJson(
      designData.design_json.nodes
    );
    setNewNodes(normalisedNodes);
    setNewEdges(designData?.design_json.edges || []);
    setTimeout(() => setTriggerFitView(triggerFitView + 1), 300);
  }, [triggerDesignUpdate]);

  useEffect(setStateBasedOnMode, [mode]);

  const changeMode = (mode: Mode) => {
    closeSpecificationMenu();
    setMode(mode);
  };

  const handleRotation = (node: ItemIconNode, rotation: number) => {
    setNodeToUpdate({
      node,
      newData: {
        rotation,
      },
    });
  };

  const getFlowPaneCenter = (): { x: number; y: number } => {
    if (!reactFlowWrapper.current || !reactFlowInstance) {
      return { x: 0, y: 0 };
    }
    const rect = (
      reactFlowWrapper.current as HTMLElement
    ).getBoundingClientRect();

    // Add a random adjust so if you stack many items they do not allign perfectly,
    // making it obvious that there are many
    const randomAdjust = randomIntFromInterval(-10, 0);
    const centerX = (rect.left + rect.right) / 2 + randomAdjust;
    const centerY = (rect.top + rect.bottom) / 2 + randomAdjust;
    return reactFlowInstance.screenToFlowPosition({
      x: centerX,
      y: centerY,
    });
  };

  const addSpecialNode = (
    itemIcon: ItemIcon,
    position?: { x: number; y: number }
  ) => {
    setNewNodes([
      {
        id: getNodeId(),
        className: 'nopan',
        type: itemIcon.partType.nodeName,
        data: {
          itemIcon,
          displayHandles,
          onRotation: handleRotation,
          item: {
            name: 'system',
            unit_series_name: 'system',
            weekly_rental_price: 0,
            id: getNodeId(),
            has_icon: true,
          },
        },
        position: position || getFlowPaneCenter(),
      },
    ]);
  };

  const addChannelNode = (event: React.MouseEvent) => {
    if (!reactFlowInstance) {
      return;
    }
    const zoom = reactFlowInstance.getZoom() || 1;
    const position = reactFlowInstance.screenToFlowPosition({
      x: event.clientX - 30 * zoom,
      y: event.clientY - 30 * zoom,
    });
    addSpecialNode(
      {
        ...CHANNEL_ICON,
        partType: PART_TYPES[CHANNEL_ICON.componentConfigName],
      },
      position
    );
  };

  const newItemIconSelected = (
    itemIcon: ItemIcon,
    position?: { x: number; y: number }
  ) => {
    if (isSpecialNode(itemIcon)) {
      addSpecialNode(itemIcon, position);
      return;
    }
    setNewNodes([
      {
        id: getNodeId(),
        className: 'nopan',
        type: itemIcon.partType.nodeName,
        data: {
          itemIcon,
          displayHandles,
          onRotation: handleRotation,
        },
        position: position || getFlowPaneCenter(),
      },
    ]);
  };

  const handleTriggerValidateDesign = () => {
    // We trigger save to validate the design, if it is valid we show the save modal
    // Need to change the tool here so that the design json is saved in its standard form.
    SetOverideToolSelection(overideToolSelection + 1);
    setTriggerValidateDesign(triggerValidateDesign + 1);
  };

  const handleBack = () => {
    setShowConfirmationModal(true);
  };

  const handleDesignDetailsPanel = (designJson: ReactFlowJsonObject) => {
    // Appears process of taking off display handles is slow, so if handles are displayed
    // when saving, the json will include display handles in the saved design. Clean this up by removing show handles
    // from all nodes before saving
    // Seems a better option than trying to chase variable loading times for updating the display handle prop
    // in all nodes
    const designJsonNoHandles = {
      ...designJson,
      nodes: designJson.nodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            displayHandles: false,
          },
        };
      }),
    };
    updateDesignData({ design_json: designJsonNoHandles });
    setShowDesignDetailsPanel(true);
  };

  const handleCloseDesignDetailsPanel = () => {
    setShowDesignDetailsPanel(false);
  };

  /*
    ITEM AND ORDER INTERACTIONS
  */

  const handleItemSpecification = (
    node: ItemIconNode,
    item: Item,
    oldItem?: Item
  ) => {
    // If node already has a selected item, remove it from selected items
    if (oldItem) {
      updateSelectedItems(oldItem, -1);
    }
    setNodeToUpdate({
      node,
      newData: {
        item,
        showError: false,
      },
    });
    updateSelectedItems(item, 1);
  };

  const handleDeleteNode = (node: ItemIconNode) => {
    setElementsToDelete({ nodes: [node] });
    if (node.data.item && !isSpecialNode(node.data.itemIcon)) {
      updateSelectedItems(node.data.item, -1);
    }
  };

  const handleOpenSpecificationMenu = (
    event: React.MouseEvent,
    node: ItemIconNode
  ) => {
    if (
      reactFlowWrapper?.current &&
      reactFlowInstance &&
      !isSpecialNode(node.data.itemIcon)
    ) {
      const pane = reactFlowWrapper.current.getBoundingClientRect();
      const zoom = reactFlowInstance.getZoom();
      const screenPos = reactFlowInstance.flowToScreenPosition(node.position);

      const bufferLeft = 10; // Margin from the edges of the pane
      const menuWidth = MENU_WIDTH; // Static menu width
      const menuHeight = MENU_HEIGHT; // Static menu height

      let left = screenPos.x + 85 * zoom; // Base left position adjusted by zoom level

      // Adjust left position for edge constraints
      if (left + menuWidth + bufferLeft > pane.width) {
        left = screenPos.x - menuWidth - bufferLeft; // Flip to the left side of the node
      } else if (left < bufferLeft) {
        left = bufferLeft; // Ensure it's not too close to the left edge
      }

      let top = screenPos.y; // Base top position
      const bufferTop = 135;

      // Adjust top position to keep the menu on screen
      if (top + menuHeight + bufferTop > pane.height) {
        top = pane.height - menuHeight - bufferTop; // Move up just enough to keep it on screen
      }

      setSpecificationMenu({
        nodeId: node.id,
        top: top,
        left: left,
        right: false,
        bottom: false,
        handleItemSpecification,
      });
    }
  };

  /*
    REACT FLOW INTERACTIONS
  */
  const onEdgeClick = (_: React.MouseEvent, edge: Edge) => {
    switch (mode) {
      case MODES.DELETE:
        setElementsToDelete({ edges: [edge] });
        break;
      default:
        break;
    }
  };

  const onNodeClick = (event: React.MouseEvent, node: ItemIconNode) => {
    switch (mode) {
      case MODES.SPECIFICATION:
        handleOpenSpecificationMenu(event, node);
        break;
      case MODES.DELETE:
        handleDeleteNode(node);
        break;
      default:
        break;
    }
  };

  const onNodeDrag = (event: React.MouseEvent, node: ItemIconNode) => {
    switch (mode) {
      case MODES.SPECIFICATION:
        // See if this is required for ipad or not
        if (isIpad) {
          handleOpenSpecificationMenu(event, node);
        } else {
          closeSpecificationMenu();
        }
        break;
      default:
        closeSpecificationMenu();
        break;
    }
  };

  const onPaneClick = (event: React.MouseEvent) => {
    switch (mode) {
      case MODES.CHANNEL:
        addChannelNode(event);
        break;
      default:
        break;
    }
  };

  const closeSpecificationMenu = () => {
    setSpecificationMenu(null);
  };

  const onMoveStart = (_: React.MouseEvent) => {
    closeSpecificationMenu();
  };

  const onItemIconDrop = (
    itemIcon: ItemIcon,
    position: { x: number; y: number }
  ) => {
    // Ensure the ref is current and React Flow instance is available
    if (reactFlowWrapper.current && reactFlowInstance) {
      const { left, top, right, bottom } =
        reactFlowWrapper.current.getBoundingClientRect();

      // Check if the event position is within the React Flow wrapper
      if (
        position.x >= left &&
        position.x <= right &&
        position.y >= top &&
        position.y <= bottom
      ) {
        // Convert screen position to React Flow's internal position
        const nodePosition = reactFlowInstance.screenToFlowPosition({
          x: position.x,
          y: position.y,
        });

        // Call the desired function with the item icon and the converted position
        newItemIconSelected(itemIcon, nodePosition);
      }
    }
  };

  const postData = async (): Promise<string | void> => {
    const currentCsrf = await getCsrf();
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('X-CSRFToken', currentCsrf || '');
    const response = (await fetch('/api/order/create/', {
      method: 'POST',
      credentials: 'same-origin',
      headers: requestHeaders,
      body: JSON.stringify(designData),
    })) as any;

    if (!response.ok) {
      try {
        const error = await response.json();
        setErrorMessage(Object.values(error).join(''));
      } catch {
        setErrorMessage('An error occurred');
      }
      return;
    }
    const data = await response.json();
    return data.id;
  };

  const putData = async (): Promise<string | void> => {
    const currentCsrf = await getCsrf();
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('X-CSRFToken', currentCsrf || '');
    const response = await fetch(`/api/order/update/${designId}/`, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: requestHeaders,
      body: JSON.stringify(designData),
    });

    if (!response.ok) {
      // handle any errors
      try {
        const error = await response.json();
        setErrorMessage(Object.values(error).join(''));
      } catch {
        setErrorMessage('An error occurred');
      }
      return;
    }
    const data = await response.json();
    return data.id;
  };

  const handleSave = async () => {
    setShowSuccessModal(true);
    setSaving(true);
    const requestFunc = designMode === 'edit' ? putData : postData;
    const id = await requestFunc();
    if (id && designMode !== 'edit') {
      setNewDesignId(id);
    } else {
      setNewDesignId(designId);
    }
    setSaving(false);
  };

  const sidePanelClass = classNames({
    [styles.paletteContainer]: !showDesignDetailsPanel,
    [styles.designDetailsPanel]: showDesignDetailsPanel,
  });

  const toolbarContainerClass = classNames({
    [styles.toolbarContainer]: true,
    [styles.toolbarHidden]: showDesignDetailsPanel,
  });

  return (
    <ItemIconProvider>
      <div className={styles.page}>
        <Button
          variant="secondary"
          onClick={handleBack}
          style={{
            position: 'absolute',
            left: '40px',
            top: '30px',
            zIndex: 1,
            border: '1px solid #D7DCE2',
            display: showDesignDetailsPanel ? 'none' : 'flex',
          }}
        >
          <Icon name="backIcon" height="16px" width="10px"></Icon>Back
        </Button>
        <div className={`${styles.designContainer} ${styles[mode]}`}>
          <ReactFlowProvider>
            <div
              ref={reactFlowWrapper}
              style={{ height: '100%', width: '100%' }}
            >
              <FlowComponent
                newNodes={newNodes}
                newEdges={newEdges}
                elementsToDelete={elementsToDelete}
                displayHandles={displayHandles}
                nodesDraggable={nodesDraggable}
                nodeToUpdate={nodeToUpdate}
                triggerValidateDesign={triggerValidateDesign}
                triggerFitView={triggerFitView}
                specificationMenu={specificationMenu}
                openSpecificationMenu={handleOpenSpecificationMenu}
                closeSpecificationMenu={closeSpecificationMenu}
                onSave={handleDesignDetailsPanel}
                onEdgeClick={onEdgeClick}
                onPaneClick={onPaneClick}
                onMoveStart={onMoveStart}
                onNodeClick={onNodeClick}
                onInit={setReactFlowInstance}
                onNodeDrag={onNodeDrag}
              ></FlowComponent>
            </div>
          </ReactFlowProvider>
          <div className={toolbarContainerClass}>
            <ToolbarComponent
              onToolChange={changeMode}
              overideToolSelection={overideToolSelection}
            ></ToolbarComponent>
          </div>
        </div>
        <div className={sidePanelClass}>
          <SideToolbar
            show={!showDesignDetailsPanel}
            onItemIconSelection={newItemIconSelected}
            doTriggerValidateDesign={handleTriggerValidateDesign}
            onItemIconDrop={onItemIconDrop}
          ></SideToolbar>
          <SaveModal
            show={showDesignDetailsPanel}
            onClosePanel={handleCloseDesignDetailsPanel}
            handleSave={handleSave}
          ></SaveModal>
        </div>
      </div>
      <Modal
        width="400px"
        onClose={() => setShowConfirmationModal(false)}
        buttons={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant="secondary"
              onClick={() => setShowConfirmationModal(false)}
              style={{ width: 90 }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              style={{ width: 90, backgroundColor: colorsConfig['error-red'] }}
              onClick={() =>
                handleRelocate(
                  designMode !== 'create'
                    ? `design-view/${designId}`
                    : 'directory'
                )
              }
            >
              Leave
            </Button>
          </div>
        }
        show={showConfirmationModal}
      >
        <div className={styles.warningModal}>
          <Icon
            name="leaveWarning"
            height="60px"
            width="auto"
            fill="white"
            iconStyle={{ margin: '10px 0 18px 0' }}
          ></Icon>
          <Text size="m" textAlign="center" fontWeight="600">
            Are you sure you want to leave this page?
          </Text>
          <Text
            size="s"
            textAlign="center"
            fontWeight="200"
            style={{ width: 300 }}
          >
            Your design has not been saved. If you leave this page, you will
            lose your progress.
          </Text>
        </div>
      </Modal>
      <Modal
        width="400px"
        buttons={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant="secondary"
              onClick={() => handleRelocate(`design`)}
              style={{ width: 190 }}
            >
              Create a new design
            </Button>
            <Button
              variant="primary"
              style={{ width: 119 }}
              onClick={() => handleRelocate(`design-view/${newDesignId}`)}
            >
              View design
            </Button>
          </div>
        }
        show={showSuccessModal}
      >
        <div className={styles.warningModal}>
          {saving ? (
            <>
              <Status loading></Status>
              <Text>Saving design...</Text>
            </>
          ) : !!errorMessage ? (
            <>
              <Status error errorMessage={errorMessage}></Status>
            </>
          ) : (
            <>
              <Icon
                name="successIcon"
                height="60px"
                width="auto"
                fill="white"
                iconStyle={{ margin: '10px 0 18px 0' }}
              ></Icon>
              <Text size="m" textAlign="center" fontWeight="600">
                Success
              </Text>
              <Text
                size="s"
                textAlign="center"
                fontWeight="200"
                style={{ width: 300 }}
              >
                {designMode === 'create-variant'
                  ? 'Your new design variant has been saved successfully'
                  : designMode === 'edit'
                  ? 'Your design has been updated successfully'
                  : 'Your design has been saved successfully'}
              </Text>
            </>
          )}
        </div>
      </Modal>
    </ItemIconProvider>
  );
};

export default DesignApp;
