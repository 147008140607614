import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  FitViewOptions,
  applyNodeChanges,
  applyEdgeChanges,
  Controls,
  Background,
  Node,
  Edge,
  NodeChange,
  EdgeChange,
  Connection,
  useReactFlow,
  ConnectionMode,
  useUpdateNodeInternals,
  ReactFlowJsonObject,
  ReactFlowInstance,
} from 'reactflow';
import 'reactflow/dist/style.css';

// ****************************
// NODE IMPORTS GO HERE
// ****************************
import NodeSinglePump from '../node-single-pump';
import NodeBufferTank from '../node-buffer-tank';
import NodeAirCooledModularScrew from '../node-air-cooled-modular-screw';
import NodeAirCooledScrew from '../node-air-cooled-screw';
import NodeAirHandlingUnit from '../node-air-handling-unit';
import NodeColdStore from '../node-cold-store';
import NodeDryAirCooler from '../node-dry-air-cooler';
import NodeFanCooledUnit from '../node-fan-cooled-unit';
import NodeLowTempCooler from '../node-low-temp-cooler';
import NodePlateHeatExchanger from '../node-plate-heat-exchanger';
import NodeRemoteConnectivityBox from '../node-remote-connectivity-box';
import NodeRoofTopAirHandler from '../node-roof-top-air-handler';
import NodeScrollCompressor from '../node-scroll-compressor';
import NodeTwinPump from '../node-twin-pump';
import NodeWaterCooledScrew from '../node-water-cooled-screw';
import NodeSystemIn from '../node-system-in';
import NodeSystemOut from '../node-system-out';
import NodeChannel from '../node-channel';
import { Item, ItemIconNode, ItemIconNodeData } from '../../types';
import { colorsConfig } from '../../common/design/colors';
import SpecificationMenu, {
  SpecificationMenuProps,
} from './specification-menu';

// Define custom node type outside of flow component
const nodeTypes = {
  nodeSinglePump: NodeSinglePump,
  nodeBufferTank: NodeBufferTank,
  nodeAirCooledModularScrew: NodeAirCooledModularScrew,
  nodeAirCooledScrew: NodeAirCooledScrew,
  nodeAirHandlingUnit: NodeAirHandlingUnit,
  nodeColdStore: NodeColdStore,
  nodeDryAirCooler: NodeDryAirCooler,
  nodeFanCooledUnit: NodeFanCooledUnit,
  nodeLowTempCooler: NodeLowTempCooler,
  nodePlateHeatExchanger: NodePlateHeatExchanger,
  nodeRemoteConnectivityBox: NodeRemoteConnectivityBox,
  nodeRoofTopAirHandler: NodeRoofTopAirHandler,
  nodeScrollCompressor: NodeScrollCompressor,
  nodeTwinPump: NodeTwinPump,
  nodeWaterCooledScrew: NodeWaterCooledScrew,
  nodeSystemIn: NodeSystemIn,
  nodeSystemOut: NodeSystemOut,
  nodeChannel: NodeChannel,
};

const fitViewOptions: FitViewOptions = {
  padding: 10,
  duration: 500,
};

const edgeStyling = {
  strokeWidth: 4,
  stroke: colorsConfig['medium-grey'],
};

interface FlowComponentProps {
  newNodes: ItemIconNode[];
  newEdges: Edge[];
  elementsToDelete: { nodes?: Node[]; edges?: Edge[] };
  nodesDraggable: boolean;
  displayHandles: boolean;
  nodeToUpdate?: { node: ItemIconNode; newData: Partial<ItemIconNodeData> };
  triggerValidateDesign: number;
  triggerFitView: number;
  specificationMenu: Omit<SpecificationMenuProps, 'getNode'> | null;
  onNodeClick: (event: React.MouseEvent, node: Node) => void;
  onEdgeClick: (event: React.MouseEvent, edge: Edge) => void;
  onNodeDrag: (event: React.MouseEvent, node: Node) => void;
  onPaneClick: (event: React.MouseEvent) => void;
  onMoveStart: (event: any) => void;
  onInit: (reactFlowInstance: ReactFlowInstance) => void;
  onSave: (designJson: ReactFlowJsonObject) => void;
  openSpecificationMenu: (event: React.MouseEvent, node: Node) => void;
  closeSpecificationMenu: () => void;
}

function FlowComponent({
  newNodes,
  newEdges,
  elementsToDelete,
  nodesDraggable,
  displayHandles,
  nodeToUpdate,
  triggerValidateDesign,
  triggerFitView,
  specificationMenu,
  onNodeClick,
  onEdgeClick,
  onNodeDrag,
  onMoveStart,
  onPaneClick,
  onInit,
  onSave,
  openSpecificationMenu,
  closeSpecificationMenu,
}: FlowComponentProps) {
  const {
    getNodes,
    getNode,
    addNodes,
    addEdges,
    deleteElements,
    toObject,
    fitView,
  } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const [nodes, setNodes] = useState<ItemIconNode[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [nodesWithErrors, setNodesWithErrors] = useState<string[]>([]);

  useEffect(() => {
    triggerFitView && fitView({ padding: 0.2, duration: 500 });
  }, [triggerFitView]);

  useEffect(() => {
    addNodes(newNodes);
  }, [newNodes]);

  useEffect(() => {
    addEdges(newEdges);
  }, [newEdges]);

  useEffect(() => {
    deleteElements(elementsToDelete);
  }, [elementsToDelete]);

  useEffect(() => {
    if (triggerValidateDesign) {
      const nodesMissingItems = nodes
        .filter((node) => !node.data.item)
        .map((node) => node.id);
      setNodesWithErrors(nodesMissingItems);
      if (nodesMissingItems.length) {
        return;
      }
      onSave(toObject());
    }
  }, [triggerValidateDesign]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node: ItemIconNode) => {
        node.data = {
          ...node.data,
          displayHandles,
          showError: nodesWithErrors.includes(node.id),
        };
        updateNodeInternals(node.id);
        return node;
      })
    );
  }, [displayHandles, nodesWithErrors]);

  useEffect(() => {
    if (nodeToUpdate) {
      setNodes((nds) =>
        nds.map((node: ItemIconNode) => {
          if (node.id === nodeToUpdate.node.id) {
            setNodesWithErrors((prevNodesWithErrors) => {
              return prevNodesWithErrors.filter((id) => id !== node.id);
            });
            node.data = {
              ...node.data,
              ...nodeToUpdate.newData,
            };
            updateNodeInternals(node.id);
          }
          return node;
        })
      );
    }
  }, [nodeToUpdate]);

  // Any functions passed to react flow should be wrapped in useCallback
  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const handleNodeRightClick = (
    event: React.MouseEvent,
    node: ItemIconNode
  ) => {
    event.preventDefault();
    event.stopPropagation();
    openSpecificationMenu(event, node);
  };

  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault();
    closeSpecificationMenu();
  };

  return (
    <ReactFlow
      defaultEdgeOptions={{ style: edgeStyling, type: 'smoothstep' }}
      connectionLineStyle={edgeStyling}
      nodes={nodes}
      edges={edges}
      nodesDraggable={nodesDraggable}
      nodesConnectable={displayHandles}
      connectionMode={ConnectionMode.Loose}
      nodeTypes={nodeTypes as any}
      maxZoom={5}
      fitViewOptions={fitViewOptions}
      onNodesChange={onNodesChange}
      proOptions={{ hideAttribution: true }}
      nodeDragThreshold={5}
      onInit={onInit}
      onNodeClick={onNodeClick}
      onEdgeClick={onEdgeClick}
      onPaneClick={onPaneClick}
      onMoveStart={onMoveStart}
      onDragOver={onDragOver}
      onNodeContextMenu={handleNodeRightClick}
      onContextMenu={handleRightClick}
      onNodeDrag={onNodeDrag}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      deleteKeyCode={null}
      selectionKeyCode={null}
      multiSelectionKeyCode={null}
      disableKeyboardA11y={false}
    >
      <Background></Background>
      <Controls></Controls>
      {specificationMenu && (
        <SpecificationMenu {...specificationMenu} getNode={getNode} />
      )}
    </ReactFlow>
  );
}

export default FlowComponent;
