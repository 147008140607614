import React from 'react';
import styles from './style.module.css';

import { DesignDetailsType, SummarySectionProps } from '../../types';
import Text from '../../ui/text';
import SummarySectionCardComponent from '../summary-section-card-component';

export function getScopeNotes(scopeNotes?: string): string {
  if (!scopeNotes) {
    return '<p>No scope notes added</p>';
  }
  return scopeNotes;
}
interface SummaryNotesComponentProps extends SummarySectionProps {
  designDetails?: Partial<DesignDetailsType>;
}

const SummaryNotesComponent = ({
  designDetails,
  toggleOpen,
  open,
}: SummaryNotesComponentProps) => {
  return (
    <SummarySectionCardComponent
      toggleOpen={toggleOpen}
      open={open}
      title="Design details"
    >
      <div className={styles.mainContainer}>
        <div className={styles.row}>
          <Text fontWeight="600" textAlign="left" alignSelf="left">
            Project name:
          </Text>
          <Text>{designDetails?.design_name}</Text>
          <Text fontWeight="600" textAlign="left" alignSelf="left">
            Reference nº:
          </Text>
          <Text>{designDetails?.reference_number}</Text>
        </div>
        <div className={styles.proposedConditionsContainer}>
          <Text
            fontWeight="600"
            textAlign="left"
            alignSelf="left"
            printFontSize="12px"
          >
            This proposal has been based on:
          </Text>
          <div className={styles.conditions}>
            <Text printFontSize="12px">
              <span style={{ fontWeight: 600 }}>Capacity: </span>
              {designDetails?.capacity || '-'} kW
            </Text>
            <Text printFontSize="12px">
              <span style={{ fontWeight: 600 }}>Ambient: </span>
              {designDetails?.ambient_temp || '-'} ºC
            </Text>
            <Text printFontSize="12px">
              <span style={{ fontWeight: 600 }}>Water: </span>
              {designDetails?.water_temp || '-'} ºC
            </Text>
          </div>
        </div>
        <div className={styles.divider}></div>
        <div
          dangerouslySetInnerHTML={{
            __html: getScopeNotes(designDetails?.scope_notes),
          }}
          className={styles.textContainer}
        ></div>
      </div>
    </SummarySectionCardComponent>
  );
};

export default SummaryNotesComponent;
