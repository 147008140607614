import React, { useContext } from 'react';
import { OpenStatus, OpenStatusKey } from './summary-component';
import { SelectedItemsBreakdown } from '../../types';
import { DesignDataContext } from '../../common/context/designData';
import SummaryCompanyComponent from '../summary-company-component';
import SummaryRentalDatesComponent from '../summary-rental-dates-component';
import SummaryLabourComponent from '../summary-labour-component';
import SummaryTransportationComponent from '../summary-transportation-component';
import SummaryItemsComponent from '../summary-items-component';
import SummaryFinalPriceComponet from '../summary-final-price-component';
import SummaryNotesComponent from '../summary-notes-component';
import SummaryExtrasComponent from '../summary-extras-component';

interface InternalViewProps {
  handleToggleOpen: (openStatus: OpenStatusKey) => () => void;
  openStatus: OpenStatus;
  splitSelectedItems?: SelectedItemsBreakdown;
  saveMode?: boolean;
}

const InternalViewComponent = ({
  handleToggleOpen,
  openStatus,
  splitSelectedItems,
  saveMode,
}: InternalViewProps) => {
  const { designData, derivedData } = useContext(DesignDataContext);

  return (
    <div>
      <SummaryCompanyComponent
        clientData={designData?.client_data}
        open={openStatus.companyOpen}
        toggleOpen={handleToggleOpen('companyOpen')}
      ></SummaryCompanyComponent>
      <SummaryNotesComponent
        designDetails={designData?.design_details}
        toggleOpen={handleToggleOpen('notesOpen')}
        open={openStatus.notesOpen}
      ></SummaryNotesComponent>
      <SummaryRentalDatesComponent
        logisticsData={designData?.logistics_data}
        saveMode={saveMode}
        toggleOpen={handleToggleOpen('rentalPeriodOpen')}
        open={openStatus.rentalPeriodOpen}
      ></SummaryRentalDatesComponent>
      <SummaryTransportationComponent
        logisticsData={designData?.logistics_data}
        clientData={designData?.client_data}
        saveMode={saveMode}
        toggleOpen={handleToggleOpen('transportOpen')}
        open={openStatus.transportOpen}
      ></SummaryTransportationComponent>
      <SummaryLabourComponent
        logisticsData={designData?.logistics_data}
        toggleOpen={handleToggleOpen('labourOpen')}
        open={openStatus.labourOpen}
      ></SummaryLabourComponent>
      <SummaryExtrasComponent
        logisticsData={designData?.logistics_data}
        toggleOpen={handleToggleOpen('extrasOpen')}
        derivedData={derivedData}
        open={openStatus.extrasOpen}
      ></SummaryExtrasComponent>
      <SummaryItemsComponent
        config={splitSelectedItems?.mainUnits}
        discount={designData?.logistics_data?.equipment_cost?.discount}
        toggleOpen={handleToggleOpen('mainUnitsOpen')}
        open={openStatus.mainUnitsOpen}
      ></SummaryItemsComponent>
      <SummaryItemsComponent
        config={splitSelectedItems?.hosesConnectorsAdapters}
        discount={designData?.logistics_data?.equipment_cost?.discount}
        toggleOpen={handleToggleOpen('hoseConnectorsAdaptersOpen')}
        open={openStatus.hoseConnectorsAdaptersOpen}
      ></SummaryItemsComponent>
      <SummaryItemsComponent
        config={splitSelectedItems?.valvesManifoldsReducers}
        discount={designData?.logistics_data?.equipment_cost?.discount}
        toggleOpen={handleToggleOpen('valvesManifoldsReducersOpen')}
        open={openStatus.valvesManifoldsReducersOpen}
      ></SummaryItemsComponent>
      <SummaryItemsComponent
        config={splitSelectedItems?.electrics}
        discount={designData?.logistics_data?.equipment_cost?.discount}
        toggleOpen={handleToggleOpen('electricsOpen')}
        open={openStatus.electricsOpen}
      ></SummaryItemsComponent>
      <SummaryItemsComponent
        config={splitSelectedItems?.otherAccessories}
        discount={designData?.logistics_data?.equipment_cost?.discount}
        toggleOpen={handleToggleOpen('otherAccessoriesOpen')}
        open={openStatus.otherAccessoriesOpen}
      ></SummaryItemsComponent>
      <SummaryFinalPriceComponet
        logisticsData={designData?.logistics_data}
        toggleOpen={handleToggleOpen('finalPriceOpen')}
        open={openStatus.finalPriceOpen}
      ></SummaryFinalPriceComponet>
    </div>
  );
};

export default InternalViewComponent;
