// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbarContainer__czjbs {
  display: flex;
  width: 89%;
  flex-direction: column;
  border-radius: 16px;
  align-items: center;
  padding: 20px 16px 40px;
  flex-grow: 1;
  box-sizing: border-box;
  gap: 40px;
  max-height: 100%;
  min-height: 0;
}

.iconContainer__ci2XR {
  display: grid;
  grid-template-columns: repeat(3, 75px);
  padding-top: 8px;
  grid-auto-rows: auto;
  align-content: start;
  justify-content: space-evenly;
  justify-items: center;
  background-color: #ffffff;
  border: 2px solid #f2f2f2;
  width: 100%;
  flex-grow: 2;
  border-radius: 12px;
  overflow-y: auto;
  max-height: 100%;
  min-height: 0;
}

.iconContainer__ci2XR.withStatus__y7lSW {
  align-content: center;
}

.hideToolbar__uV4qS {
  display: none;
}

.ipadIconContainer__PiVGX {
  align-content: space-around;
}

@media (max-width: 1100px) {
  .iconContainer__ci2XR {
    grid-template-columns: repeat(2, 75px);
  }
}

.icon-row__PT2SQ {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 4px 8px 8px 8px;
}

.divider__Fsks4 {
  width: 80%;
  height: 3px;
  background-color: #f2f2f2;
}
`, "",{"version":3,"sources":["webpack://./src/components/side-toolbar/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAU;EACV,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sCAAsC;EACtC,gBAAgB;EAChB,oBAAoB;EACpB,oBAAoB;EACpB,6BAA6B;EAC7B,qBAAqB;EACrB,yBAAyB;EACzB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,sCAAsC;EACxC;AACF;;AAEA;EACE,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;EACR,wBAAwB;AAC1B;;AAEA;EACE,UAAU;EACV,WAAW;EACX,yBAAyB;AAC3B","sourcesContent":[".toolbarContainer {\n  display: flex;\n  width: 89%;\n  flex-direction: column;\n  border-radius: 16px;\n  align-items: center;\n  padding: 20px 16px 40px;\n  flex-grow: 1;\n  box-sizing: border-box;\n  gap: 40px;\n  max-height: 100%;\n  min-height: 0;\n}\n\n.iconContainer {\n  display: grid;\n  grid-template-columns: repeat(3, 75px);\n  padding-top: 8px;\n  grid-auto-rows: auto;\n  align-content: start;\n  justify-content: space-evenly;\n  justify-items: center;\n  background-color: #ffffff;\n  border: 2px solid #f2f2f2;\n  width: 100%;\n  flex-grow: 2;\n  border-radius: 12px;\n  overflow-y: auto;\n  max-height: 100%;\n  min-height: 0;\n}\n\n.iconContainer.withStatus {\n  align-content: center;\n}\n\n.hideToolbar {\n  display: none;\n}\n\n.ipadIconContainer {\n  align-content: space-around;\n}\n\n@media (max-width: 1100px) {\n  .iconContainer {\n    grid-template-columns: repeat(2, 75px);\n  }\n}\n\n.icon-row {\n  display: flex;\n  cursor: pointer;\n  flex-direction: column;\n  align-items: center;\n  gap: 2px;\n  padding: 4px 8px 8px 8px;\n}\n\n.divider {\n  width: 80%;\n  height: 3px;\n  background-color: #f2f2f2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarContainer": `toolbarContainer__czjbs`,
	"iconContainer": `iconContainer__ci2XR`,
	"withStatus": `withStatus__y7lSW`,
	"hideToolbar": `hideToolbar__uV4qS`,
	"ipadIconContainer": `ipadIconContainer__PiVGX`,
	"icon-row": `icon-row__PT2SQ`,
	"divider": `divider__Fsks4`
};
export default ___CSS_LOADER_EXPORT___;
