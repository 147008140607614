import { useState } from 'react';
import { ADDITIONAL_ITEMS_CONFIGS } from '../../common/config/partTypes';

export type SaveStep =
  | 'accessories'
  | 'selectedItems'
  | 'company'
  | 'rentalPeriod'
  | 'delivery'
  | 'labour'
  | 'extras'
  | 'designDetails'
  | 'pricing'
  | 'summary';

const useSaveStep = (initialStep: SaveStep = 'accessories') => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const steps: readonly SaveStep[] = [
    'accessories',
    'selectedItems',
    'company',
    'rentalPeriod',
    'delivery',
    'labour',
    'extras',
    'designDetails',
    'pricing',
    'summary',
  ];

  const isFinalStep = (): boolean => {
    return currentStepIndex === steps.length - 1;
  };

  const isFirstStep = (): boolean => {
    return currentStepIndex === 0;
  };

  const allowedStepChange = (delta: number): boolean => {
    return !(delta < 0 && isFirstStep()) && !(delta > 0 && isFinalStep());
  };

  const getForwardButtonText = () => {
    return isFinalStep() ? 'Save Order' : 'Continue';
  };

  const getBackwardButtonText = () => {
    return isFirstStep() ? 'Cancel' : 'Back';
  };

  const changeStep = (delta: number) => {
    if (allowedStepChange(delta)) {
      setCurrentStepIndex((currentIndex) => currentIndex + delta);
    }
  };

  const goToFirstStep = () => {
    setCurrentStepIndex(0);
  };

  const getPercentageCompletion = (): number => {
    return ((currentStepIndex + 1) / steps.length) * 100;
  };

  const getCurrentStep = (): SaveStep => {
    return steps[currentStepIndex];
  };

  return {
    goToFirstStep,
    getForwardButtonText,
    getBackwardButtonText,
    changeStep,
    getPercentageCompletion,
    getCurrentStep,
    isFinalStep,
    isFirstStep,
  };
};

export default useSaveStep;
