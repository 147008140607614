// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standard__AQUO4 {
  background-color: var(--dark-grey);
  color: white;
  font-family: 'Helvetica Neue', sans-serif;
  width: 250px;
  padding: 6px;
  border-radius: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/tooltip/style.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,YAAY;EACZ,yCAAyC;EACzC,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".standard {\n  background-color: var(--dark-grey);\n  color: white;\n  font-family: 'Helvetica Neue', sans-serif;\n  width: 250px;\n  padding: 6px;\n  border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"standard": `standard__AQUO4`
};
export default ___CSS_LOADER_EXPORT___;
