import React from 'react';
import styles from './style.module.css';

import Text from '../../ui/text';
import Icon from '../../ui/icon';
import SummarySectionCardComponent from '../summary-section-card-component';
import { LogisticsData, SummarySectionProps } from '../../types';
import {
  getDiscountedDisplayPrice,
  pricingToDiscountedPrice,
} from '../../common/helpers/number-helpers';
import ValueCard from '../../ui/value-card';

interface SummaryLabourComponentProps extends SummarySectionProps {
  logisticsData?: Partial<LogisticsData>;
}

const SummaryLabourComponent = ({
  logisticsData,
  toggleOpen,
  open,
}: SummaryLabourComponentProps) => {
  const labourDiscount = logisticsData?.labour_cost?.discount || 0;
  return (
    <SummarySectionCardComponent
      toggleOpen={toggleOpen}
      open={open}
      title="Labour"
      infoText={`£${pricingToDiscountedPrice(logisticsData?.labour_cost)}`}
    >
      <>
        <div className={styles.labourForm}>
          <div className={styles.labourTitle}>
            <Text fontWeight="500" size="m">
              Labour type
            </Text>
          </div>

          <div className={styles.hoursTitle}>
            <Text fontWeight="500" size="m">
              Hours
            </Text>
          </div>

          <div className={styles.costTitle}>
            <Text fontWeight="500" size="m">
              Price
            </Text>
          </div>

          <div className={styles.textContainer}>
            <Text size="m" fontWeight="400">
              Installation
            </Text>
          </div>

          <div className={styles.hoursInput}>
            <Text size="m">
              {logisticsData?.installation_labour?.number_of_hours || 0}
            </Text>
          </div>

          <div className={styles.costContainer}>
            <Text size="m">
              £
              {getDiscountedDisplayPrice(
                logisticsData?.installation_labour?.cost,
                labourDiscount
              )}
            </Text>
          </div>

          <div className={styles.textContainer}>
            <Text size="m" fontWeight="400">
              Commissioning
            </Text>
          </div>

          <div className={styles.hoursInput}>
            <Text size="m">
              {logisticsData?.commissioning_labour?.number_of_hours || 0}
            </Text>
          </div>

          <div className={styles.costContainer}>
            <Text size="m">
              £
              {getDiscountedDisplayPrice(
                logisticsData?.commissioning_labour?.cost,
                labourDiscount
              )}
            </Text>
          </div>

          <div className={styles.textContainer}>
            <Text size="m" fontWeight="400">
              De-commissioning
            </Text>
          </div>

          <div className={styles.hoursInput}>
            <Text size="m">
              {logisticsData?.decommissioning_labour?.number_of_hours || 0}
            </Text>
          </div>

          <div className={styles.costContainer}>
            <Text size="m">
              £
              {getDiscountedDisplayPrice(
                logisticsData?.decommissioning_labour?.cost,
                labourDiscount
              )}
            </Text>
          </div>

          <div className={styles.textContainer}>
            <Text size="m" fontWeight="400">
              De-installation
            </Text>
          </div>

          <div className={styles.hoursInput}>
            <Text size="m">
              {logisticsData?.deinstallation_labour?.number_of_hours || 0}
            </Text>
          </div>

          <div className={styles.costContainer}>
            <Text size="m">
              £
              {getDiscountedDisplayPrice(
                logisticsData?.deinstallation_labour?.cost,
                labourDiscount
              )}
            </Text>
          </div>
        </div>
        <ValueCard
          innerStyle={{ width: '100%' }}
          label="Total labour price:"
          price={pricingToDiscountedPrice(logisticsData?.labour_cost)}
        ></ValueCard>
      </>
    </SummarySectionCardComponent>
  );
};

export default SummaryLabourComponent;
