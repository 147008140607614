import React, { Fragment, useState } from 'react';
import styles from './style.module.css';
import Button from '../../ui/button';
import Text from '../../ui/text';
import { UseAuth } from '../../components/auth-component/auth-provider';
import Icon from '../../ui/icon';
import Footer from '../../ui/footer';
import { style } from 'd3-selection';

const Header = (): React.ReactElement => {
  const { handleLogout } = UseAuth();
  return (
    <Fragment>
      <Icon
        name="traneLogo"
        iconStyle={{
          position: 'absolute',
          right: 25,
          top: 12,
        }}
        width="100px"
        height="auto"
      ></Icon>
      <Button
        onClick={handleLogout}
        variant="secondary"
        style={{
          position: 'absolute',
          left: 25,
          top: 12,
        }}
      >
        <Icon name="logoutIconV2"></Icon>
      </Button>
    </Fragment>
  );
};

const DirectoryApp = (): React.ReactElement => {
  const { handleRelocate } = UseAuth();

  return (
    <div className={styles.page}>
      <div className={styles.gutter}></div>
      <div className={styles.gutter}></div>
      <div className={styles.gutter}></div>
      <div className={styles.gutter}></div>
      <div className={styles.center}>
        <div className={styles.centerBackground}></div>
        <div className={styles.welcomeText}>
          <Text fontWeight="300" size="l">
            <span style={{ fontWeight: 'normal' }}>Welcome to</span> Rental
            Survey Tool
          </Text>
          <Text fontWeight="300" color="light-grey" size="ml">
            What do you want to do today?
          </Text>
        </div>
        <div className={styles.cardContainer}>
          <div className={`${styles.card} ${styles.cardLeft}`}>
            <Icon name="designPageIcon" fill="white"></Icon>
            <Text size="l" color="darkest-blue" fontWeight="600">
              Create a design
            </Text>
            <Text
              size="m"
              fontWeight="300"
              color="medium-grey"
              textAlign="center"
            >
              Design a new project, add order details and get a summary price.
            </Text>
            <Button
              variant="directory"
              onClick={() => handleRelocate('design')}
            >
              Start a design
            </Button>
          </div>
          <div className={styles.cardDivider}></div>
          <div className={styles.card}>
            <Icon name="searchPageIcon"></Icon>
            <Text size="l" color="darkest-blue" fontWeight="600">
              Search for a design
            </Text>
            <Text
              size="m"
              fontWeight="300"
              color="medium-grey"
              textAlign="center"
            >
              Find a project design to view or modify. Generate PDF proposals or
              contracts.
            </Text>
            <Button
              variant="directory"
              onClick={() => handleRelocate('search')}
            >
              Search for a design
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.gutter}></div>
      <div className={styles.gutter}></div>
      <div className={styles.gutter}></div>
      <div className={styles.gutter}></div>
      <Header></Header>
      <Footer></Footer>
    </div>
  );
};
export default DirectoryApp;
