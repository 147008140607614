const getSrc = (fileName: string, format = 'opentype'): string => {
  return `url(${window.location.origin}/static/assets/helvetica-neue-5/${fileName}) format('${format}')`;
};

export const fontsConfig = [
  {
    fontFamily: 'Helvetica Neue',
    src: getSrc('HelveticaNeueMedium.otf'),
    fontWeight: '600',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'Helvetica Neue',
    src: getSrc('HelveticaNeueRoman.otf'),
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'Helvetica Neue',
    src: getSrc('HelveticaNeueLight.otf'),
    fontWeight: '300',
    fontStyle: 'normal',
  },
];
