// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-container__YL3Cm {
  display: flex;
  min-height: 80px;
  background-color: white;
  align-items: center;
  width: 360px;
  border-radius: 8px;
  justify-content: center;
}

.buttons-toolbar__nOAZN {
  display: flex;
  justify-content: center;
  gap: 1px;
}

.view-header__WAPsv {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 16px;
  max-width: 100%;
  min-width: 0;
}

.internal-view-toggle__ED420 {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/components/toolbar-component/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,eAAe;EACf,eAAe;EACf,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,yBAAyB;AAC3B","sourcesContent":[".toolbar-container {\n  display: flex;\n  min-height: 80px;\n  background-color: white;\n  align-items: center;\n  width: 360px;\n  border-radius: 8px;\n  justify-content: center;\n}\n\n.buttons-toolbar {\n  display: flex;\n  justify-content: center;\n  gap: 1px;\n}\n\n.view-header {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  padding: 0 16px;\n  max-width: 100%;\n  min-width: 0;\n}\n\n.internal-view-toggle {\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar-container": `toolbar-container__YL3Cm`,
	"buttons-toolbar": `buttons-toolbar__nOAZN`,
	"view-header": `view-header__WAPsv`,
	"internal-view-toggle": `internal-view-toggle__ED420`
};
export default ___CSS_LOADER_EXPORT___;
