// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-container {
  width: 100%;
  display: flex;
  justify-content: center;
  width: 474px;
  border-bottom: 1px solid var(--progress-bar-blue);
  gap: 10px;
}

.react-tabs__tab-list {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: none;
}

.react-tabs__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 8px 41px;
  width: 150px;
  cursor: pointer;
  font-family: 'Helvetica Neue', sans-serif;
  background-color: var(--background-grey);
  color: var(--medium-grey);
  font-size: 15px;
  border-radius: 15px 15px 0 0;
  text-align: center;
  border-left: none;
  border-right: none;
}

.react-tabs__tab--selected {
  background-color: var(--progress-bar-blue);
  color: white;
  border-left: none;
  border-right: none;
}

.react-tabs__tab-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/extras-form/react-tabs.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,iDAAiD;EACjD,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,yCAAyC;EACzC,wCAAwC;EACxC,yBAAyB;EACzB,eAAe;EACf,4BAA4B;EAC5B,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,0CAA0C;EAC1C,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":[".tabs-container {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  width: 474px;\n  border-bottom: 1px solid var(--progress-bar-blue);\n  gap: 10px;\n}\n\n.react-tabs__tab-list {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  border-bottom: none;\n}\n\n.react-tabs__tab {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 30px;\n  padding: 8px 41px;\n  width: 150px;\n  cursor: pointer;\n  font-family: 'Helvetica Neue', sans-serif;\n  background-color: var(--background-grey);\n  color: var(--medium-grey);\n  font-size: 15px;\n  border-radius: 15px 15px 0 0;\n  text-align: center;\n  border-left: none;\n  border-right: none;\n}\n\n.react-tabs__tab--selected {\n  background-color: var(--progress-bar-blue);\n  color: white;\n  border-left: none;\n  border-right: none;\n}\n\n.react-tabs__tab-panel {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
