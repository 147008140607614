// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer__DRbsF {
  display: grid;
  grid-template-columns: 145px 112px;
  grid-column-gap: 12px;
}

.titleAndInputContainer__hk3po,
.inputMainContainer__j2EKQ {
  display: grid;
  grid-row-gap: 12px;
  justify-items: start;
}

.valueCardContainer__hpZdX {
  display: flex;
}

.valuePriceContainer__hJGyL {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0;
}

@media (max-width: 1200px) {
  .valuePriceContainer__hJGyL {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/summary-rental-dates-component/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kCAAkC;EAClC,qBAAqB;AACvB;;AAEA;;EAEE,aAAa;EACb,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;IACb,uBAAuB;IACvB,QAAQ;EACV;AACF","sourcesContent":[".mainContainer {\n  display: grid;\n  grid-template-columns: 145px 112px;\n  grid-column-gap: 12px;\n}\n\n.titleAndInputContainer,\n.inputMainContainer {\n  display: grid;\n  grid-row-gap: 12px;\n  justify-items: start;\n}\n\n.valueCardContainer {\n  display: flex;\n}\n\n.valuePriceContainer {\n  display: flex;\n  justify-content: space-between;\n  padding: 0 10px 0;\n}\n\n@media (max-width: 1200px) {\n  .valuePriceContainer {\n    display: flex;\n    justify-content: center;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `mainContainer__DRbsF`,
	"titleAndInputContainer": `titleAndInputContainer__hk3po`,
	"inputMainContainer": `inputMainContainer__j2EKQ`,
	"valueCardContainer": `valueCardContainer__hpZdX`,
	"valuePriceContainer": `valuePriceContainer__hJGyL`
};
export default ___CSS_LOADER_EXPORT___;
