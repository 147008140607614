// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-main-container__oE4eQ {
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
  min-width: 0;
  max-width: 100%;
}

@media print {
  .page-break-after__V4zcr {
    -moz-column-break-after: page;
         break-after: page;
  }

  .summary-main-container__oE4eQ {
    display: flex;
    flex-direction: column;
  }

  .internal-view-toggle__Vqt_v {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/summary-component/style.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,WAAW;EACX,sBAAsB;EACtB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE;IACE,6BAAiB;SAAjB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".summary-main-container {\n  justify-self: flex-start;\n  width: 100%;\n  box-sizing: border-box;\n  min-width: 0;\n  max-width: 100%;\n}\n\n@media print {\n  .page-break-after {\n    break-after: page;\n  }\n\n  .summary-main-container {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .internal-view-toggle {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary-main-container": `summary-main-container__oE4eQ`,
	"page-break-after": `page-break-after__V4zcr`,
	"internal-view-toggle": `internal-view-toggle__Vqt_v`
};
export default ___CSS_LOADER_EXPORT___;
