// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labourCostsForm__GjnYN {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 200px 150px min-content;
  row-gap: 8px;
}

.weeksInput__g028S {
  width: 25%;
  font-family: 'Helvetica Neue', sans-serif;
  color: var(--dark-grey);
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid var(--border-blue);
  padding: 0 10px;
  font-size: 16px;
}

.divider__taJNG {
  border-bottom: 1px solid var(--background-light-blue);
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 4;
}

.labourCostsFormContainer__ew39d {
  /* Center like this so when table grows it doesn't jump around */
  margin-left: calc(50% - 220px);
}
`, "",{"version":3,"sources":["webpack://./src/components/labour-form/style.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,8CAA8C;EAC9C,YAAY;AACd;;AAEA;EACE,UAAU;EACV,yCAAyC;EACzC,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,oCAAoC;EACpC,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,qDAAqD;EACrD,WAAW;EACX,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,gEAAgE;EAChE,8BAA8B;AAChC","sourcesContent":[".labourCostsForm {\n  margin-bottom: 50px;\n  display: grid;\n  grid-template-columns: 200px 150px min-content;\n  row-gap: 8px;\n}\n\n.weeksInput {\n  width: 25%;\n  font-family: 'Helvetica Neue', sans-serif;\n  color: var(--dark-grey);\n  height: 30px;\n  border-radius: 5px;\n  background-color: white;\n  border: 1px solid var(--border-blue);\n  padding: 0 10px;\n  font-size: 16px;\n}\n\n.divider {\n  border-bottom: 1px solid var(--background-light-blue);\n  width: 100%;\n  grid-column-start: 1;\n  grid-column-end: 4;\n}\n\n.labourCostsFormContainer {\n  /* Center like this so when table grows it doesn't jump around */\n  margin-left: calc(50% - 220px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labourCostsForm": `labourCostsForm__GjnYN`,
	"weeksInput": `weeksInput__g028S`,
	"divider": `divider__taJNG`,
	"labourCostsFormContainer": `labourCostsFormContainer__ew39d`
};
export default ___CSS_LOADER_EXPORT___;
