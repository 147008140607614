// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__R3Jzy {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/ui/dropdown/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;AACpB","sourcesContent":[".container {\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  justify-content: space-between;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__R3Jzy`
};
export default ___CSS_LOADER_EXPORT___;
