import { Pricing } from '../../types';

export const formatPrice = (priceString: string | undefined): string => {
  if (!priceString) {
    return '0.00';
  }
  const price = parseFloat(priceString);
  const integerPart = Math.floor(price);
  const decimalPart = (price - integerPart).toFixed(2);
  const formattedPrice = integerPart.toLocaleString('en-GB');
  return formattedPrice + decimalPart.slice(1);
};

export const formatNumberPrice = (priceNumber: number | undefined): string => {
  return formatPrice(priceNumber?.toString());
};

export const isNumber = (num: string | number | null | undefined) => {
  if (num === null) {
    return false;
  }
  return isFinite(Number(num));
};

export const getDiscountQuotient = (discount?: string | number): number => {
  const validDiscount = isNumber(discount) ? discount : 0;
  return (100 - Number(validDiscount)) / 100;
};

export const pricingToDiscountedPrice = (pricing?: Pricing): string => {
  if (!pricing) {
    return formatNumberPrice(0);
  }
  const { price, discount } = pricing;
  const priceNumber = Number(price || 0) * getDiscountQuotient(discount);
  return formatNumberPrice(priceNumber);
};

export const pricingToDiscountedPriceNumber = (pricing?: Pricing): number => {
  if (!pricing) {
    return 0;
  }
  const { price, discount } = pricing;
  const priceNumber = Number(price || 0) * getDiscountQuotient(discount);
  return priceNumber;
};

export const getDiscountedDisplayPrice = (
  price?: number | string,
  discount?: string | number
): string => {
  const discountQuotient = getDiscountQuotient(discount);
  const priceNumber = Number(price || 0);
  const discountedPrice = priceNumber * discountQuotient;
  return formatNumberPrice(discountedPrice);
};

export const formatNumberWithUnit = (
  decimalString?: string,
  suffix: string = '',
  defaultValue = 'No Data'
) => {
  if (
    decimalString === undefined ||
    parseFloat(decimalString) === 0 ||
    decimalString === null
  ) {
    return defaultValue;
  }
  return `${decimalString}${suffix}`;
};

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/* 
Clean numbers and allows negative numbers
*/
export const cleanNumberInput = (string: string): string => {
  return (
    string
      // Step 1: Preserve an optional leading negative sign, digits, and decimal points
      .replace(/[^\d.-]/g, '')
      // Step 2: Remove any additional minus signs that aren't at the start
      .replace(/(.)-/g, '$1')
      // Step 3: Ensure only one dot is present for decimal numbers
      .replace(/(\..*)\./g, '$1')
  );
};
