// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standard__eubMg {
  position: relative;
}

.standard__eubMg > input {
  width: calc(100% - 30px);
  font-family: 'Helvetica Neue', sans-serif;
  color: var(--dark-grey);
  border: none;
  height: 35px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid var(--border-blue);
  padding: 0 10px;
  font-size: 16px;
}

.standard__eubMg > input:focus {
  outline: none; /* Optional: Removes the default focus outline */
  box-shadow: inset 0 0 10px #4f95b352;
}

.standard__eubMg > input::-moz-placeholder {
  color: var(--light-grey);
}

.standard__eubMg > input::placeholder {
  color: var(--light-grey);
}

.iconContainer__OkZQ2 {
  position: absolute;
  left: 10px;
  z-index: 13;
  bottom: 11px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/input/style.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,yCAAyC;EACzC,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,oCAAoC;EACpC,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa,EAAE,gDAAgD;EAC/D,oCAAoC;AACtC;;AAEA;EACE,wBAAwB;AAC1B;;AAFA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,YAAY;AACd","sourcesContent":[".standard {\n  position: relative;\n}\n\n.standard > input {\n  width: calc(100% - 30px);\n  font-family: 'Helvetica Neue', sans-serif;\n  color: var(--dark-grey);\n  border: none;\n  height: 35px;\n  border-radius: 5px;\n  background-color: white;\n  border: 1px solid var(--border-blue);\n  padding: 0 10px;\n  font-size: 16px;\n}\n\n.standard > input:focus {\n  outline: none; /* Optional: Removes the default focus outline */\n  box-shadow: inset 0 0 10px #4f95b352;\n}\n\n.standard > input::placeholder {\n  color: var(--light-grey);\n}\n\n.iconContainer {\n  position: absolute;\n  left: 10px;\n  z-index: 13;\n  bottom: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"standard": `standard__eubMg`,
	"iconContainer": `iconContainer__OkZQ2`
};
export default ___CSS_LOADER_EXPORT___;
