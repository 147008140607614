// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labourForm__xNK9V {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  grid-row-gap: 10px;
}

.hoursTitle__WsBAJ,
.labourTitle__yuHPt,
.costTitle__oDIIs {
  display: flex;
  justify-content: start;
  border-bottom: 1px solid var(--background-light-blue);
  margin-bottom: 5px;
}

.hoursInput__iHAzb,
.costContainer__rXKb4,
.textContainer__n3rnQ {
  display: flex;
  justify-content: start;
}
`, "",{"version":3,"sources":["webpack://./src/components/summary-labour-component/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;;;EAGE,aAAa;EACb,sBAAsB;EACtB,qDAAqD;EACrD,kBAAkB;AACpB;;AAEA;;;EAGE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".labourForm {\n  display: grid;\n  grid-template-columns: 3fr 3fr 1fr;\n  grid-row-gap: 10px;\n}\n\n.hoursTitle,\n.labourTitle,\n.costTitle {\n  display: flex;\n  justify-content: start;\n  border-bottom: 1px solid var(--background-light-blue);\n  margin-bottom: 5px;\n}\n\n.hoursInput,\n.costContainer,\n.textContainer {\n  display: flex;\n  justify-content: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labourForm": `labourForm__xNK9V`,
	"hoursTitle": `hoursTitle__WsBAJ`,
	"labourTitle": `labourTitle__yuHPt`,
	"costTitle": `costTitle__oDIIs`,
	"hoursInput": `hoursInput__iHAzb`,
	"costContainer": `costContainer__rXKb4`,
	"textContainer": `textContainer__n3rnQ`
};
export default ___CSS_LOADER_EXPORT___;
