import React, { ReactNode, useState, useRef } from 'react';
import { usePopper } from 'react-popper';
import stylesModule from './style.module.css';

type TooltipProps = {
  content: ReactNode;
  children: ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  const [visible, setVisible] = useState(false);
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: 'top',
  });

  return (
    <div
      onMouseOver={() => setVisible(true)}
      onMouseOut={() => setVisible(false)}
    >
      <div ref={setReferenceElement}>{children}</div>
      {visible && (
        <div
          className={stylesModule.standard}
          ref={setTooltipElement}
          style={{ ...styles.popper, zIndex: 999, color: 'white' }}
          {...attributes.popper}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
