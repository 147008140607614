// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summaryCardMainContainer___fDBu {
  display: flex;
}

.summaryMainContainer__NIFmy {
  padding-bottom: 5px;
}

.summaryMainContainer__NIFmy.open__gsJPt {
    padding-bottom: 10px;
  }

.summaryHeader__YdhDN {
  display: grid;
  grid-template-columns: 2fr auto auto;
  grid-column-gap: 30px;
  background-color: #ffffff;
  width: 100%;
  border: 1px solid var(--background-light-blue);
  border-radius: 2px;
  justify-items: stretch;
  padding: 8px 8px;
  align-content: center;
  height: 30px;
  align-items: center;
}

.summaryHeader__YdhDN.open__gsJPt {
    background-color: #fcfdfe;
    justify-items: start;
    border-bottom: none;
  }

.summaryHeader__YdhDN.double__UXnle {
    height: 46px;
  }

.summaryBody___vVRx {
  padding: 15px 20px;
  background-color: #fcfdfe;
  border: 1px solid var(--background-light-blue);
}

.summaryButton__uEyKA {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.summaryButton__uEyKA.open__gsJPt {
    transition: transform 0.5s ease;
    transform: rotate(180deg);
    background-color: #fcfdfe;
  }

.infoTextContainer__hhBBw {
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/components/summary-section-card-component/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AAKrB;;AAHE;IACE,oBAAoB;EACtB;;AAGF;EACE,aAAa;EACb,oCAAoC;EACpC,qBAAqB;EACrB,yBAAyB;EACzB,WAAW;EACX,8CAA8C;EAC9C,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;EAChB,qBAAqB;EACrB,YAAY;EACZ,mBAAmB;AAWrB;;AATE;IACE,yBAAyB;IACzB,oBAAoB;IACpB,mBAAmB;EACrB;;AAEA;IACE,YAAY;EACd;;AAGF;EACE,kBAAkB;EAClB,yBAAyB;EACzB,8CAA8C;AAChD;;AAEA;EACE,uBAAuB;EACvB,+BAA+B;AAOjC;;AALE;IACE,+BAA+B;IAC/B,yBAAyB;IACzB,yBAAyB;EAC3B;;AAGF;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,yBAAyB;AAC3B","sourcesContent":[".summaryCardMainContainer {\n  display: flex;\n}\n\n.summaryMainContainer {\n  padding-bottom: 5px;\n\n  &.open {\n    padding-bottom: 10px;\n  }\n}\n\n.summaryHeader {\n  display: grid;\n  grid-template-columns: 2fr auto auto;\n  grid-column-gap: 30px;\n  background-color: #ffffff;\n  width: 100%;\n  border: 1px solid var(--background-light-blue);\n  border-radius: 2px;\n  justify-items: stretch;\n  padding: 8px 8px;\n  align-content: center;\n  height: 30px;\n  align-items: center;\n\n  &.open {\n    background-color: #fcfdfe;\n    justify-items: start;\n    border-bottom: none;\n  }\n\n  &.double {\n    height: 46px;\n  }\n}\n\n.summaryBody {\n  padding: 15px 20px;\n  background-color: #fcfdfe;\n  border: 1px solid var(--background-light-blue);\n}\n\n.summaryButton {\n  transform: rotate(0deg);\n  transition: transform 0.2s ease;\n\n  &.open {\n    transition: transform 0.5s ease;\n    transform: rotate(180deg);\n    background-color: #fcfdfe;\n  }\n}\n\n.infoTextContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n  justify-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summaryCardMainContainer": `summaryCardMainContainer___fDBu`,
	"summaryMainContainer": `summaryMainContainer__NIFmy`,
	"open": `open__gsJPt`,
	"summaryHeader": `summaryHeader__YdhDN`,
	"double": `double__UXnle`,
	"summaryBody": `summaryBody___vVRx`,
	"summaryButton": `summaryButton__uEyKA`,
	"infoTextContainer": `infoTextContainer__hhBBw`
};
export default ___CSS_LOADER_EXPORT___;
