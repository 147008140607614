import React from 'react';
import styles from './style.module.css';
import Select from 'react-select';
import Text from '../text';
import { DropdownOption } from '../../types';
import { UseAuth } from '../../components/auth-component/auth-provider';

interface DropdownProps {
  dropdownLabel?: string;
  options?: DropdownOption[];
  selectedValue?: DropdownOption;
  onSelect: (option: DropdownOption) => void;
  placeHolder?: string;
  dropdownWidth?: string;
  noOptionsMessages?: string;
}

const Dropdown = (props: DropdownProps): React.ReactElement => {
  const { isIpad } = UseAuth();
  const handleOptionClick = (option: DropdownOption | null): void => {
    if (option) {
      props.onSelect(option);
    }
  };

  return (
    <div
      className={styles['container']}
      style={
        {
          '--dropdown-width': props.dropdownWidth || '350px',
        } as React.CSSProperties
      }
    >
      {props.dropdownLabel && (
        <Text truncate size="m">
          {props.dropdownLabel}
        </Text>
      )}
      <Select
        isSearchable={!isIpad}
        options={props.options}
        onChange={handleOptionClick}
        value={props.selectedValue || null}
        menuPortalTarget={document.body}
        noOptionsMessage={() => {
          const message = props.noOptionsMessages || 'No options available';
          return <Text size="m">{message}</Text>;
        }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontFamily: "'Helvetica Neue', sans-serif;",
            width: props.dropdownWidth || '350px',
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            fontFamily: "'Helvetica Neue', sans-serif;",
          }),
          menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 10,
          }),
        }}
      ></Select>
    </div>
  );
};

export default Dropdown;
