import React, { useContext, useState } from 'react';
import ProgressBar from '../../ui/progress-bar';
import useSaveStep from './step';
import ClientInfo from '../client-info';
import SelectedItemsTable from '../selected-items-table';
import LogisticsForm from '../logistics-form';
import DesignDetails from '../design-details';
import { DesignDataContext } from '../../common/context/designData';
import { UseAuth } from '../auth-component/auth-provider';
import SummaryComponent from '../summary-component';
import classNames from 'classnames';
import styles from './style.module.css';
import Button from '../../ui/button';
import Text from '../../ui/text';
import RentalPeriodForm from '../rental-period-form';
import LabourForm from '../labour-form';
import ExtrasForm from '../extras-form';
import { AdditionalItems } from '../additional-items-form/additional-items-form';
import TotalPriceForm from '../total-price-form';

interface SaveModalProps {
  show: boolean;
  onClosePanel: () => void;
  handleSave: () => Promise<void>;
}

const SaveModal = ({ show, onClosePanel, handleSave }: SaveModalProps) => {
  const [validationMessage, setValidationMessage] = useState<string>();
  const [saveProcessing, setSaveProcessing] = useState<boolean>(false);
  const { designData, designId, cleanEmptySelectedItems, cleanDataForSave } =
    useContext(DesignDataContext);
  const { getCsrf, handleRelocate } = UseAuth();

  const { changeStep, getCurrentStep, isFinalStep, isFirstStep } =
    useSaveStep();

  const handleBackward = () => {
    setValidationMessage(undefined);
    if (isFirstStep()) {
      // setShow(false);
      return;
    }
    changeStep(-1);
  };

  const validateForStep = (): boolean => {
    switch (getCurrentStep()) {
      case 'designDetails':
        if (!designData.design_details.design_name) {
          setValidationMessage('Design name is required');
          return false;
        }
      case 'pricing':
        if (designData.logistics_data?.equipment_cost?.discount === '-') {
          setValidationMessage('Valid number is required for discount');
          return false;
        }
      default:
        setValidationMessage(undefined);
        return true;
    }
  };

  const cleanUpSelectedItems = () => {
    // Remove all empty items from selected items on step advance
    cleanEmptySelectedItems();
  };

  const handleForward = () => {
    const valid = validateForStep();
    cleanUpSelectedItems();
    if (getCurrentStep() === 'pricing') {
      // There is likely a better way to do this but this ensures
      // data is clean for summary page and BE save
      cleanDataForSave();
    }
    if (!valid) {
      return;
    }
    setValidationMessage(undefined);
    if (isFinalStep()) {
      setSaveProcessing(true);
      handleSave();
      return;
    }
    changeStep(1);
  };

  const containerClass = classNames({
    [styles.container]: true,
    [styles.hide]: !show,
  });

  const headerClass = classNames({
    [styles.header]: true,
    [styles.summary]: getCurrentStep() === 'summary',
  });

  const footerClass = classNames({
    [styles.footer]: true,
  });

  return (
    <div className={containerClass}>
      <div className={headerClass}>
        <Text
          size="l"
          fontWeight="200"
          style={{ display: getCurrentStep() === 'summary' ? 'block' : 'none' }}
        >
          Order Summary
        </Text>
        <ProgressBar
          step={getCurrentStep()}
          style={{ display: getCurrentStep() === 'summary' ? 'none' : 'flex' }}
        ></ProgressBar>
      </div>
      <div className={styles.body}>
        {getCurrentStep() === 'accessories' ? (
          <AdditionalItems></AdditionalItems>
        ) : getCurrentStep() === 'selectedItems' ? (
          <SelectedItemsTable></SelectedItemsTable>
        ) : getCurrentStep() === 'company' ? (
          <ClientInfo></ClientInfo>
        ) : getCurrentStep() === 'rentalPeriod' ? (
          <RentalPeriodForm></RentalPeriodForm>
        ) : getCurrentStep() === 'delivery' ? (
          <LogisticsForm></LogisticsForm>
        ) : getCurrentStep() === 'labour' ? (
          <LabourForm></LabourForm>
        ) : getCurrentStep() === 'extras' ? (
          <ExtrasForm></ExtrasForm>
        ) : getCurrentStep() === 'designDetails' ? (
          <DesignDetails></DesignDetails>
        ) : getCurrentStep() === 'pricing' ? (
          <TotalPriceForm></TotalPriceForm>
        ) : (
          <SummaryComponent
            design={designData}
            saveMode={true}
          ></SummaryComponent>
        )}
      </div>
      <div className={footerClass}>
        <Button
          variant="secondary"
          onClick={onClosePanel}
          style={{ justifySelf: 'flex-start', padding: '0 40px' }}
        >
          Edit Design
        </Button>
        <div className={styles.rightFooterButtons}>
          {!isFirstStep() && (
            <Button
              variant="secondary"
              style={{ padding: '0 40px' }}
              onClick={handleBackward}
            >
              Back
            </Button>
          )}
          <Button
            onClick={handleForward}
            style={{ padding: '0 40px' }}
            disabled={saveProcessing}
          >
            {isFinalStep() ? 'Save' : 'Next'}
          </Button>
        </div>
        {validationMessage && (
          <Text
            color="red"
            textAlign="end"
            style={{ gridColumn: '1/-1', marginTop: 5 }}
          >
            {validationMessage}
          </Text>
        )}
      </div>
    </div>
  );
};

export default SaveModal;
