// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input:focus {
  outline: none; /* Optional: Removes the default focus outline */
}

input::-moz-placeholder {
  color: var(--light-grey);
}

input::placeholder {
  color: var(--light-grey);
}

.quantityInput__FuXRm {
  font-family: 'Inter', sans-serif;
  border: none;
  width: auto;
  font-size: 16px;
  max-width: 40px;
  text-align: center;
  background-color: white;
}

.container__KiQtE {
  text-align: center;
  width: -moz-min-content;
  width: min-content;
  border: 1px solid var(--border-grey);
  background-color: white;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 24px 46px 24px;
  padding: 2px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/ui/number-input/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,gDAAgD;AACjE;;AAEA;EACE,wBAAwB;AAC1B;;AAFA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gCAAgC;EAChC,YAAY;EACZ,WAAW;EACX,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,uBAAkB;EAAlB,kBAAkB;EAClB,oCAAoC;EACpC,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,qCAAqC;EACrC,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":["input:focus {\n  outline: none; /* Optional: Removes the default focus outline */\n}\n\ninput::placeholder {\n  color: var(--light-grey);\n}\n\n.quantityInput {\n  font-family: 'Inter', sans-serif;\n  border: none;\n  width: auto;\n  font-size: 16px;\n  max-width: 40px;\n  text-align: center;\n  background-color: white;\n}\n\n.container {\n  text-align: center;\n  width: min-content;\n  border: 1px solid var(--border-grey);\n  background-color: white;\n  border-radius: 4px;\n  display: grid;\n  grid-template-columns: 24px 46px 24px;\n  padding: 2px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quantityInput": `quantityInput__FuXRm`,
	"container": `container__KiQtE`
};
export default ___CSS_LOADER_EXPORT___;
