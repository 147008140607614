import React from 'react';
import { Handle, Position } from 'reactflow';
import Icon from '../../ui/icon';
import { createSharedHandleStyle } from '../../common/config/globalHandleStyle';
import { ItemIconNodeData } from '../../types';

const NodeAirHandlingUnit = ({ data }: { data: ItemIconNodeData }) => {
  const sharedHandleStyle = createSharedHandleStyle(data, {
    width: '15px',
    height: '25px',
  });
  const rightStyle = { ...sharedHandleStyle, top: 23, right: 5 };
  const leftStyle = { ...sharedHandleStyle, top: 23, left: 4 };
  return (
    <div>
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={rightStyle}
      />
      <Icon
        name="airHandlingUnit"
        height="56px"
        width="56px"
        showError={data.showError}
        label={data.item?.name}
      ></Icon>
      <Handle type="source" position={Position.Left} id="a" style={leftStyle} />
    </div>
  );
};

export default NodeAirHandlingUnit;
