import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import { SaveStep } from '../../components/save-modal/step';
import { set, toUpper } from 'lodash';
import classNames from 'classnames';
import Text from '../text';
import { colorsConfig } from '../../common/design/colors';

interface ProgressBarProps {
  step: SaveStep;
  style: React.CSSProperties;
}

// Steps and displayed stages don't exactly match, so these are the 'stages'
const PROGRESS_STAGES = [
  'accessories',
  'company',
  'rentalPeriod',
  'delivery',
  'labour',
  'extras',
  'designDetails',
];

const getProgressStage = (step: SaveStep) => {
  const irregularSteps: Record<string, string> = {
    selectedItems: 'accessories',
    pricing: 'designDetails',
  };
  return irregularSteps[step] || step;
};

type StageProps = {
  state: string;
  stage: string;
};

const getHumanReadableStageName = (stage: string): string => {
  const stageNameMap: Record<string, string> = {
    rentalPeriod: 'rental period',
    designDetails: 'design details',
  };
  const name = stageNameMap[stage] || stage;
  return toUpper(name);
};

const stateStylingConfig: Record<string, Record<string, string>> = {
  complete: {
    stageNumberColor: 'white',
    stageNameColor: 'dark-blue',
  },
  inProgress: {
    stageNumberColor: 'progress-bar-blue',
    stageNameColor: 'dark-blue',
  },
  incomplete: {
    stageNumberColor: 'medium-grey',
    stageNameColor: 'light-grey',
  },
};

const Stage = ({ state, stage }: StageProps) => {
  const withTail = stage !== 'accessories';
  const stageNumber = PROGRESS_STAGES.findIndex((s) => s === stage) + 1;
  const [styleConfig, setStyleConfig] = useState<Record<string, string>>();

  useEffect(() => {
    if (!styleConfig || state !== 'complete') {
      setStyleConfig(stateStylingConfig[state]);
    } else {
      setTimeout(() => {
        setStyleConfig(stateStylingConfig[state]);
      }, 200);
    }
  }, [state]);

  const tailProgressClass = classNames({
    [styles.tailProgress]: true,
    [styles.full]: state === 'complete',
  });

  const stageBallCenterClass = classNames({
    [styles.stageBallCenter]: true,
    [styles.full]: state === 'complete',
  });

  const stageBallContainerClass = classNames({
    [styles.stageBallContainer]: true,
    [styles.incomplete]: state === 'incomplete',
  });

  return (
    <div className={styles.stageMainContainer}>
      {withTail && (
        <div className={styles.tailContainer}>
          <div className={tailProgressClass}></div>
        </div>
      )}
      <div className={styles.stageContainer}>
        <div className={stageBallContainerClass}>
          <div className={stageBallCenterClass}></div>
          <Text
            textAlign="center"
            color={styleConfig?.stageNumberColor}
            style={{
              position: 'absolute',
              bottom: '50%',
              whiteSpace: 'nowrap',
              left: '50%',
              transform: 'translate(-50%, 50%)',
            }}
          >
            {stageNumber}
          </Text>
        </div>
        <Text
          size="xs"
          color={styleConfig?.stageNameColor}
          style={{
            position: 'absolute',
            bottom: '-25px',
            whiteSpace: 'nowrap',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          {getHumanReadableStageName(stage)}
        </Text>
      </div>
    </div>
  );
};

const ProgressBar = ({ step, style }: ProgressBarProps) => {
  const [completeStages, setCompleteStages] = useState<string[]>([]);
  const [inProgressStages, setInprogressStages] = useState<string[]>([]);

  const setStageProgress = (newStage: string) => {
    const currentStageIndex = PROGRESS_STAGES.findIndex(
      (stage) => stage === newStage
    );
    setInprogressStages([newStage]);
    setCompleteStages(PROGRESS_STAGES.slice(0, currentStageIndex));
  };

  useEffect(() => {
    const newStage = getProgressStage(step);
    setStageProgress(newStage);
  }, [step]);

  const getStageState = (stage: string) => {
    if (completeStages.includes(stage)) {
      return 'complete';
    } else if (inProgressStages.includes(stage)) {
      return 'inProgress';
    } else {
      return 'incomplete';
    }
  };

  return (
    <div className={styles.mainContainer} style={style}>
      {PROGRESS_STAGES.map((stage, i) => {
        return (
          <Stage stage={stage} key={i} state={getStageState(stage)}></Stage>
        );
      })}
    </div>
  );
};

export default ProgressBar;
