import React from 'react';
import styles from './style.module.css';
import { ColorConfigKey, colorsConfig } from '../../common/design/colors';

type TextVariant = 'standard' | 'title' | 'light';

interface TextProps extends React.ComponentPropsWithoutRef<'p'> {
  size?: string;
  variant?: TextVariant;
  alignSelf?: string;
  lineHeight?: string;
  color?: string;
  truncate?: boolean;
  textAlign?: string;
  fontWeight?: string;
  printFontSize?: string;
  maxWidth?: string;
  style?: React.CSSProperties;
}

const textSizeMap: Record<string, string> = {
  xs: '12px',
  s: '14px',
  m: '16px',
  ml: '18px',
  l: '24px',
  xl: '30px',
};

class Text extends React.Component<TextProps, {}> {
  constructor(props: TextProps) {
    super(props);
  }

  render() {
    const {
      variant = 'standard',
      size = 'm',
      children,
      printFontSize,
      alignSelf = 'center',
      lineHeight = '1.25',
      color = '#303030',
      truncate = false,
      textAlign = 'start',
      fontWeight = 'light',
      maxWidth = 'unset',
      style = {},
    } = this.props;
    const textClass = `${styles.text} ${styles[variant]} ${
      truncate && styles['truncate']
    }`;

    const combinedStyle = {
      '--font-size': textSizeMap[size] || '16px',
      '--print-font-size': printFontSize || textSizeMap[size],
      '--align-self': alignSelf,
      '--line-height': lineHeight,
      '--color': colorsConfig[color as ColorConfigKey] || color,
      '--text-align': textAlign,
      '--font-weight': fontWeight,
      '--max-width': maxWidth,
      ...style,
    } as React.CSSProperties;
    return (
      <p className={textClass} style={combinedStyle}>
        {children}
      </p>
    );
  }
}

export default Text;
