// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page__KXLgc {
  margin: 0;
  min-height: 100vh;
  display: grid;
  place-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/protected-route/style.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,iBAAiB;EACjB,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".page {\n  margin: 0;\n  min-height: 100vh;\n  display: grid;\n  place-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `page__KXLgc`
};
export default ___CSS_LOADER_EXPORT___;
