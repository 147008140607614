import React, { useEffect, useState, useContext, Fragment } from 'react';
import {
  UserByRoles,
  PDFContactDetails,
  User,
  PDFExportView,
  PDFModalData,
  PDFExportBody,
} from '../../types';
import Modal from '../../ui/modal';
import Dropdown from '../../ui/dropdown';
import Text from '../../ui/text';
import styles from './style.module.css';
import { DesignDataContext } from '../../common/context/designData';
import { DropdownOption } from '../../types';
import { isEmpty } from 'lodash';
import Input from '../../ui/input';
import { GeneratePDFData } from '../../common/helpers/pdf-data-helper';
import { UseAuth } from '../auth-component/auth-provider';
import Button from '../../ui/button';
import Icon from '../../ui/icon';
import ToastMessage from '../../ui/toast-message';
import Status from '../../ui/status';
import { colorsConfig } from '../../common/design/colors';

// Define a type that will return DropdownOption if user is provided, otherwise undefined
type NormaliseUserToDropdownOption = <T extends User | undefined>(
  user: T
) => T extends User ? DropdownOption : undefined;

// Implement the function
const normaliseUserToDropdownOption: NormaliseUserToDropdownOption = (user) => {
  if (!user) {
    return undefined as any;
  }
  return {
    value: user.id,
    label: `${user.first_name} ${user.last_name}`,
  };
};

function toFileSystemSafeName(input: string) {
  // Replace characters that are not allowed in file names, including periods, with an underscore
  return input.replace(/[\\/:*?"<>|\s.]/g, '_');
}

const exportViewDropdownOptions = [
  {
    value: 'client_proposal',
    label: 'Client Proposal PDF',
  },
  {
    value: 'client_contract',
    label: 'Client Contract PDF',
  },
  {
    value: 'internal_view',
    label: 'Internal View PDF',
  },
];

const includeDesignImageOptions = [
  {
    value: 'yes',
    label: 'Include image',
  },
  {
    value: 'no',
    label: "Don't include image",
  },
];

const defaultExclusions = [
  'Weekend or out hours working',
  'Any works adjacent to areas affected by asbestos related material',
  'Additional works should any isolation valve not withhold system pressure',
  'Removal of lagging on existing system',
  'Water supply for filling the system if needed',
  'Electrical isolation of equipment to be worked on at distribution board',
  'Separate connection points for flexible hoses unless specified in our proposal',
  'Road closure permits if needed',
  'Ground / structural surveys or planning permission',
  'Any items not specifically mentioned in the proposal',
];

interface GenerateV2PDFModalProps {
  triggerShow: number;
  designImageFile?: File;
}

const defaultLoadingMessage = 'Preparing design image, please wait...';

const GenerateV2PDFModal = ({
  triggerShow,
  designImageFile,
}: GenerateV2PDFModalProps) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(defaultLoadingMessage);
  const [usersByRoles, setUsersByRoles] = useState<UserByRoles>();
  const [selectedUsers, setSelectedUsers] =
    useState<Partial<PDFContactDetails>>();
  const [exportView, setExportView] =
    useState<PDFExportView>('client_proposal');
  const [validationMessage, setValidationMessage] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();
  const [customerEmail, setCustomerEmail] = useState<string>();
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>();
  const [includeDesignImage, setincludeDesignImage] = useState<DropdownOption>({
    value: 'yes',
    label: 'Include image',
  });
  const [collectSiteContact, setCollectSiteContact] = useState(false);
  const [siteContactName, setSiteContactName] = useState<string>();
  const [siteContactEmail, setSiteContactEmail] = useState<string>();
  const [siteContactPhoneNumber, setSiteContactPhoneNumber] =
    useState<string>();
  const [exclusions, setExclusions] = useState(defaultExclusions);
  const [displayedExclusions, setDisplayedExclusions] =
    useState(defaultExclusions);
  const [activeCustomExclusion, setActiveCustomExclusion] =
    useState<string>('');
  const { designData, derivedData } = useContext(DesignDataContext);
  const { getCsrf } = UseAuth();

  const accountManagerDropdownOptions = usersByRoles?.account_manager.map(
    normaliseUserToDropdownOption
  );

  const salesSupportDropdownOptions = usersByRoles?.sales_support.map(
    normaliseUserToDropdownOption
  );
  const fetchUsersByRoles = async () => {
    const response = await fetch('/api/authentication/users-by-roles/', {
      credentials: 'same-origin',
    });
    if (!response.ok) {
      return;
    }
    const result = await response.json();
    setUsersByRoles(result);
  };

  const handleIncludeImageSelect = (selected: DropdownOption) => {
    setincludeDesignImage(selected);
  };

  const handleSelect =
    (role: 'account_manager' | 'sales_support') =>
    (selected: DropdownOption) => {
      if (usersByRoles) {
        const user = usersByRoles[role].find((u) => u.id == selected.value);
        if (user) {
          setSelectedUsers((prev) => {
            return {
              ...prev,
              [role]: user,
            };
          });
        }
      }
    };

  useEffect(() => {
    if (!usersByRoles) {
      fetchUsersByRoles();
    }
  }, []);

  useEffect(() => {
    if (triggerShow) {
      setShow(true);
    }
  }, [triggerShow]);

  const handleCancel = () => {
    setShow(false);
  };

  const selectedUsersValid = (selectedUsers?: Partial<PDFContactDetails>) => {
    return (
      selectedUsers &&
      !isEmpty(selectedUsers.account_manager) &&
      !isEmpty(selectedUsers.sales_support)
    );
  };

  const handleExportViewChange = (exportViewOpt: DropdownOption) => {
    const exportView = exportViewOpt.value as PDFExportView;
    setExportView(exportView);
  };

  const handleExclusionChange =
    (exclusion: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (exclusions.includes(exclusion)) {
        setExclusions((prev) =>
          prev.filter((pExclusion) => pExclusion !== exclusion)
        );
      } else {
        setExclusions((prev) => [...prev, exclusion]);
      }
    };

  const validate = (): boolean => {
    if (!exportView) {
      setValidationMessage('Please select PDF export type');
      return false;
    }
    if (!selectedUsersValid(selectedUsers)) {
      setValidationMessage('Account Manager and Sales Support are required');
      return false;
    }
    if (!customerName || !customerEmail) {
      setValidationMessage('Customer name and email are required.');
      return false;
    }
    if (collectSiteContact && (!siteContactName || !siteContactEmail)) {
      setValidationMessage('Site contact name and email are required.');
      return false;
    }
    if (!collectSiteContact) {
      setSiteContactName(customerName);
      setSiteContactEmail(customerEmail);
      setSiteContactPhoneNumber(customerPhoneNumber);
    }
    return true;
  };

  const createModalData = (): PDFModalData => {
    return {
      account_manager: selectedUsers!.account_manager!,
      sales_support: selectedUsers!.sales_support!,
      include_design_image: includeDesignImage.value === 'yes',
      export_view: exportView,
      exclusions_page: exclusions,
      customer_name: customerName!,
      customer_email: customerEmail!,
      customer_phone_number: customerPhoneNumber || '-',
      site_contact_name: siteContactName || customerName!,
      site_contact_email: siteContactEmail! || customerEmail!,
      site_contact_phone_number:
        siteContactPhoneNumber || customerPhoneNumber || '-',
    };
  };

  const fetchDataAndDownload = async (
    pdfData: PDFExportBody,
    imageFile: File
  ): Promise<void> => {
    try {
      const formData = new FormData();
      formData.append('pdfData', JSON.stringify(pdfData)); // Append PDF data as stringified JSON
      formData.append('designImage', imageFile); // Append image file

      const currentCsrf = await getCsrf();
      const requestHeaders = new Headers();
      requestHeaders.set('X-CSRFToken', currentCsrf || '');

      const response = await fetch('/api/authentication/pdf-gen/', {
        method: 'POST',
        credentials: 'same-origin',
        headers: requestHeaders,
        body: formData, // Send as FormData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const filename =
        designData.design_details?.reference_number ||
        designData.design_details?.design_name ||
        'design';
      handleFileDownload(blob, toFileSystemSafeName(filename));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleFileDownload = (blob: Blob, filename: string): void => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a); // Necessary for Firefox
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleSave = async () => {
    if (!designImageFile) {
      setValidationMessage(
        'Design image is not ready, please wait a few seconds then click save again.'
      );
      return;
    }
    if (!validate()) {
      return;
    }
    setLoadingMessage(
      'Generating PDF file. This could take up to a minute, do not navigate away from this page.'
    );
    setLoading(true);
    const modalData = createModalData();
    const pdfData = GeneratePDFData(designData, modalData, derivedData);
    await fetchDataAndDownload(pdfData, designImageFile);
    setShow(false);
    setLoading(false);
    setLoadingMessage(defaultLoadingMessage);
  };

  const handleAddCustomExclusion = (): void => {
    if (!activeCustomExclusion) {
      return;
    }
    setExclusions((prev: string[]) => {
      return [...prev, activeCustomExclusion];
    });
    setDisplayedExclusions((prev: string[]) => {
      return [...prev, activeCustomExclusion];
    });
    setActiveCustomExclusion('');
  };

  return (
    <Modal
      show={show}
      onClose={handleCancel}
      width="600px"
      errorMessage={validationMessage}
      title={
        <Fragment>
          <Text size="m" fontWeight="600">
            Print to PDF
          </Text>
        </Fragment>
      }
      buttons={
        !loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant="secondary"
              onClick={handleCancel}
              style={{ width: 90 }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              style={{ width: 150 }}
              onClick={handleSave}
            >
              Generate PDF
            </Button>
          </div>
        )
      }
    >
      {!usersByRoles || loading || !designImageFile ? (
        <Status loading message={loadingMessage}></Status>
      ) : (
        <Fragment>
          <div className={styles.dropdownContainer}>
            <Dropdown
              dropdownLabel="PDF Export Type"
              options={exportViewDropdownOptions}
              onSelect={handleExportViewChange}
              dropdownWidth="280px"
              selectedValue={exportViewDropdownOptions.find(
                (opt) => opt.value === exportView
              )}
            ></Dropdown>
            <Dropdown
              dropdownLabel="Include Design Image"
              options={includeDesignImageOptions}
              onSelect={handleIncludeImageSelect}
              dropdownWidth="280px"
              selectedValue={includeDesignImage}
            ></Dropdown>
            <Dropdown
              dropdownLabel="Account Manager"
              options={accountManagerDropdownOptions}
              onSelect={handleSelect('account_manager')}
              dropdownWidth="280px"
              selectedValue={normaliseUserToDropdownOption(
                selectedUsers?.account_manager
              )}
            ></Dropdown>
            <Dropdown
              dropdownLabel="Sales Support"
              options={salesSupportDropdownOptions}
              onSelect={handleSelect('sales_support')}
              dropdownWidth="280px"
              selectedValue={normaliseUserToDropdownOption(
                selectedUsers?.sales_support
              )}
            ></Dropdown>
          </div>

          <div className={styles.divider}></div>
          <div className={styles.customerDataNotice}>
            <Text textAlign="center" fontWeight="600">
              Customer data
            </Text>
            <ToastMessage
              icon={
                <Icon
                  fill="#1979ec"
                  name="infoIcon"
                  height="14px"
                  width="14px"
                  iconStyle={{ minWidth: '14px' }}
                ></Icon>
              }
              style={{ marginTop: '8px' }}
              text="Due to GDPR compliance, customer data is not retained within the Trane rental tool. Please add customer information you wish to be included in the PDF here. Ensure you have customer consent to save their data in the proposal PDF."
            ></ToastMessage>
          </div>
          <div className={styles.contactContainer}>
            <div className={styles.formRow}>
              <Text size="sm" variant="standard">
                Customer Full Name
              </Text>
              <Text size="sm" variant="standard">
                Customer Phone Number
              </Text>
            </div>

            <div className={styles.inputContainer}>
              <Input
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                name="full_name"
              ></Input>
              <Input
                value={customerPhoneNumber}
                onChange={(e) => setCustomerPhoneNumber(e.target.value)}
                name="phone_number"
              ></Input>
              <div className={styles.inputBottomRow}>
                <Text size="sm" variant="standard">
                  Customer Email
                </Text>
                <Input
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                  name="email"
                ></Input>
              </div>
            </div>
            <div className={styles.checkbox}>
              <input
                type="checkbox"
                checked={!collectSiteContact}
                onChange={() => setCollectSiteContact((prev) => !prev)}
              ></input>
              <Text size="s">Use as site contact too</Text>
            </div>
            {collectSiteContact && (
              <div>
                <div className={styles.formRow}>
                  <Text size="sm" variant="standard">
                    Site Contact Full Name
                  </Text>
                  <Text size="sm" variant="standard">
                    Site Contact Phone Number
                  </Text>
                </div>

                <div className={styles.inputContainer}>
                  <Input
                    value={siteContactName}
                    onChange={(e) => setSiteContactName(e.target.value)}
                    name="site_contact_full_name"
                  ></Input>
                  <Input
                    value={siteContactPhoneNumber}
                    onChange={(e) => setSiteContactPhoneNumber(e.target.value)}
                    name="site_contact_phone_number"
                  ></Input>
                </div>
                <div className={styles.inputBottomRow}>
                  <Text size="sm" variant="standard">
                    Site Contact Email
                  </Text>
                  <Input
                    value={siteContactEmail}
                    onChange={(e) => setSiteContactEmail(e.target.value)}
                    name="site_contact_email"
                  ></Input>
                </div>
              </div>
            )}
          </div>
          <div className={styles.divider}></div>
          <div className={styles.exclusions}>
            <Text
              textAlign="center"
              fontWeight="600"
              style={{ marginBottom: '8px' }}
            >
              Included Exclusions
            </Text>
            {displayedExclusions.map((exclusion) => {
              return (
                <div className={styles.exclusionCheckbox} key={exclusion}>
                  <input
                    type="checkbox"
                    checked={exclusions.includes(exclusion)}
                    onChange={handleExclusionChange(exclusion)}
                  ></input>
                  <Text size="s">{exclusion}</Text>
                </div>
              );
            })}
          </div>
          <div className={styles.customExclusion}>
            <Input
              placeholder="Custom exclusion..."
              style={{ width: '450px' }}
              value={activeCustomExclusion}
              onChange={(e) => setActiveCustomExclusion(e.target.value)}
              name="custom-exclusion"
            ></Input>
            <Button onClick={handleAddCustomExclusion} variant="secondary">
              <Icon
                name="addIconV2"
                fill="white"
                width="22px"
                height="22px"
              ></Icon>
              Add
            </Button>
          </div>
        </Fragment>
      )}
    </Modal>
  );
};

export default GenerateV2PDFModal;
