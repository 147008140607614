import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import styles from './style.module.css';
import Button from '../../ui/button';
import Text from '../../ui/text';
import { UseAuth } from '../../components/auth-component/auth-provider';
import Icon from '../../ui/icon';
import DesignListRow from '../../components/design-list-row';
import Status from '../../ui/status';
import DesignQuickViewModal from '../../components/design-quick-view-modal';
import { Design } from '../../types';
import { debounce } from 'lodash';
import { DesignDataProvider } from '../../common/context/designData';
import Search from '../../ui/search';

interface SearchAppProps {}

const SearchApp = ({}: SearchAppProps): ReactElement => {
  const { handleRelocate, savedFilters, setSavedFilters } = UseAuth();
  const [designs, setDesigns] = useState<Design[]>([]);
  const [designCount, setDesignCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [triggerShowQuickView, setTriggerShowQuickView] = useState<number>(0);
  const [shownDesignId, setShownDesignId] = useState<string>('');
  const [prevSavedFilters, setPrevSavedFilters] = useState(savedFilters);

  const fetchDesigns = useCallback(async (): Promise<void> => {
    setLoading(true);
    setError(false);
    setErrorMessage(undefined);

    const queryParams = new URLSearchParams();

    if (savedFilters.search) {
      queryParams.append('search', savedFilters.search as string);
    }
    if (savedFilters.status) {
      queryParams.append('status', savedFilters.status as string);
    }
    if (savedFilters['created_by_me']) {
      queryParams.append(
        'created_by_me',
        savedFilters['created_by_me'].toString()
      );
    }

    try {
      const response = await fetch(
        `/api/order/orders/?${queryParams.toString()}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch designs');
      }
      const data = await response.json();
      // Result is paginated but currently only using one page and not next
      setDesigns(data.results);
      setDesignCount(data.count || 0);
    } catch (error) {
      setError(true);
      setErrorMessage('Failed to fetch designs');
    } finally {
      setLoading(false);
    }
  }, [savedFilters]);

  useEffect(() => {
    // Check if savedFilters have actually changed
    if (!isEqual(savedFilters, prevSavedFilters)) {
      fetchDesigns();
      setPrevSavedFilters(savedFilters);
    }
  }, [savedFilters, prevSavedFilters, fetchDesigns]);

  useEffect(() => {
    // Initial load
    setSearchTerm((savedFilters.search as string) || '');
    fetchDesigns();
  }, []);

  const setFiltersFromPartial = (
    partialFilers: Record<string, string | boolean>
  ) => {
    setSavedFilters((prevFilters) => ({
      ...prevFilters,
      ...partialFilers,
    }));
  };

  const debouncedSetFilters = useCallback(
    debounce((newFilters) => {
      setFiltersFromPartial(newFilters);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSetFilters({ search: searchTerm });
  }, [searchTerm]);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target?.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    debouncedSetFilters({ search: newSearchTerm });
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSavedFilters((prev) => ({ ...prev, search: '' }));
  };

  const goToDesign = (designId: string) => {
    handleRelocate(`design-view/${designId}`);
  };

  const openQuickView = (designId: string) => {
    setShownDesignId(designId);
    setTriggerShowQuickView((prev) => prev + 1);
  };

  const changeSelectedFilter = (filter: string) => () => {
    setSavedFilters((prev) => {
      const newFilters = { ...prev, status: filter };
      if (filter === 'created_by_me') {
        newFilters.created_by_me = true;
        newFilters.status = 'show_all';
      } else {
        newFilters.created_by_me = false;
      }
      return newFilters;
    });
  };

  useEffect(() => {
    if (!designs.length && !loading) {
      setError(true);
      setErrorMessage('No designs found');
    }
  }, [designs]);

  return (
    <DesignDataProvider
      designMode="view"
      usePropDesignId
      propDesignId={shownDesignId}
    >
      <div className={styles.page}>
        <div className={styles.top}>
          <Button
            onClick={() => handleRelocate('directory')}
            variant="secondary"
            size="lg"
          >
            <Icon name="backIcon" height="16px" width="10px"></Icon>Back
          </Button>
          <Search
            onClearInput={clearSearch}
            onChange={handleSearchInput}
            value={searchTerm}
            placeholder="Search reference number, name or created by"
          ></Search>
          <Icon name="traneLogo" width="109px" height="auto"></Icon>
        </div>
        <div className={styles.middle}>
          <div className={styles.designCount}>
            <Text fontWeight="300" size="ml" color="dark-grey">
              Designs
            </Text>
            <Text fontWeight="300" size="xs" color="dark-grey">
              {`(${designCount})`}
            </Text>
          </div>
          <div className={styles.filters}>
            <Button
              variant="filter"
              active={
                savedFilters.status === 'show_all' &&
                !savedFilters.created_by_me
              }
              onClick={changeSelectedFilter('show_all')}
            >
              Show all
            </Button>
            <Button
              variant="filter"
              active={savedFilters.created_by_me}
              onClick={changeSelectedFilter('created_by_me')}
            >
              Created by me
            </Button>
            <Button
              variant="filter"
              active={savedFilters.status === 'proposal'}
              onClick={changeSelectedFilter('proposal')}
            >
              Proposal
            </Button>
            <Button
              variant="filter"
              active={savedFilters.status === 'quoted'}
              onClick={changeSelectedFilter('quoted')}
            >
              Quoted
            </Button>
            <Button
              variant="filter"
              active={savedFilters.status === 'lost'}
              onClick={changeSelectedFilter('lost')}
            >
              Lost
            </Button>
            <Button
              variant="filter"
              active={savedFilters.status === 'won'}
              onClick={changeSelectedFilter('won')}
            >
              Won
            </Button>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.designListTopRow}>
            <Text size="m" fontWeight="600" color="dark-grey">
              Reference nº
            </Text>
            <Text size="m" fontWeight="600" color="dark-grey">
              Design name
            </Text>
            <Text
              size="m"
              fontWeight="600"
              color="dark-grey"
              style={{ padding: '0 10px' }}
            >
              Created by
            </Text>
            <Text
              size="m"
              fontWeight="600"
              color="dark-grey"
              style={{ padding: '0 10px' }}
            >
              Date
            </Text>
            <Text
              size="m"
              fontWeight="600"
              color="dark-grey"
              style={{ padding: '0 10px' }}
            >
              Status
            </Text>
          </div>
          {loading || error ? (
            <Status
              loading={loading}
              error={error}
              errorMessage={errorMessage}
              hideIcon={!!designs.length}
            ></Status>
          ) : (
            designs.map((design) => (
              <DesignListRow
                id={design.id}
                referenceNumber={design.reference_number}
                key={design.id}
                designName={design.design_name}
                createdBy={design.created_by}
                price={design.price}
                createdAt={design.created_at}
                email={design.email}
                status={design.status}
                onClick={openQuickView}
                goToDesign={goToDesign}
              ></DesignListRow>
            ))
          )}
          <DesignQuickViewModal
            triggerShow={triggerShowQuickView}
            designId={shownDesignId}
          ></DesignQuickViewModal>
        </div>
      </div>
    </DesignDataProvider>
  );
};
export default SearchApp;
