import React, { useEffect, useState } from 'react';
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';
import Icon from '../../ui/icon';
import { createSharedHandleStyle } from '../../common/config/globalHandleStyle';
import { ItemIconNode } from '../../types';
import { UseAuth } from '../auth-component/auth-provider';
import { colorsConfig } from '../../common/design/colors';

const NodeTwinPump = (node: ItemIconNode) => {
  const [rotation, setRotation] = useState(0);
  const updateNodeInternals = useUpdateNodeInternals();
  const { isIpad } = UseAuth();

  useEffect(() => {
    if (node.data.rotation !== undefined && node.data.rotation !== rotation) {
      setRotation(node.data.rotation);
      setTimeout(() => updateNodeInternals(node.id), 20);
    }
  }, [node]);

  const rotateNode = (event: React.MouseEvent | React.TouchEvent) => {
    if (!isIpad || event.type === 'touchstart') {
      const newRotation = (rotation + 90) % 360;
      setRotation(newRotation);
      node.data.onRotation(node, newRotation);
      updateNodeInternals(node.id);
      event.stopPropagation();
    }
  };

  const sharedHandleStyle = createSharedHandleStyle(node.data, {
    height: '30px',
    width: '10px',
  });
  const rightStyle = { ...sharedHandleStyle, top: 34, right: 3 };
  const leftStyle = { ...sharedHandleStyle, top: 34, left: 2 };
  const topStyle = { ...sharedHandleStyle, width: 30, height: 10, top: -7 };
  const bottomStyle = {
    ...sharedHandleStyle,
    width: 30,
    height: 10,
    bottom: 11,
  };
  return (
    <div>
      {node.data.displayHandles && (
        <button
          style={{
            position: 'absolute',
            bottom: -18,
            right: -37,
            zIndex: 1,
            backgroundColor: colorsConfig['dark-blue'],
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '50%',
            padding: '10px',
          }}
          // https://github.com/xyflow/xyflow/issues/2341
          onMouseDownCapture={rotateNode}
          onTouchStartCapture={rotateNode}
        >
          <Icon fill="white" name="rotate" height="20px" width="20px"></Icon>
        </button>
      )}
      <Icon
        name="twinPump"
        height="86px"
        width="86px"
        showError={node.data.showError}
        label={node.data.item?.name}
        rotation={rotation}
      ></Icon>
      {rotation === 0 || rotation === 180 ? (
        <div>
          <Handle
            type="source"
            position={Position.Right}
            id="b"
            style={rightStyle}
            isConnectable={node.data.displayHandles}
          />
          <Handle
            type="source"
            position={Position.Left}
            id="a"
            style={leftStyle}
            isConnectable={node.data.displayHandles}
          />
        </div>
      ) : (
        <div>
          <Handle
            type="source"
            position={Position.Top}
            id="a"
            style={topStyle}
            isConnectable={node.data.displayHandles}
          />
          <Handle
            type="source"
            position={Position.Bottom}
            id="b"
            style={bottomStyle}
            isConnectable={node.data.displayHandles}
          />
        </div>
      )}
    </div>
  );
};

export default NodeTwinPump;
