import { isEmpty } from 'lodash';
import {
  Address,
  DesignData,
  LabourData,
  LogisticsData,
  PDFContractPageData,
  PDFCoverPageData,
  PDFExportBody,
  PDFHeaderData,
  PDFInternalViewPageData,
  PDFLabourDataRow,
  PDFModalData,
  PDFOneOffItemsDataRow,
  PDFOrderDetailsPageData,
  PDFOtherWeeklyRentalsDataRow,
  PDFStandardItemdataRow,
  PDFTransportDataRow,
  SelectedItems,
  SelectedItemsBreakdown,
  User,
} from '../../types';
import { DerivedData } from '../context/designData';
import { convertDateFormat } from './date-helpers';
import {
  getDiscountQuotient,
  pricingToDiscountedPriceNumber,
} from './number-helpers';
import {
  formatAddress,
  getVehicleName,
} from '../../components/summary-transportation-component/summary-transportation-component';
import {
  filterAdditionalItems,
  filterUnitItems,
  getSelectedItemsWeeklyRentalPrice,
} from './item-filters';
import { ADDITIONAL_ITEMS_CONFIGS } from '../config/partTypes';

export const getStringOrDefault = (
  string?: string | null,
  defaultString = '-'
) => {
  return string || defaultString;
};

const getFullName = (user?: User) => {
  if (!user) {
    return '-';
  }
  const { first_name, last_name } = user;
  return `${getStringOrDefault(first_name, '')} ${getStringOrDefault(
    last_name,
    ''
  )}`;
};

type FormatStringOpts = {
  defaultString: string;
  prefix: string; // include space
  suffix: string; // include space
};

const defaultFormatStringOpts = {
  defaultString: '-',
  prefix: '',
  suffix: '',
};

const getAddressArray = (
  defaultAddress: string[],
  address?: Address
): string[] => {
  if (!address || isEmpty(address)) {
    return defaultAddress;
  }
  const addressFields = ['name', 'street_address', 'city', 'postcode'];
  const result = addressFields.reduce<string[]>(
    (addressArray, field: string) => {
      const addressLine = address[field as keyof Address];
      if (addressLine) {
        return [...addressArray, addressLine];
      } else {
        return addressArray;
      }
    },
    []
  );
  return isEmpty(result) ? defaultAddress : result;
};

const formatString = (
  string?: string,
  formatStringOpts?: Partial<FormatStringOpts>
): string => {
  const { prefix, defaultString, suffix } = {
    ...defaultFormatStringOpts,
    ...formatStringOpts,
  };
  return `${prefix}${getStringOrDefault(string, defaultString)}${suffix}`;
};

type FormatNumberOpts = {
  locale: string;
  style: 'currency' | 'decimal';
  decimalPlaces: number;
  discount?: string;
  currency: 'GBP';
};

const defaultFormatNumberOpts: FormatNumberOpts = {
  locale: 'en-GB',
  style: 'currency',
  decimalPlaces: 2,
  discount: undefined,
  currency: 'GBP',
};

const applyDiscount = (number?: number, discount?: string | number): number => {
  const discountQuotient = getDiscountQuotient(discount);
  const processedNumber = number || 0;
  return discountQuotient * processedNumber;
};

const formatNumber = (
  number?: number | string,
  formatNumberOpts?: Partial<FormatNumberOpts>,
  formatStringOpts?: Partial<FormatStringOpts>
): string => {
  const stringOpts = { ...defaultFormatStringOpts, ...formatStringOpts };
  const { locale, style, decimalPlaces, currency, discount } = {
    ...defaultFormatNumberOpts,
    ...formatNumberOpts,
  };
  const cleanNum = typeof number === 'string' ? Number(number) : number;
  const processedNum = !!discount
    ? applyDiscount(cleanNum, discount)
    : cleanNum;
  const string = new Intl.NumberFormat(locale, {
    style,
    currency,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(processedNum || 0);
  if (!formatStringOpts) {
    return string;
  } else {
    return formatString(string, stringOpts);
  }
};

const generateCoverPageData = (
  designData: DesignData,
  modalData: PDFModalData
): PDFCoverPageData => {
  return {
    company_name: getStringOrDefault(designData.client_data.company?.name),
    design_name: getStringOrDefault(designData.design_details.design_name),
    reference_number: getStringOrDefault(
      designData.design_details?.reference_number
    ),
    created_by: getFullName(modalData.account_manager),
    contact_email: getStringOrDefault(modalData.account_manager.email),
    general_contact_number: '08457 165162',
  };
};

const generateHeaderData = (
  designData: DesignData,
  modalData: PDFModalData
): PDFHeaderData => {
  return {
    design_name: getStringOrDefault(designData.design_details.design_name),
    customer_name: modalData.customer_name,
    reference_number: getStringOrDefault(
      designData.design_details?.reference_number
    ),
    general_contact_number: '08457 165162',
  };
};

const generateOrderDetailsPageData = (
  designData: DesignData,
  modalData: PDFModalData,
  derivedData: DerivedData
): PDFOrderDetailsPageData => {
  return {
    capacity: formatString(designData.design_details?.capacity, {
      suffix: ' kW',
    }),
    water_temp: formatString(designData.design_details?.water_temp, {
      suffix: ' ºC',
    }),
    ambient_temp: formatString(designData.design_details?.ambient_temp, {
      suffix: ' ºC',
    }),
    scope_notes: formatString(designData.design_details.scope_notes, {
      defaultString: 'No scope notes added',
    }),
    labour_price: formatNumber(
      pricingToDiscountedPriceNumber(designData.logistics_data?.labour_cost)
    ),
    transport_price: formatNumber(
      pricingToDiscountedPriceNumber(
        designData.logistics_data?.transportation_cost
      )
    ),
    labour_and_transport_price: formatString(
      derivedData.labourAndTransportPrice,
      {
        prefix: '£',
      }
    ),
    one_off_items_price: formatNumber(
      pricingToDiscountedPriceNumber(
        designData.logistics_data?.one_off_items_cost
      )
    ),
    weekly_rental_price: formatString(derivedData.totalEquipmentWeeklyPrice, {
      prefix: '£',
    }),
    estimated_delivery_date: convertDateFormat(
      designData.logistics_data.start_date,
      'd MMM yyyy'
    ),
    hire_period: formatNumber(
      designData.logistics_data?.number_of_weeks,
      { style: 'decimal', decimalPlaces: 0 },
      { suffix: ' weeks' }
    ),
    total_price: formatNumber(designData.logistics_data.total_price),
    account_manager_name: getFullName(modalData.account_manager),
    account_manager_number: getStringOrDefault(
      modalData.account_manager.phone_number
    ),
    sales_support_name: getFullName(modalData.sales_support),
    sales_support_number: getStringOrDefault(
      modalData.sales_support.phone_number
    ),
  };
};

const generateContractPageData = (
  designData: DesignData,
  modalData: PDFModalData
): PDFContractPageData => {
  const customer_address_lines = getAddressArray(
    ['No address provided'],
    designData.client_data.company
  );
  const site_address_lines = getAddressArray(
    customer_address_lines,
    designData.client_data.company_site
  );
  return {
    customer_address_lines,
    site_address_lines,
    customer_name: getStringOrDefault(modalData.customer_name),
    customer_email: getStringOrDefault(modalData.customer_email),
    site_contact_name: getStringOrDefault(modalData.site_contact_name),
    site_contact_email: getStringOrDefault(modalData.site_contact_email),
    company_name: getStringOrDefault(
      designData.client_data.company?.name,
      'Not provided.'
    ),
  };
};

const getTransportDataRows = (
  designData: DesignData
): PDFTransportDataRow[] => {
  const discount = designData?.logistics_data?.transportation_cost?.discount;
  const transport = designData.logistics_data.transport;
  if (!transport || isEmpty(designData.logistics_data?.transport)) {
    return [];
  }
  return Object.values(transport).map((transportOption) => {
    return {
      destination: formatAddress(transportOption.destination),
      distance: formatNumber(
        transportOption.number_of_miles,
        { style: 'decimal', decimalPlaces: 1 },
        { suffix: ' miles' }
      ),
      return: !!transportOption.is_return_trip ? 'Yes' : 'No',
      vehicle_type: getVehicleName(transportOption.vehicle_type),
      price: formatNumber(transportOption.total_price, {
        discount: designData.logistics_data.transportation_cost?.discount,
      }),
    };
  });
};

const getLabourDataRows = (designData: DesignData): PDFLabourDataRow[] => {
  const labourTypeMap: Record<string, string> = {
    installation_labour: 'Installation',
    commissioning_labour: 'Commissioning',
    decommissioning_labour: 'De-commissioning',
    deinstallation_labour: 'De-installation',
  };
  const labourTypes = Object.keys(labourTypeMap);
  return labourTypes.map((type) => {
    const labourObj = designData.logistics_data[
      type as keyof LogisticsData
    ] as LabourData;
    return {
      labour_type: labourTypeMap[type],
      hours: formatNumber(
        labourObj?.number_of_hours,
        { style: 'decimal', decimalPlaces: 0 },
        { suffix: ' hours' }
      ),
      price: formatNumber(labourObj?.cost, {
        discount: designData.logistics_data?.labour_cost?.discount,
      }),
    };
  });
};

const getOneOffItemDataRows = (
  designData: DesignData
): PDFOneOffItemsDataRow[] => {
  const oneOffItems = designData.logistics_data.one_off_items;
  const discount = designData.logistics_data.one_off_items_cost?.discount;
  if (!oneOffItems || isEmpty(oneOffItems)) {
    return [];
  }
  return Object.values(oneOffItems)
    .filter((item) => !isEmpty(item))
    .map((item) => {
      return {
        item_name: item.name,
        price: formatNumber(item.price, { discount: discount }),
      };
    });
};

const getOtherWeeklyRentalsDataRows = (
  designData: DesignData
): PDFOtherWeeklyRentalsDataRow[] => {
  const otherWeeklyRentals = designData.logistics_data.other_weekly_rentals;
  const discount =
    designData.logistics_data.other_weekly_rentals_cost?.discount;
  const numberOfWeeks = designData.logistics_data.number_of_weeks || 0;
  if (!otherWeeklyRentals || isEmpty(otherWeeklyRentals)) {
    return [];
  }
  return Object.values(otherWeeklyRentals)
    .filter((item) => !isEmpty(item))
    .map((item) => {
      const numberPrice =
        (Number(item.weekly_rental_price) || 0) * numberOfWeeks;
      return {
        item_name: item.name,
        weekly_price: formatNumber(item.weekly_rental_price, {
          discount: discount,
        }),
        price: formatNumber(numberPrice, { discount: discount }),
      };
    });
};

const createUnitItemBreakdown = (designData: DesignData) => {
  const filteredSelectedItems = filterUnitItems(designData?.selected_items);
  const discountQuotient = getDiscountQuotient(
    designData.logistics_data.equipment_cost?.discount
  );
  const weeklyRentalPrice = getSelectedItemsWeeklyRentalPrice(
    filteredSelectedItems
  );
  const weeklyRentalPriceWithDiscount = weeklyRentalPrice * discountQuotient;
  const totalPrice =
    weeklyRentalPriceWithDiscount *
    (designData?.logistics_data.number_of_weeks || 0);
  return {
    weeklyRentalPrice: formatNumber(weeklyRentalPriceWithDiscount),
    totalPrice: formatNumber(totalPrice),
    selectedItems: filteredSelectedItems,
  };
};

const createAdditionalItemsBreakdown = (designData: DesignData) => {
  return Object.entries(ADDITIONAL_ITEMS_CONFIGS).reduce(
    (breakdown, keyValue) => {
      const [key, config] = keyValue;
      const filteredSelectedItems = filterAdditionalItems(
        designData?.selected_items,
        config.unitSeries
      );
      const discountQuotient = getDiscountQuotient(
        designData.logistics_data.equipment_cost?.discount
      );
      const weeklyRentalPrice = getSelectedItemsWeeklyRentalPrice(
        filteredSelectedItems
      );
      const weeklyRentalPriceWithDiscount =
        weeklyRentalPrice * discountQuotient;
      const totalPriceWithDiscount =
        weeklyRentalPriceWithDiscount *
        (Number(designData?.logistics_data.number_of_weeks) || 0);
      breakdown[key as keyof SelectedItemsBreakdown] = {
        weeklyRentalPrice: formatNumber(weeklyRentalPriceWithDiscount),
        totalPrice: formatNumber(totalPriceWithDiscount),
        title: config.name,
        selectedItems: filteredSelectedItems,
      };
      return breakdown;
    },
    {} as SelectedItemsBreakdown
  );
};

const getPDFItemDataRow = (
  designData: DesignData,
  selectedItems?: SelectedItems
): PDFStandardItemdataRow[] => {
  const discount = designData.logistics_data.equipment_cost?.discount;
  const numberOfWeeks = designData.logistics_data.number_of_weeks || 0;
  if (!selectedItems || isEmpty(selectedItems)) {
    return [];
  }
  return Object.values(selectedItems).map((item) => {
    const weeklyPriceNumber = item.weekly_rental_price * item.quantity;
    const priceNumber = weeklyPriceNumber * numberOfWeeks;
    return {
      item_name: item.name,
      quantity: formatNumber(item.quantity, {
        style: 'decimal',
        decimalPlaces: 0,
      }),
      weekly_unit_price: formatNumber(item.weekly_rental_price, { discount }),
      weekly_price: formatNumber(weeklyPriceNumber, { discount }),
      price: formatNumber(priceNumber, { discount }),
    };
  });
};

const generateInternalViewPage = (
  designData: DesignData,
  modalData: PDFModalData
): PDFInternalViewPageData => {
  const mainUnitBreakdown = createUnitItemBreakdown(designData);
  const additionalItemsBreakdowns = createAdditionalItemsBreakdown(designData);
  const company_name = getStringOrDefault(
    designData.client_data?.company?.name
  );
  const company_street_address = getStringOrDefault(
    designData.client_data?.company?.street_address
  );
  const company_city = getStringOrDefault(
    designData.client_data?.company?.city
  );
  const company_country = getStringOrDefault(
    designData.client_data?.company?.country
  );
  const company_postcode = getStringOrDefault(
    designData.client_data?.company?.postcode
  );
  return {
    company_name,
    company_street_address,
    company_city,
    company_country,
    company_postcode,
    customer_name: getStringOrDefault(modalData.customer_name),
    customer_email: getStringOrDefault(modalData.customer_email),
    customer_phone_number: getStringOrDefault(modalData.customer_phone_number),
    site_contact_name: getStringOrDefault(modalData.site_contact_name),
    site_contact_email: getStringOrDefault(modalData.site_contact_email),
    site_contact_phone_number: getStringOrDefault(
      modalData.site_contact_phone_number
    ),
    site_name: getStringOrDefault(
      designData.client_data?.company_site?.name,
      company_name
    ),
    site_street_address: getStringOrDefault(
      designData.client_data?.company_site?.street_address,
      company_street_address
    ),
    site_city: getStringOrDefault(
      designData.client_data?.company_site?.city,
      company_city
    ),
    site_country: getStringOrDefault(
      designData.client_data?.company_site?.country,
      company_country
    ),
    site_postcode: getStringOrDefault(
      designData.client_data?.company_site?.postcode,
      company_postcode
    ),
    rental_date_start: convertDateFormat(designData.logistics_data?.start_date),
    rental_date_end: convertDateFormat(designData.logistics_data?.end_date),
    number_of_weeks: formatNumber(
      designData.logistics_data?.number_of_weeks,
      { style: 'decimal', decimalPlaces: 0 },
      { suffix: ' weeks' }
    ),
    transport_rows: getTransportDataRows(designData),
    labour_rows: getLabourDataRows(designData),
    labour_total_price: formatNumber(
      pricingToDiscountedPriceNumber(designData.logistics_data?.labour_cost)
    ),
    one_off_items_rows: getOneOffItemDataRows(designData),
    one_off_items_total_price: formatNumber(
      pricingToDiscountedPriceNumber(
        designData.logistics_data?.one_off_items_cost
      )
    ),
    other_weekly_rentals: getOtherWeeklyRentalsDataRows(designData),
    other_weekly_rentals_total_price: formatNumber(
      pricingToDiscountedPriceNumber(
        designData.logistics_data?.other_weekly_rentals_cost
      )
    ),
    main_units_rows: getPDFItemDataRow(
      designData,
      mainUnitBreakdown.selectedItems
    ),
    main_units_total_price: mainUnitBreakdown.totalPrice,
    main_units_weekly_price: mainUnitBreakdown.weeklyRentalPrice,
    hose_items_rows: getPDFItemDataRow(
      designData,
      additionalItemsBreakdowns.hosesConnectorsAdapters.selectedItems
    ),
    hose_items_total_price:
      additionalItemsBreakdowns.hosesConnectorsAdapters.totalPrice,
    hose_items_weekly_price:
      additionalItemsBreakdowns.hosesConnectorsAdapters.weeklyRentalPrice,
    valve_items_rows: getPDFItemDataRow(
      designData,
      additionalItemsBreakdowns.valvesManifoldsReducers.selectedItems
    ),
    valve_manifolds_reducers_total_price:
      additionalItemsBreakdowns.valvesManifoldsReducers.totalPrice,
    valve_manifolds_reducers_weekly_price:
      additionalItemsBreakdowns.valvesManifoldsReducers.weeklyRentalPrice,
    electric_items_rows: getPDFItemDataRow(
      designData,
      additionalItemsBreakdowns.electrics.selectedItems
    ),
    electric_items_total_prices: additionalItemsBreakdowns.electrics.totalPrice,
    electric_items_weekly_prices:
      additionalItemsBreakdowns.electrics.weeklyRentalPrice,
    other_items_rows: getPDFItemDataRow(
      designData,
      additionalItemsBreakdowns.otherAccessories.selectedItems
    ),
    other_items_total_price:
      additionalItemsBreakdowns.otherAccessories.totalPrice,
    other_items_weekly_price:
      additionalItemsBreakdowns.otherAccessories.weeklyRentalPrice,
    equipment_total_price: formatNumber(
      pricingToDiscountedPriceNumber(designData.logistics_data?.equipment_cost)
    ),
    transport_total_price: formatNumber(
      pricingToDiscountedPriceNumber(
        designData.logistics_data?.transportation_cost
      )
    ),
    total_price: formatNumber(designData.logistics_data.total_price),
  };
};

export const GeneratePDFData = (
  designData: DesignData,
  modalData: PDFModalData,
  derivedData: DerivedData
): PDFExportBody => {
  return {
    export_view: modalData.export_view,
    include_design_image: modalData.include_design_image,
    cover_page: generateCoverPageData(designData, modalData),
    header: generateHeaderData(designData, modalData),
    order_details_page: generateOrderDetailsPageData(
      designData,
      modalData,
      derivedData
    ),
    exclusions_page: modalData.exclusions_page,
    contract_page: generateContractPageData(designData, modalData),
    internal_view_page: generateInternalViewPage(designData, modalData),
  };
};
