/**
 * This function takes an array of strings and a query parameter, and returns a query string
 * that can be appended to a URL to filter results based on the strings.
 * @param strings An array of strings to filter by
 * @param queryParam The name of the query parameter to use in the URL
 * @returns A query string in the format "?queryParam=encodedString1&queryParam=encodedString2&..."
 */
export const toQueryString = (
  strings: string[],
  queryParam: string
): string => {
  // Use the Array.map() method to encode each string and construct a query string parameter
  // for the specified queryParam, in the format "queryParam=encodedString"
  const encodedStrings = strings.map(
    (str) => `${encodeURIComponent(queryParam)}=${encodeURIComponent(str)}`
  );
  // Use the Array.join() method to concatenate the encoded strings with an "&" separator
  // and prepend the result with a "?" to form a valid query string
  return `${encodedStrings.join('&')}`;
};
