import React, { useEffect, useState, useMemo, useContext } from 'react';
import Text from '../../ui/text';
import styles from './style.module.css';
import { DesignData, Item, ItemOrder, SelectedItems } from '../../types';
import Search from '../../ui/search';
import Status from '../../ui/status';
import Button from '../../ui/button';
import Icon from '../../ui/icon';
import { debounce } from 'lodash';
import { DesignDataContext } from '../../common/context/designData';
import { ADDITIONAL_ITEMS_CONFIGS } from '../../common/config/partTypes';
import { filterAdditionalItems } from '../../common/helpers/item-filters';
import { toQueryString } from '../../common/helpers/api-helpers';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './react-tabs.css';
import NumberInput from '../../ui/number-input';

interface AddItemRowProps {
  item: Item;
  index: number;
  onAddItem: (item: Item, quantityDelta: number) => void;
}

const AddItemRow = ({ item, index, onAddItem }: AddItemRowProps) => {
  const style = {
    '--background-color': index % 2 ? '#ffffff' : '#f8f6ff',
  } as React.CSSProperties;
  return (
    <div className={styles.addItemRowContainer} style={style}>
      <Text color="dark-grey" fontWeight="400" style={{ paddingRight: 8 }}>
        {item.name}
      </Text>
      <Text textAlign="center" color="dark-grey" fontWeight="400">
        £{item.weekly_rental_price}
      </Text>
      <div className={styles.addItemButton}>
        <Button
          onClick={() => onAddItem(item, 1)}
          variant="empty"
          style={{
            backgroundColor: '#d7e5ea',
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            border: 'none',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
          }}
        >
          <Icon name="plusIconV2" width="14px" height="14px"></Icon>
        </Button>
      </div>
    </div>
  );
};

interface ItemQuantitiesRowProps {
  itemOrder: ItemOrder;
  updateItemBy: (
    item: Item,
    quantityDelta: number,
    removeEmpties: boolean
  ) => void;
  updateItemQuantity: (
    item: Item,
    newQuantity: number,
    removeEmpties: boolean
  ) => void;
}

const ItemQuantitiesRow = ({
  itemOrder,
  updateItemBy,
  updateItemQuantity,
}: ItemQuantitiesRowProps): React.ReactElement => {
  const handleInput = (value: number) => {
    updateItemQuantity(itemOrder, value, false);
  };

  return (
    <tr className={`${styles.row} ${styles.itemRow} `}>
      <td className={styles.cell}>
        <Text color="dark-grey" fontWeight="400">
          {itemOrder.name}
        </Text>
      </td>
      <td>
        <NumberInput
          onChange={handleInput}
          value={itemOrder.quantity}
        ></NumberInput>
      </td>
      <td className={styles.deleteCell}>
        <div
          className={styles.deleteIconContainer}
          onClick={() => updateItemQuantity(itemOrder, 0, true)}
        >
          <Icon name="binIconV2" height="24px" width="auto" fill="white"></Icon>
        </div>
      </td>
    </tr>
  );
};

interface AdditionalItemsFormProps {
  type:
    | 'hosesConnectorsAdapters'
    | 'valvesManifoldsReducers'
    | 'electrics'
    | 'otherAccessories';
}

const AdditionalItemsForm = ({ type }: AdditionalItemsFormProps) => {
  const [additionalItems, setAdditionalItems] = useState<Item[]>([]);
  const [displayedAdditionalItems, setDisplayAdditionalItems] = useState<
    Item[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [displayedSelectedItem, setDisplayedSelectedItems] = useState<
    ItemOrder[]
  >([]);
  const { designData, updateSelectedItems, updateSelectedItemsQuantity } =
    useContext(DesignDataContext);

  useEffect(() => {
    const selectedItems = designData.selected_items;
    const relevantSelectedItems = filterAdditionalItems(
      selectedItems,
      ADDITIONAL_ITEMS_CONFIGS[type].unitSeries
    );
    setDisplayedSelectedItems(Object.values(relevantSelectedItems));
  }, [designData, type]);

  const fetchItemIcons = async (): Promise<void> => {
    setLoading(true);
    setError(false);
    const unitSeriesQuery = toQueryString(
      ADDITIONAL_ITEMS_CONFIGS[type].unitSeries,
      'unit_series'
    );
    const response = await fetch(
      `/api/items/additional-items/?${unitSeriesQuery}`
    );
    if (!response.ok) {
      // Handle this
      setError(true);
      return;
    }
    const data = (await response.json()) as Item[];
    setAdditionalItems(data);
    setDisplayAdditionalItems(data);
    setLoading(false);
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target?.value.toLowerCase());
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    fetchItemIcons();
  }, [type]);

  useEffect(() => {
    setError(false);
    setErrorMessage(undefined);
    setLoading(true);
    const handleSearch = () => {
      if (!additionalItems.length) {
        return;
      }
      // Implement your search logic here
      if (searchTerm) {
        const searchResults = additionalItems?.filter((item) =>
          item.name.toLowerCase().includes(searchTerm)
        );
        setDisplayAdditionalItems(searchResults);
        if (!searchResults.length) {
          setError(true);
          setErrorMessage('No results found.');
        }
      } else {
        setDisplayAdditionalItems(additionalItems);
      }
      setLoading(false);
    };

    const debouncedSearch = debounce(handleSearch, 500);

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm]);

  return (
    <div className={styles.mainContainer}>
      <Search
        placeholder="Search item"
        onChange={handleSearchInput}
        onClearInput={clearSearch}
        value={searchTerm}
      ></Search>
      <div className={styles.additionalItemsContainer}>
        <div className={styles.additionalItemsHeaders}>
          <Text color="dark-grey m,m,m" fontWeight="600">
            Item
          </Text>
          <Text textAlign="center" color="dark-grey" fontWeight="600">
            Weekly price
          </Text>
        </div>
        <div className={styles.additionalItemsTable}>
          {loading || error ? (
            <Status
              loading={loading}
              error={error}
              errorMessage={errorMessage}
              hideIcon={!!additionalItems.length}
            ></Status>
          ) : (
            displayedAdditionalItems?.map((displayedItem, index) => (
              <AddItemRow
                index={index}
                item={displayedItem}
                key={displayedItem.id}
                onAddItem={updateSelectedItems}
              ></AddItemRow>
            ))
          )}
        </div>
      </div>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.row} ${styles.rowHeader}`}>
            <th className={styles.headerCellLeft}>
              <Text color="dark-grey" fontWeight="600">
                Item
              </Text>
            </th>
            <th className={styles.headerCellRight}>
              <Text color="dark-grey" fontWeight="600">
                Quantity
              </Text>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {displayedSelectedItem.length ? (
            displayedSelectedItem.map((itemOrder) => (
              <ItemQuantitiesRow
                itemOrder={itemOrder}
                updateItemBy={updateSelectedItems}
                updateItemQuantity={updateSelectedItemsQuantity}
                key={itemOrder.id}
              ></ItemQuantitiesRow>
            ))
          ) : (
            <tr className={`${styles.row} ${styles.emptyRow}`}>
              <td className={styles['cell']}>
                <Text variant="light">Select items above...</Text>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export const AdditionalItems = () => {
  return (
    <div className={styles.tabsContainer}>
      <Tabs>
        <TabList>
          <div className="additional-items-tabs-container">
            <Tab className="additional-items-tab">
              Hoses, connectors, adapters
            </Tab>
            <Tab className="additional-items-tab">
              Valves, manifolds, reduces
            </Tab>
            <Tab className="additional-items-tab">Electrics</Tab>
            <Tab className="additional-items-tab">Others</Tab>
          </div>
        </TabList>
        <TabPanel>
          <AdditionalItemsForm type="hosesConnectorsAdapters"></AdditionalItemsForm>
        </TabPanel>
        <TabPanel>
          <AdditionalItemsForm type="valvesManifoldsReducers"></AdditionalItemsForm>
        </TabPanel>
        <TabPanel>
          <AdditionalItemsForm type="electrics"></AdditionalItemsForm>
        </TabPanel>
        <TabPanel>
          <AdditionalItemsForm type="otherAccessories"></AdditionalItemsForm>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default AdditionalItemsForm;
