import React, { useState, useEffect, useContext, useMemo } from 'react';
import Text from '../../ui/text';
import { SelectedItems } from '../../types';
import styles from './style.module.css';
import { isEmpty } from 'lodash';
import Status from '../../ui/status';
import ValueCard from '../../ui/value-card';
import Icon from '../../ui/icon';
import { DesignDataContext } from '../../common/context/designData';
import { getDiscountedDisplayPrice } from '../../common/helpers/number-helpers';

interface SelectedItemsTableProps {}

const SelectedItemsTable =
  ({}: SelectedItemsTableProps): React.ReactElement => {
    const { designData } = useContext(DesignDataContext);

    const selectedItemsArray = useMemo(() => {
      return Object.values(designData.selected_items);
    }, [designData.selected_items]);

    if (!designData.selected_items || isEmpty(designData.selected_items)) {
      return (
        <Status
          error={true}
          errorMessage={'You have not selected any items'}
        ></Status>
      );
    }
    return (
      <div className={styles.mainContainer}>
        <div className={styles.selectedItemsTitle}>
          <Text size="ml">Selected equipment</Text>
        </div>

        <div className={styles.titleContainer}>
          <Text color="dark-grey" fontWeight="600" size="m">
            Item
          </Text>
          <Text color="dark-grey" fontWeight="600" size="m">
            Quantity
          </Text>
          <Text color="dark-grey" fontWeight="600" size="m">
            Unit weekly price
          </Text>
          <Text color="dark-grey" fontWeight="600" size="m">
            Weekly price
          </Text>
        </div>
        {selectedItemsArray.map((itemOrder, index) => {
          const discountedDisplayWeeklyPrice = getDiscountedDisplayPrice(
            itemOrder.weekly_rental_price,
            designData.logistics_data.equipment_cost?.discount
          );
          const discountedDisplayTotalPrice = getDiscountedDisplayPrice(
            itemOrder.weekly_rental_price * itemOrder.quantity,
            designData.logistics_data.equipment_cost?.discount
          );
          return (
            <div className={styles.rowContainer} key={index}>
              <div className={styles.nameRow}>
                <Text fontWeight="400">{itemOrder.name}</Text>
              </div>
              <div className={styles.quantityRow}>
                <Text>{itemOrder.quantity}</Text>
              </div>
              <div className={styles.unitPricePerWeekRow}>
                <Text>£{discountedDisplayWeeklyPrice}</Text>
              </div>
              <div className={styles.totalPricePerWeekRow}>
                <Text>£{discountedDisplayTotalPrice}</Text>
              </div>
              {index !== selectedItemsArray.length - 1 && (
                <div className={styles.rowDivider}></div>
              )}
            </div>
          );
        })}
        <ValueCard
          price={getDiscountedDisplayPrice(
            designData.logistics_data.weekly_equipment_price,
            designData.logistics_data.equipment_cost?.discount
          )}
          label="Total weekly price:"
          style={{ marginTop: 10, marginBottom: 30 }}
        ></ValueCard>
      </div>
    );
  };

export default SelectedItemsTable;
