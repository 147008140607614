// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formRowThree__S1DIW,
.formRowTwo__djnZr,
.formRow__HtvNM {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 15px 0 3px 0;
  gap: 5px;
}

.inputContainerThree__IoReW,
.inputContainerTwo__zntku {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
}

.inputContainer__gcso4 {
  display: grid;
  grid-template-columns: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/company-form/style.module.css"],"names":[],"mappings":"AAAA;;;EAGE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,QAAQ;AACV;;AAEA;;EAEE,aAAa;EACb,8BAA8B;EAC9B,OAAO;AACT;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B","sourcesContent":[".formRowThree,\n.formRowTwo,\n.formRow {\n  display: grid;\n  grid-template-columns: 50% 50%;\n  padding: 15px 0 3px 0;\n  gap: 5px;\n}\n\n.inputContainerThree,\n.inputContainerTwo {\n  display: grid;\n  grid-template-columns: 49% 49%;\n  gap: 2%;\n}\n\n.inputContainer {\n  display: grid;\n  grid-template-columns: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRowThree": `formRowThree__S1DIW`,
	"formRowTwo": `formRowTwo__djnZr`,
	"formRow": `formRow__HtvNM`,
	"inputContainerThree": `inputContainerThree__IoReW`,
	"inputContainerTwo": `inputContainerTwo__zntku`,
	"inputContainer": `inputContainer__gcso4`
};
export default ___CSS_LOADER_EXPORT___;
