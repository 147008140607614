// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer__WeuYo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.container__xt9CT {
  display: flex;
  padding: 8px 16px 0 0;
  gap: 20px;
  width: 95%;
  border-top: 1px solid var(--background-light-blue);
  box-sizing: border-box;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/ui/value-card/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,SAAS;EACT,UAAU;EACV,kDAAkD;EAClD,sBAAsB;EACtB,yBAAyB;AAC3B","sourcesContent":[".mainContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  margin-top: 40px;\n}\n\n.container {\n  display: flex;\n  padding: 8px 16px 0 0;\n  gap: 20px;\n  width: 95%;\n  border-top: 1px solid var(--background-light-blue);\n  box-sizing: border-box;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `mainContainer__WeuYo`,
	"container": `container__xt9CT`
};
export default ___CSS_LOADER_EXPORT___;
