import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './containers/app/app';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://3480b1295b69f81ccd4f15ad2a77d653@o4506945220640768.ingest.us.sentry.io/4506945224114176',
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/tranerental\.uk/],
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
