import React from 'react';
import styles from './style.module.css';
import Text from '../text';
import { UseAuth } from '../../components/auth-component/auth-provider';
import { cleanNumberInput } from '../../common/helpers/number-helpers';
import Button from '../button';
import Icon from '../icon';
import { colorsConfig } from '../../common/design/colors';

interface InputProps {
  value?: number;
  onChange: (newValue: number) => void;
  max?: number;
  min?: number;
}

const NumberInput = ({
  value = 0,
  max = 9999,
  min = 0,
  onChange,
}: InputProps): React.ReactElement => {
  const { isIpad } = UseAuth();

  const validateNumber = (number: number): number => {
    if (!number) {
      return 0;
    }
    if (number > max) {
      return max;
    }
    if (number < min) {
      return min;
    }
    return number;
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanNumberString = cleanNumberInput(event.target.value);
    const number = validateNumber(Number(cleanNumberString));
    onChange(number);
  };

  const handleDeltaChange = (delta: number) => () => {
    const newValue = validateNumber(value + delta);
    onChange(newValue);
  };

  const handleOnBlur = () => {
    if (isIpad) {
      setTimeout(() => {
        // This prevents the bug that caused the PWA ipad app to occasionally freeze all inputs
        // when the keyboard was hidden.
        // Check if the newly focused element is not an input
        if (
          !(
            document?.activeElement?.tagName === 'INPUT' ||
            document?.activeElement?.tagName === 'TEXTAREA'
          )
        ) {
          // Force the page to scroll back into normal view if it isn't
          if (document.documentElement.scrollTop > 1) {
            document.documentElement.scrollTop = 100;
          }
        }
      }, 100); // Delay to allow focus to shift to the next element
    }
  };
  return (
    <div className={styles.container}>
      <Button
        variant="empty"
        onClick={handleDeltaChange(-1)}
        style={{
          padding: '6px',
          display: 'flex',
          height: '24px',
          width: '24px',
          backgroundColor: colorsConfig['background-light-blue'],
          border: 'none',
        }}
      >
        <Icon name="minusIconV2" width="22px" height="2px"></Icon>
      </Button>
      <input
        value={value}
        className={styles.quantityInput}
        onChange={handleInput}
        onBlur={handleOnBlur}
      ></input>
      <Button
        variant="empty"
        onClick={handleDeltaChange(1)}
        style={{
          padding: '6px',
          display: 'flex',
          height: '24px',
          width: '24px',
          backgroundColor: colorsConfig['background-light-blue'],
          border: 'none',
        }}
      >
        <Icon name="plusIconDarkV2" width="22px" height="auto"></Icon>
      </Button>
    </div>
  );
};

export default NumberInput;
