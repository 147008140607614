// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker__day-name {
  color: var(--darkest-blue);
  font-family: 'Helvetica Neue', sans-serif;
}

.react-datepicker__day-names {
  padding-top: 8px;
}

.react-datepicker__header {
  background-color: white;
  padding: 16px 0 0px 0;
  border-bottom: none;
}

.react-datepicker__navigation {
  top: 8px;
}

.react-datepicker {
  border: 1px solid var(--border-blue);
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day {
  border-radius: 50%;
}

.react-datepicker__day:hover {
  background-color: var(--background-light-blue);
  border-radius: 50%;
}

.react-datepicker__day--keyboard-selected {
  background-color: white;
  font-weight: normal;
}

.react-datepicker__day--selected:hover {
  background-color: #216ba5;
}
`, "",{"version":3,"sources":["webpack://./src/components/rental-period-form/style.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,yCAAyC;AAC3C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,8CAA8C;EAC9C,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".react-datepicker__day-name {\n  color: var(--darkest-blue);\n  font-family: 'Helvetica Neue', sans-serif;\n}\n\n.react-datepicker__day-names {\n  padding-top: 8px;\n}\n\n.react-datepicker__header {\n  background-color: white;\n  padding: 16px 0 0px 0;\n  border-bottom: none;\n}\n\n.react-datepicker__navigation {\n  top: 8px;\n}\n\n.react-datepicker {\n  border: 1px solid var(--border-blue);\n}\n\n.react-datepicker__triangle {\n  display: none !important;\n}\n\n.react-datepicker__day {\n  border-radius: 50%;\n}\n\n.react-datepicker__day:hover {\n  background-color: var(--background-light-blue);\n  border-radius: 50%;\n}\n\n.react-datepicker__day--keyboard-selected {\n  background-color: white;\n  font-weight: normal;\n}\n\n.react-datepicker__day--selected:hover {\n  background-color: #216ba5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
