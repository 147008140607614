import React, { useEffect, useState } from 'react';
import Button from '../../ui/button';
import Text from '../../ui/text';
import Input from '../../ui/input';
import Icon from '../../ui/icon';
import styles from './style.module.css';
import { UseAuth } from '../../components/auth-component/auth-provider';
import Footer from '../../ui/footer';
import { style } from 'd3-selection';

const LoginApp = (): React.ReactElement => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { handleLogin, handleRelocate, isAuthenticated } = UseAuth();

  useEffect(() => {
    redirectAuthenticatedUsers().catch(console.error);
  }, []);

  const redirectAuthenticatedUsers = async () => {
    const authenticated = await isAuthenticated();
    if (authenticated) {
      handleRelocate('directory');
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target?.value);
  };

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const login = async (): Promise<void> => {
    try {
      const response = await handleLogin(username, password);
      if (response.status === 403) {
        setError('Too many login attempts, please wait.');
        return;
      }
      const authenticationObj = await response.json();
      if (!authenticationObj.authenticated) {
        setError('Invalid username or password.');
      } else {
        handleRelocate('directory');
      }
    } catch (e) {
      setError('Login request failed. Please refresh browser.');
    }
  };

  return (
    <div className={styles.page}>
      <img
        className={styles.backgroundImage}
        src="/static/assets/loginPageBackground.png"
      ></img>
      <img
        className={styles.backgroundImagePortrait}
        src="/static/assets/loginPageBackgroundPortrait.png"
      ></img>
      <Footer></Footer>
      <div className={styles.leftSide}>
        <Icon name="traneLogo"></Icon>
        <div className={styles.divider}></div>
        <Text size="xl" color="dark-grey">
          Rental Survey Tool
        </Text>
      </div>
      <div className={styles.rightSide}>
        <div className={styles.rightSideHeader}>
          <Icon name="userV2" width="60px"></Icon>

          <Text size="ml" fontWeight="300">
            Enter your login credentials to get access
          </Text>
        </div>
        <div className={styles.rightSideContent}>
          <Input
            variant="standard"
            value={username}
            onChange={handleUserNameChange}
            placeholder="Username"
          ></Input>
          <Input
            variant="standard"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
            type="password"
          ></Input>
          {error && (
            <Text size="sm" color="red">
              {error}
            </Text>
          )}
          <Button
            style={{ marginTop: '10px', height: '40px' }}
            onClick={login}
            listenEnter={true}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginApp;
