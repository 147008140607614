import React, { ChangeEventHandler, useEffect, useState } from 'react';
import Button from '../../ui/button';
import Text from '../../ui/text';
import Icon from '../../ui/icon';
import styles from './style.module.css';
import { UseAuth } from '../../components/auth-component/auth-provider';
import { Mode } from '../../containers/design-app/design-app';

const getSelectedIcon = (iconName: string, isSelected: boolean) => {
  return isSelected ? `${iconName}Selected` : iconName;
};

interface ToolbarButtonProps {
  onToolChange?: (toolname: Mode) => void;
  overideToolSelection?: number;
}

const ToolbarComponent = ({
  onToolChange,
  overideToolSelection,
}: ToolbarButtonProps): React.ReactElement => {
  const [selectedTool, setSelectedTool] = useState<Mode>('default');

  useEffect(() => {
    if (overideToolSelection) {
      selectTool('default');
    }
  }, [overideToolSelection]);

  const selectTool = (toolName: Mode) => {
    setSelectedTool(toolName);
    if (onToolChange) {
      onToolChange(toolName);
    }
  };

  return (
    <div className={styles['toolbar-container']}>
      <div className={styles['buttons-toolbar']}>
        <Button
          onClick={() => selectTool('default')}
          variant="toolbar"
          active={'default' === selectedTool}
        >
          <Icon
            name={getSelectedIcon('moveIconV2', 'default' === selectedTool)}
            fill="white"
            height="40px"
            width="40px"
          ></Icon>
          <Text size="xs">Move</Text>
        </Button>
        <Button
          onClick={() => selectTool('connect')}
          variant="toolbar"
          active={'connect' === selectedTool}
        >
          <Icon
            name={getSelectedIcon('hosesV2', 'connect' === selectedTool)}
            fill="white"
            height="40px"
            width="auto"
          ></Icon>
          <Text size="xs">Hoses</Text>
        </Button>
        <Button
          onClick={() => selectTool('specification')}
          variant="toolbar"
          active={'specification' === selectedTool}
        >
          <Icon
            name={getSelectedIcon(
              'selectTypeIconV2',
              'specification' === selectedTool
            )}
            fill="white"
            height="40px"
            width="auto"
          ></Icon>
          <Text size="xs">Type</Text>
        </Button>
        <Button
          onClick={() => selectTool('delete')}
          variant="toolbar"
          active={'delete' === selectedTool}
        >
          <Icon
            name={getSelectedIcon('eraserIconV2', 'delete' === selectedTool)}
            fill="white"
            height="40px"
            width="auto"
          ></Icon>
          <Text size="xs">Eraser</Text>
        </Button>
        <Button
          onClick={() => selectTool('channel')}
          variant="toolbar"
          active={'channel' === selectedTool}
        >
          <Icon
            name={getSelectedIcon('headerIconV2', 'channel' === selectedTool)}
            height="40px"
            width="auto"
            iconStyle={{ marginTop: '3px' }}
            fill="white"
          ></Icon>
          <Text size="xs">Header</Text>
        </Button>
        {/* <Button
            onClick={() => selectTool('channel')}
            variant="toolbar"
            active={'channel' === selectedTool}
          >
            <Icon
              name="undoIconV2"
              height="40px"
              width="auto"
              fill="transparent"
            ></Icon>
            <Text size="xs">Undo</Text>
          </Button> */}
      </div>
    </div>
  );
};

export default ToolbarComponent;
