import React, { useEffect, useState } from 'react';
import { UseAuth } from '../auth-component/auth-provider';
import styles from './style.module.css';
import Status from '../../ui/status/status';

const ProtectedRoute = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { isAuthenticated, handleRelocate } = UseAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    redirectUnauthenticatedUsers().catch(console.error);
  }, []);

  const redirectUnauthenticatedUsers = async () => {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      handleRelocate('');
    }
    setLoading(false);
  };
  if (loading) {
    return (
      <div className={styles['page']}>
        <Status loading={true}></Status>
      </div>
    );
  }
  return <div>{children}</div>;
};

export default ProtectedRoute;
