import { noop } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useReactFlow } from 'reactflow';
import styles from './style.module.css';
import { Item, ItemIconNode } from '../../types';
import { ItemIconContext } from '../../common/context/itemIcons';
import Text from '../../ui/text';
import classNames from 'classnames';
import { getStringOrDefault } from '../../common/helpers/pdf-data-helper';
import {
  formatNumberWithUnit,
  getDiscountedDisplayPrice,
} from '../../common/helpers/number-helpers';

export interface SpecificationMenuProps {
  nodeId: string;
  top: number | false;
  left: number | false;
  right: number | false;
  bottom: number | false;
  handleItemSpecification: (
    node: ItemIconNode,
    selectedItem: Item,
    oldItem?: Item
  ) => void;
  getNode: (id: string) => ItemIconNode | undefined;
}

export const MENU_HEIGHT = 250;
export const MENU_WIDTH = 400;

export default function SpecificationMenu({
  nodeId,
  top,
  left,
  right,
  bottom,
  handleItemSpecification,
  getNode,
}: SpecificationMenuProps) {
  const [items, setItems] = useState<Item[]>([]);
  const [selectedItem, setSelectedItem] = useState<Item>();
  const { itemIconMap } = useContext(ItemIconContext);

  useEffect(() => {
    const node = getNode(nodeId);
    if (node) {
      setItems(itemIconMap[node.data.itemIcon.id].items);
      setSelectedItem(node.data.item);
    }
  }, [nodeId, itemIconMap]);

  const style = {
    top: top,
    left: left,
    right: right,
    bottom: bottom,
    maxHeight: MENU_HEIGHT,
    width: MENU_WIDTH,
    position: 'absolute',
  } as unknown as React.CSSProperties;

  const handleWheel = (event: React.WheelEvent) => {
    // Prevent the wheel event from propagating to parent elements
    event.stopPropagation();
  };

  const handleSelect = (item: Item) => () => {
    // Important to use getNode() method here otherwise we have an out of date
    // version of the node and hence the old item
    const node = getNode(nodeId);
    if (!node) {
      return;
    }
    const oldItem = { ...node?.data?.item } as Item | undefined;
    handleItemSpecification(node, item, oldItem);
    setSelectedItem(item);
  };

  return (
    <div style={style} onWheel={handleWheel} className={styles.menuContainer}>
      <div className={styles.header}>
        <Text size="m" color="white" fontWeight="200">
          Select {getNode(nodeId)?.data.itemIcon.name} specifications
        </Text>
      </div>
      <div className={styles.itemsColumn}>
        {items.map((item) => {
          const itemButtonClass = classNames({
            [styles.itemButton]: true,
            [styles.selected]: item.id === selectedItem?.id,
          });
          return (
            <div
              className={itemButtonClass}
              key={item.id}
              onClick={handleSelect(item)}
            >
              <Text size="s">{item.name}</Text>
            </div>
          );
        })}
      </div>
      <div className={styles.detailsColumn}>
        <div className={styles.detailsHeader}>
          {selectedItem ? (
            <Text fontWeight="600">{selectedItem.name}</Text>
          ) : (
            <Text> Item details</Text>
          )}
        </div>
        <Text size="s" style={{ paddingLeft: 7 }}>
          Price:
          <span style={{ fontWeight: 600 }}>
            {' '}
            £{getDiscountedDisplayPrice(selectedItem?.weekly_rental_price)}
          </span>
        </Text>
        <Text size="s" style={{ paddingLeft: 7 }}>
          Width:
          <span style={{ fontWeight: 600 }}>
            {' '}
            {formatNumberWithUnit(selectedItem?.width, 'mm', '-')}
          </span>
        </Text>
        <Text size="s" style={{ paddingLeft: 7 }}>
          Height:
          <span style={{ fontWeight: 600 }}>
            {' '}
            {formatNumberWithUnit(selectedItem?.height, 'mm', '-')}
          </span>
        </Text>
        <Text size="s" style={{ paddingLeft: 7 }}>
          Length:
          <span style={{ fontWeight: 600 }}>
            {' '}
            {formatNumberWithUnit(selectedItem?.length, 'mm', '-')}
          </span>
        </Text>
        <Text size="s" style={{ paddingLeft: 7 }}>
          Weight:
          <span style={{ fontWeight: 600 }}>
            {' '}
            {formatNumberWithUnit(selectedItem?.weight, 'kg', '-')}
          </span>
        </Text>
        <Text size="s" style={{ paddingLeft: 7 }}>
          Amps:
          <span style={{ fontWeight: 600 }}>
            {' '}
            {formatNumberWithUnit(selectedItem?.amps, 'A', '-')}
          </span>
        </Text>
      </div>
    </div>
  );
}
