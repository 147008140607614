import React, { useMemo } from 'react';
import styles from './style.module.css';

import Text from '../../ui/text';
import Icon from '../../ui/icon';
import SummarySectionCardComponent from '../summary-section-card-component';
import {
  Address,
  ClientData,
  LogisticsData,
  TransportSummaryData,
  SummarySectionProps,
} from '../../types';
import ValueCard from '../../ui/value-card';
import {
  getDiscountedDisplayPrice,
  pricingToDiscountedPrice,
} from '../../common/helpers/number-helpers';
import { isEmpty } from 'lodash';
import { VEHICLE_CONFIGS } from '../logistics-form/transport-card';

export function formatAddress(
  address?: Address | string,
  saveMode?: boolean,
  clientData?: Partial<ClientData>
): string {
  if (saveMode || typeof address === 'string') {
    if (clientData && (address === 'company' || address === 'company_site')) {
      address = clientData[address];
    } else {
      address = undefined;
    }
  }
  if (!address) {
    return 'No destination address provided.';
  }
  const { name, street_address, city, postcode, country } = address;

  const addressParts: string[] = [];

  if (street_address) {
    addressParts.push(street_address);
  }
  if (city) {
    addressParts.push(city);
  }
  if (postcode) {
    addressParts.push(postcode);
  }
  if (country) {
    addressParts.push(country);
  }

  return addressParts.join(', ');
}

export const getVehicleName = (key: string | undefined): string => {
  if (key && VEHICLE_CONFIGS.hasOwnProperty(key)) {
    return VEHICLE_CONFIGS[key].label;
  }
  return 'No vehicle selected.';
};

interface TransportSummaryCardProps {
  transport: Partial<TransportSummaryData>;
  saveMode?: boolean;
  clientData?: Partial<ClientData>;
  discount?: string;
  isLast?: boolean;
  title: string;
}

const TransportSummaryCard = ({
  transport,
  saveMode,
  clientData,
  discount,
  isLast,
  title,
}: TransportSummaryCardProps) => {
  return (
    <div className={styles.transportDetailCard}>
      <Text size="m" style={{ gridColumn: '1/3', margin: '10px 0' }}>
        {title}
      </Text>
      <Text size="m" fontWeight="600">
        Destination:
      </Text>
      <Text size="m" fontWeight="400">
        {formatAddress(transport.destination, saveMode, clientData)}
      </Text>

      <Text size="m" fontWeight="600">
        Distance:
      </Text>

      <Text size="m" fontWeight="400">
        {transport.number_of_miles} miles
      </Text>
      <Text size="m" fontWeight="600">
        Return:
      </Text>

      <Text size="m" fontWeight="400">
        {transport.is_return_trip ? 'Yes' : 'No'}
      </Text>

      <Text size="m" fontWeight="600">
        Vehicle type:
      </Text>
      <Text size="m" fontWeight="400">
        {getVehicleName(transport.vehicle_type)}
      </Text>

      <Text size="m" fontWeight="600">
        Price:
      </Text>
      <Text size="m" fontWeight="400">
        £{getDiscountedDisplayPrice(transport?.total_price, discount)}
      </Text>
      {!isLast && <div className={styles.divider}></div>}
    </div>
  );
};

interface SummaryTransportationComponentProps extends SummarySectionProps {
  logisticsData?: Partial<LogisticsData>;
  saveMode?: boolean;
  clientData?: Partial<ClientData>;
}

const SummaryTransportationComponent = ({
  logisticsData,
  saveMode,
  clientData,
  toggleOpen,
  open,
}: SummaryTransportationComponentProps) => {
  const transportDataArray = useMemo(() => {
    return Object.values(logisticsData?.transport || {});
  }, [logisticsData?.transport]);
  const noTransportMethods =
    transportDataArray.length === 0 ||
    (transportDataArray.length === 1 && isEmpty(transportDataArray[0]));
  return (
    <SummarySectionCardComponent
      toggleOpen={toggleOpen}
      open={open}
      title="Delivery"
      infoText={`£${pricingToDiscountedPrice(
        logisticsData?.transportation_cost
      )}`}
    >
      <>
        {noTransportMethods ? (
          <Text>No transport methods selected for order.</Text>
        ) : (
          transportDataArray.map((transportOption, index) => {
            return (
              <TransportSummaryCard
                transport={
                  transportOption as unknown as Partial<TransportSummaryData>
                }
                clientData={clientData}
                title={`Transport ${index + 1}`}
                saveMode={saveMode}
                discount={logisticsData?.transportation_cost?.discount}
                key={index}
                isLast={index === transportDataArray.length - 1}
              ></TransportSummaryCard>
            );
          })
        )}
        <ValueCard
          label="Total delivery price:"
          price={pricingToDiscountedPrice(logisticsData?.transportation_cost)}
          style={{ marginTop: '20px' }}
          innerStyle={{ width: '100%' }}
        ></ValueCard>
      </>
    </SummarySectionCardComponent>
  );
};

export default SummaryTransportationComponent;
