export const colorsConfig = {
  white: '#fff',
  'dark-grey': '#303030',
  'medium-grey': '#545454',
  'light-grey': '#747474',
  'darkest-blue': '#00326C',
  'dark-blue': '#00265F',
  'border-blue': '#4F95B3',
  'lighter-blue': '#4C6F98',
  'background-grey': '#F6F7F6',
  'light-background-grey': '#FAFAFA',
  'border-grey': '#D7DCE2',
  'light-orange': '#FAF5EF',
  'progress-bar-blue': '#44648d',
  'dark-orange': '#FE951D',
  'light-lightblue': '#F0F7FF',
  'dark-lightblue': '#4695F0',
  'light-red': '#FAF0F0',
  'dark-red': '#F57777',
  'error-red': '#DA2400',
  'light-green': '#EEF7F2',
  'dark-green': '#06B651',
  'background-light-blue': '#D7E5EA',
  'status-proposal': '#FE951D',
  'status-quoted': '#4695F0',
  'status-won': '#06B651',
  'status-lost': '#F57777',
  'status-background-proposal': '#FAF5EF',
  'status-background-quoted': '#F0F7FF',
  'status-background-won': '#EEF7F2',
  'status-background-lost': '#FAF0F0',
} as const;

export type ColorConfigKey = keyof typeof colorsConfig;
