import React, { useState, useEffect } from 'react';
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';
import Icon from '../../ui/icon';
import { createSharedHandleStyle } from '../../common/config/globalHandleStyle';
import { ItemIconNode } from '../../types';
import { UseAuth } from '../auth-component/auth-provider';
import { colorsConfig } from '../../common/design/colors';

const NodeChannel = (node: ItemIconNode) => {
  const [rotation, setRotation] = useState(0);
  const updateNodeInternals = useUpdateNodeInternals();
  const { isIpad } = UseAuth();

  useEffect(() => {
    if (node.data.rotation !== undefined && node.data.rotation !== rotation) {
      setRotation(node.data.rotation);
      setTimeout(() => updateNodeInternals(node.id), 20);
    }
  }, [node]);

  const rotateNode = (event: React.MouseEvent | React.TouchEvent) => {
    if (!isIpad || event.type === 'touchstart') {
      const newRotation = (rotation + 90) % 360;
      setRotation(newRotation);
      node.data.onRotation(node, newRotation);
      updateNodeInternals(node.id);
      event?.stopPropagation();
    }
  };
  const sharedHandleStyle = createSharedHandleStyle(node.data, {
    height: '30px',
    width: '10px',
  });

  return (
    <div>
      {node.data.displayHandles && (
        <button
          style={{
            position: 'absolute',
            bottom: -29,
            right: -33,
            zIndex: 1,
            backgroundColor: colorsConfig['dark-blue'],
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '50%',
            padding: '10px',
          }}
          onMouseDownCapture={rotateNode}
          onTouchStartCapture={rotateNode}
        >
          <Icon fill="white" name="rotate" height="20px" width="20px"></Icon>
        </button>
      )}
      {rotation === 0 ? (
        <div>
          <Handle
            type="source"
            position={Position.Right}
            id="a"
            style={{ ...sharedHandleStyle, top: 9, left: 43 }}
            isConnectable={node.data.displayHandles}
          />
          <Handle
            type="source"
            position={Position.Bottom}
            id="b"
            style={{
              ...sharedHandleStyle,
              width: 30,
              height: 10,
              bottom: 21,
            }}
            isConnectable={node.data.displayHandles}
          />
          <Handle
            type="source"
            position={Position.Left}
            id="c"
            style={{ ...sharedHandleStyle, top: 9, left: 2 }}
            isConnectable={node.data.displayHandles}
          />
        </div>
      ) : rotation === 90 ? (
        <div>
          <Handle
            type="source"
            position={Position.Bottom}
            id="a"
            style={{
              ...sharedHandleStyle,
              width: 30,
              height: 10,
              bottom: 13,
              left: 41,
            }}
            isConnectable={node.data.displayHandles}
          />
          <Handle
            type="source"
            position={Position.Left}
            id="b"
            style={{ ...sharedHandleStyle, top: 20, left: 12 }}
            isConnectable={node.data.displayHandles}
          />
          <Handle
            type="source"
            position={Position.Top}
            id="c"
            style={{
              ...sharedHandleStyle,
              width: 30,
              height: 10,
              top: -8,
              left: 41,
            }}
            isConnectable={node.data.displayHandles}
          />
        </div>
      ) : rotation === 180 ? (
        <div>
          <Handle
            type="source"
            position={Position.Left}
            id="a"
            style={{ ...sharedHandleStyle, top: 30, left: 3 }}
            isConnectable={node.data.displayHandles}
          />
          <Handle
            type="source"
            position={Position.Top}
            id="b"
            style={{
              ...sharedHandleStyle,
              width: 30,
              height: 10,
              top: 1,
              left: 30,
            }}
            isConnectable={node.data.displayHandles}
          />
          <Handle
            type="source"
            position={Position.Right}
            id="c"
            style={{ ...sharedHandleStyle, top: 30, left: 43 }}
            isConnectable={node.data.displayHandles}
          />
        </div>
      ) : (
        <div>
          <Handle
            type="source"
            position={Position.Top}
            id="a"
            style={{
              ...sharedHandleStyle,
              width: 30,
              height: 10,
              top: -7,
              left: 19,
            }}
            isConnectable={node.data.displayHandles}
          />
          <Handle
            type="source"
            position={Position.Right}
            id="b"
            style={{ ...sharedHandleStyle, top: 20, left: 35 }}
            isConnectable={node.data.displayHandles}
          />
          <Handle
            type="source"
            position={Position.Bottom}
            id="c"
            style={{
              ...sharedHandleStyle,
              width: 30,
              height: 10,
              bottom: 12,
              left: 19,
            }}
            isConnectable={node.data.displayHandles}
          />
        </div>
      )}
      <Icon
        name="channel"
        height="60px"
        width="60px"
        showError={node.data.showError}
        rotation={rotation}
      ></Icon>
    </div>
  );
};

export default NodeChannel;
