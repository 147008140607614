// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page__HTgDm {
  position: relative;
  display: grid;
  grid-template-rows: 100px 120px 1fr;
  height: 100dvh;
  justify-items: center;
  align-items: center;
}

.top__fxvHE {
  display: grid;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 0 40px #e9e9e9;
  z-index: 1;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-items: center;
  padding: 0 30px;
  box-sizing: border-box;
}

.middle__ObAWt {
  display: grid;
  grid-template-columns: 2fr auto;
  width: 100%;
  height: auto;
  background-color: white;
  padding: 0 30px 0 16px;
  box-sizing: border-box;
  max-width: 1400px;
}

.filters__bWZqd {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  gap: 20px;
}

.designListTopRow__yzRJH {
  display: grid;
  grid-template-columns: minmax(16%, 100px) 30% 17% 12% 10% 15%;
  padding: 0 10px 5px 16px;
  border-bottom: 0.5px solid #d7e5ea;
}

.designCount__b59Sc {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
}

.bottom__BiQ6H {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  margin-right: 10px;
  max-width: 1400px;
  padding-right: 0 5px 0 5px;
}
`, "",{"version":3,"sources":["webpack://./src/containers/search-app/style.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mCAAmC;EACnC,cAAc;EACd,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,4BAA4B;EAC5B,UAAU;EACV,oCAAoC;EACpC,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,+BAA+B;EAC/B,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,sBAAsB;EACtB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,oDAAoD;EACpD,SAAS;AACX;;AAEA;EACE,aAAa;EACb,6DAA6D;EAC7D,wBAAwB;EACxB,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":[".page {\n  position: relative;\n  display: grid;\n  grid-template-rows: 100px 120px 1fr;\n  height: 100dvh;\n  justify-items: center;\n  align-items: center;\n}\n\n.top {\n  display: grid;\n  width: 100%;\n  height: 100%;\n  background-color: white;\n  box-shadow: 0 0 40px #e9e9e9;\n  z-index: 1;\n  grid-template-columns: auto 1fr auto;\n  align-items: center;\n  justify-items: center;\n  padding: 0 30px;\n  box-sizing: border-box;\n}\n\n.middle {\n  display: grid;\n  grid-template-columns: 2fr auto;\n  width: 100%;\n  height: auto;\n  background-color: white;\n  padding: 0 30px 0 16px;\n  box-sizing: border-box;\n  max-width: 1400px;\n}\n\n.filters {\n  display: grid;\n  grid-template-columns: auto auto auto auto auto auto;\n  gap: 20px;\n}\n\n.designListTopRow {\n  display: grid;\n  grid-template-columns: minmax(16%, 100px) 30% 17% 12% 10% 15%;\n  padding: 0 10px 5px 16px;\n  border-bottom: 0.5px solid #d7e5ea;\n}\n\n.designCount {\n  display: flex;\n  justify-content: flex-start;\n  gap: 5px;\n}\n\n.bottom {\n  display: flex;\n  overflow-y: auto;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  background-color: white;\n  margin-right: 10px;\n  max-width: 1400px;\n  padding-right: 0 5px 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `page__HTgDm`,
	"top": `top__fxvHE`,
	"middle": `middle__ObAWt`,
	"filters": `filters__bWZqd`,
	"designListTopRow": `designListTopRow__yzRJH`,
	"designCount": `designCount__b59Sc`,
	"bottom": `bottom__BiQ6H`
};
export default ___CSS_LOADER_EXPORT___;
