import React, { ReactElement } from 'react';
import Text from '../text';
import styles from './style.module.css';
import { colorsConfig } from '../../common/design/colors';

interface ValueCardProps {
  label?: string;
  price?: number | string;
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
}

const ValueCard = ({
  label,
  price,
  style,
  innerStyle,
}: ValueCardProps): React.ReactElement => {
  return (
    <div className={styles.mainContainer} style={style}>
      <div className={styles.container} style={innerStyle}>
        <Text color={colorsConfig['medium-grey']} fontWeight="500" size="m">
          {label}
        </Text>
        <Text fontWeight="600" size="m" color={colorsConfig['darkest-blue']}>
          £{price}
        </Text>
      </div>
    </div>
  );
};

export default ValueCard;
