import React, { ReactComponentElement } from 'react';
import styles from './style.module.css';
import Text from '../text';
import Button from '../button';
import { noop } from 'lodash';
import { UseAuth } from '../../components/auth-component/auth-provider';
import Icon from '../icon';

interface SearchProps extends React.ComponentPropsWithoutRef<'input'> {
  value?: string;
  label?: string;
  variant?: string;
  containerVariant?: string;
  onClearInput?: () => void;
}

const Search = ({
  value = '',
  type = 'text',
  variant = 'standard',
  onChange,
  onClearInput = noop,
  placeholder,
  name,
}: SearchProps): React.ReactElement => {
  const { isIpad } = UseAuth();

  const handleOnBlur = () => {
    if (isIpad) {
      setTimeout(() => {
        // This prevents the bug that caused the PWA ipad app to occasionally freeze all inputs
        // when the keyboard was hidden.
        // Check if the newly focused element is not an input
        if (
          !(
            document?.activeElement?.tagName === 'INPUT' ||
            document?.activeElement?.tagName === 'TEXTAREA'
          )
        ) {
          // Force the page to scroll back into normal view if it isn't
          if (document.documentElement.scrollTop > 1) {
            document.documentElement.scrollTop = 100;
          }
        }
      }, 100); // Delay to allow focus to shift to the next element
    }
  };
  return (
    <div className={styles[variant]}>
      <Icon name="searchIconV2" fill="white" height="20px" width="auto"></Icon>
      <input
        value={value}
        type={type}
        onChange={onChange}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        name={name}
      ></input>
      <div className={styles.line}></div>
      <Button variant="empty" onClick={onClearInput} style={{ padding: 0 }}>
        <Icon
          name="closedIconV2"
          fill="white"
          height="16px"
          width="auto"
        ></Icon>
      </Button>
    </div>
  );
};

export default Search;
