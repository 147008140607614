import React, { ReactElement } from 'react';
import Icon from '../../ui/icon';
import Button from '../../ui/button';
import Text from '../../ui/text';
import { upperFirst } from 'lodash';
import styles from './style.module.css';

interface DesignListRowProps {
  id: string;
  designName?: string;
  referenceNumber: string | null;
  createdBy?: string;
  price?: string;
  createdAt?: string;
  email?: string;
  status?: string;
  onClick: (id: string) => void;
  goToDesign: (id: string) => void;
}

const STATUSES = ['won', 'lost', 'quoted', 'proposal'];

const DesignListRow = ({
  id,
  designName,
  referenceNumber,
  createdBy,
  price,
  createdAt,
  email,
  onClick,
  status,
  goToDesign,
}: DesignListRowProps): React.ReactElement => {
  const humanReadableStatus = upperFirst(status);

  return (
    <div className={styles.rowContainer} onClick={() => onClick(id)}>
      <div className={styles.idColumn}>
        <Text
          size="m"
          fontWeight="200"
          truncate={true}
          color={referenceNumber ? undefined : '#bbbbbb'}
        >
          {referenceNumber || 'None'}
        </Text>
      </div>
      <div className={styles.designNameColumn}>
        <Text size="m" fontWeight="400" truncate={true}>
          {designName}
        </Text>
      </div>
      <div className={styles.createdByColumn}>
        <div className={styles.emailCreatedBy}>
          <Text
            size="m"
            fontWeight="400"
            alignSelf="flex-start"
            truncate={true}
          >
            {createdBy}
          </Text>
          <a className={styles.emailLink}>{email}</a>
        </div>
      </div>
      <div className={styles.createdAtColumn}>
        <Text size="m" fontWeight="400" truncate={true}>
          {createdAt}
        </Text>
      </div>
      {!!status ? (
        <div className={styles.statusColumn + ' ' + styles[status]}>
          <Text size="s" fontWeight="400" alignSelf="center" truncate={true}>
            {humanReadableStatus}
          </Text>
        </div>
      ) : (
        <div>
          <Text
            size="s"
            fontWeight="400"
            alignSelf="center"
            truncate={true}
            textAlign="center"
          >
            -
          </Text>
        </div>
      )}
      <div className={styles.buttonColumn}>
        <Button
          onClick={() => goToDesign(id)}
          variant="primary"
          style={{ height: '35px' }}
        >
          Open design
        </Button>
      </div>
    </div>
  );
};

export default DesignListRow;
