import React, { useContext } from 'react';
import styles from './style.module.css';
import { OpenStatus, OpenStatusKey } from './summary-component';
import { DesignDataContext } from '../../common/context/designData';
import SummaryCompanyComponent from '../summary-company-component';
import SummaryRentalDatesComponent from '../summary-rental-dates-component';
import SummaryFinalPriceComponet from '../summary-final-price-component';
import SummaryNotesComponent from '../summary-notes-component';
import SummaryLabourAndTransportComponent from '../summary-labour-and-transport-component';
import SummaryWeeklyHireComponent from '../summary-weekly-hire-component/summary-weekly-hire-component';
import SummaryExtrasComponent from '../summary-extras-component';

interface InternalViewProps {
  handleToggleOpen: (openStatus: OpenStatusKey) => () => void;
  openStatus: OpenStatus;
  saveMode?: boolean;
}

const CustomerViewComponent = ({
  handleToggleOpen,
  openStatus,
  saveMode,
}: InternalViewProps) => {
  const { designData, derivedData } = useContext(DesignDataContext);

  return (
    <div>
      <SummaryCompanyComponent
        clientData={designData?.client_data}
        open={openStatus.companyOpen}
        toggleOpen={handleToggleOpen('companyOpen')}
      ></SummaryCompanyComponent>
      <SummaryNotesComponent
        designDetails={designData?.design_details}
        toggleOpen={handleToggleOpen('notesOpen')}
        open={openStatus.notesOpen}
      ></SummaryNotesComponent>
      <SummaryRentalDatesComponent
        logisticsData={designData?.logistics_data}
        saveMode={saveMode}
        toggleOpen={handleToggleOpen('rentalPeriodOpen')}
        open={openStatus.rentalPeriodOpen}
      ></SummaryRentalDatesComponent>
      <SummaryLabourAndTransportComponent></SummaryLabourAndTransportComponent>
      <SummaryExtrasComponent
        logisticsData={designData?.logistics_data}
        toggleOpen={handleToggleOpen('extrasOpen')}
        derivedData={derivedData}
        open={false}
        canOpen={false}
      ></SummaryExtrasComponent>
      <SummaryWeeklyHireComponent></SummaryWeeklyHireComponent>
      <SummaryFinalPriceComponet
        logisticsData={designData?.logistics_data}
        toggleOpen={handleToggleOpen('finalPriceOpen')}
        open={false}
        canOpen={false}
      ></SummaryFinalPriceComponet>
    </div>
  );
};

export default CustomerViewComponent;
