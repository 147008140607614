import React, { useContext } from 'react';
import Text from '../../ui/text';
import styles from './style.module.css';
import './style.css';
import { DesignDataContext } from '../../common/context/designData';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import ValueCard from '../../ui/value-card';
import {
  formatPrice,
  pricingToDiscountedPrice,
} from '../../common/helpers/number-helpers';
import { convertDateFormat } from '../../common/helpers/date-helpers';
import NumberInput from '../../ui/number-input';
import Icon from '../../ui/icon';
import classNames from 'classnames';

const RentalPeriodForm = () => {
  const { designData, updateLogisticsDataValue } =
    useContext(DesignDataContext);
  const handleDateChange = (date: Date | null, fieldName: string) => {
    if (!date) {
      return;
    }
    const stringDate = date.toISOString();
    updateLogisticsDataValue(fieldName, stringDate);
  };

  const handleWeeksInput = (weeks: number) => {
    if (weeks > 3000) {
      return;
    }
    updateLogisticsDataValue('number_of_weeks', weeks);
  };

  const endDateClass = classNames({
    [styles.dateBox]: true,
    [styles.disabled]: !designData.logistics_data.end_date,
  });

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.column}>
            <div className={styles.title}>
              <Text size="ms" variant="standard">
                Start date
              </Text>
            </div>
            <div className={styles.dateBox} style={{ marginBottom: '4px' }}>
              <Icon
                name="calendarV2"
                width="16px"
                height="auto"
                fill="white"
              ></Icon>
              {designData.logistics_data.start_date ? (
                <Text>
                  {convertDateFormat(designData.logistics_data.start_date)}
                </Text>
              ) : (
                <Text size="xs" color="light-grey">
                  Select a date
                </Text>
              )}
            </div>
            <div className="date-picker-container">
              <DatePicker
                dateFormat="yyyy/MM/dd"
                inline
                selected={
                  designData.logistics_data?.start_date
                    ? new Date(designData.logistics_data?.start_date)
                    : null
                }
                onChange={(date) => handleDateChange(date, 'start_date')}
              />
            </div>
          </div>

          <div className={styles.column}>
            <div className={styles.title}>
              <Text
                size="ms"
                variant="standard"
                style={{ whiteSpace: 'nowrap' }}
              >
                Number of weeks
              </Text>
            </div>
            <NumberInput
              value={Number(designData.logistics_data?.number_of_weeks || 0)}
              onChange={handleWeeksInput}
              max={3000}
            ></NumberInput>
          </div>

          <div className={styles.column}>
            <div className={styles.title}>
              <Text
                size="ms"
                variant="standard"
                style={{ whiteSpace: 'nowrap' }}
              >
                End date
              </Text>
            </div>
            <div className={endDateClass} style={{ marginBottom: '4px' }}>
              <Icon
                name="calendarV2"
                width="16px"
                height="auto"
                fill="white"
              ></Icon>
              {designData.logistics_data.end_date && (
                <Text>
                  {convertDateFormat(designData.logistics_data.end_date)}
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>
      <ValueCard
        price={pricingToDiscountedPrice(
          designData.logistics_data.equipment_cost
        )}
        label="Total equipment price:"
      ></ValueCard>
    </>
  );
};

export default RentalPeriodForm;
