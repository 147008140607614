import { ItemIcon, PartType, PartTypeNames } from '../../types';

// This will be the one truth for what partTypes exist and what don't

export const SYSTEM_IN_ITEM = {
  componentConfigName: 'systemIn',
  id: '99999',
  name: 'SYSTEM IN',
  items: [],
};

export const SYSTEM_OUT_ITEM = {
  componentConfigName: 'systemOut',
  id: '99998',
  name: 'SYSTEM OUT',
  items: [],
};

export const CHANNEL_ICON = {
  componentConfigName: 'channel' as PartTypeNames,
  id: '99997',
  name: 'CHANNEL POINT',
  items: [],
};

export const PART_TYPES: Record<PartTypeNames, PartType> = {
  singlePump: {
    iconName: 'singlePump',
    componentName: 'NodeSinglePump',
    nodeName: 'nodeSinglePump',
  },
  bufferTank: {
    iconName: 'bufferTank',
    componentName: 'NodeBufferTank',
    nodeName: 'nodeBufferTank',
  },
  airCooledModularScrew: {
    iconName: 'airCooledModularScrew',
    componentName: 'NodeAirCooledModularScrew',
    nodeName: 'nodeAirCooledModularScrew',
  },
  airCooledScrew: {
    iconName: 'airCooledScrew',
    componentName: 'NodeAirCooledScrew',
    nodeName: 'nodeAirCooledScrew',
  },
  airHandlingUnit: {
    iconName: 'airHandlingUnit',
    componentName: 'NodeAirHandlingUnit',
    nodeName: 'nodeAirHandlingUnit',
  },
  coldStore: {
    iconName: 'coldStore',
    componentName: 'NodeColdStore',
    nodeName: 'nodeColdStore',
  },
  dryAirCooler: {
    iconName: 'dryAirCooler',
    componentName: 'NodeDryAirCooler',
    nodeName: 'nodeDryAirCooler',
  },
  fanCooledUnit: {
    iconName: 'fanCooledUnit',
    componentName: 'NodeFanCooledUnit',
    nodeName: 'nodeFanCooledUnit',
  },
  lowTempCooler: {
    iconName: 'lowTempCooler',
    componentName: 'NodeLowTempCooler',
    nodeName: 'nodeLowTempCooler',
  },
  plateHeatExchanger: {
    iconName: 'plateHeatExchanger',
    componentName: 'NodePlateHeatExchanger',
    nodeName: 'nodePlateHeatExchanger',
  },
  remoteConnectivityBox: {
    iconName: 'remoteConnectivityBox',
    componentName: 'NodeRemoteConnectivityBox',
    nodeName: 'nodeRemoteConnectivityBox',
  },
  roofTopAirHandler: {
    iconName: 'roofTopAirHandler',
    componentName: 'NodeRoofTopAirHandler',
    nodeName: 'nodeRoofTopAirHandler',
  },
  scrollCompressor: {
    iconName: 'scrollCompressor',
    componentName: 'NodeScrollCompressor',
    nodeName: 'nodeScrollCompressor',
  },
  twinPump: {
    iconName: 'twinPump',
    componentName: 'NodeTwinPump',
    nodeName: 'nodeTwinPump',
  },
  waterCooledScrew: {
    iconName: 'waterCooledScrew',
    componentName: 'NodeWaterCooledScrew',
    nodeName: 'nodeWaterCooledScrew',
  },
  systemIn: {
    iconName: 'systemIn',
    componentName: 'NodeSystemIn',
    nodeName: 'nodeSystemIn',
  },
  systemOut: {
    iconName: 'systemOut',
    componentName: 'NodeSystemOut',
    nodeName: 'nodeSystemOut',
  },
  channel: {
    iconName: 'channel',
    componentName: 'NodeChannel',
    nodeName: 'nodeChannel',
  },
};

export const ACCESSORIES_HOSES_CONNECTORS_ADAPTERS = {
  name: 'Accessories: Hoses, Connectors, Adapters',
  unitSeries: ['HOSE', 'GASKET', 'CONNECTOR', 'ADAPTER', 'FLANGE'],
};

export const ACCESSORIES_VALVES_MANIFOLDS_REDUCERS = {
  name: 'Accessories: Valves, Manifolds, Reducers',
  unitSeries: ['VALVE', 'MANIFOLD', 'REDUCER'],
};

export const ACCESSORIES_ELECTRICS = {
  name: 'Accessories: Electrics',
  unitSeries: ['ELECTRICS'],
};

export const ACCESSORIES_OTHERS = {
  name: 'Accessories: Others',
  unitSeries: ['ACOUSTIC', 'GLYCOL', 'DUCT', 'END CAP', 'FILTER'],
};

export const ADDITIONAL_ITEMS_CONFIGS = {
  hosesConnectorsAdapters: ACCESSORIES_HOSES_CONNECTORS_ADAPTERS,
  valvesManifoldsReducers: ACCESSORIES_VALVES_MANIFOLDS_REDUCERS,
  electrics: ACCESSORIES_ELECTRICS,
  otherAccessories: ACCESSORIES_OTHERS,
};

export const ROTATABLE_NODES = ['singlePump', 'twinPump', 'channel'];

// Generally this just means nodes that don't have item selection
export const SPECIAL_NODES = ['systemIn', 'systemOut', 'channel'];
