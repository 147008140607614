// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otherWeeklyRentalsContainer__CV7qQ {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 12px;
  margin-top: 20px;
}

.divider__MmoYF {
  grid-column: 1/-1;
  background-color: var(--background-light-blue);
  width: 100%;
  height: 1px;
  margin: 0px 0;
}

.oneOffItemsContainer__H6opV {
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-column-gap: 12px;
  grid-template-rows: auto;
  margin-top: 20px;
}

.spacer__JrYO_ {
  grid-column: 1/-1;
  height: 1px;
  margin: 5px 0;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/summary-extras-component/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kCAAkC;EAClC,wBAAwB;EACxB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,8CAA8C;EAC9C,WAAW;EACX,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,WAAW;AACb","sourcesContent":[".otherWeeklyRentalsContainer {\n  display: grid;\n  grid-template-columns: 3fr 3fr 1fr;\n  grid-template-rows: auto;\n  grid-column-gap: 12px;\n  margin-top: 20px;\n}\n\n.divider {\n  grid-column: 1/-1;\n  background-color: var(--background-light-blue);\n  width: 100%;\n  height: 1px;\n  margin: 0px 0;\n}\n\n.oneOffItemsContainer {\n  display: grid;\n  grid-template-columns: 6fr 1fr;\n  grid-column-gap: 12px;\n  grid-template-rows: auto;\n  margin-top: 20px;\n}\n\n.spacer {\n  grid-column: 1/-1;\n  height: 1px;\n  margin: 5px 0;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"otherWeeklyRentalsContainer": `otherWeeklyRentalsContainer__CV7qQ`,
	"divider": `divider__MmoYF`,
	"oneOffItemsContainer": `oneOffItemsContainer__H6opV`,
	"spacer": `spacer__JrYO_`
};
export default ___CSS_LOADER_EXPORT___;
