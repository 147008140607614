import React, { useState, useEffect } from 'react';
import Text from '../../ui/text';
import styles from './style.module.css';
import Input from '../../ui/input';
import { Address } from '../../types';

interface CompanyFormProps {
  title: string;
  nameLabel: string;
  fieldName: string;
  onFormChange: (formValues: Partial<Address>, fieldName: string) => void;
  formValues?: Partial<Address>;
}

const CompanyForm = ({
  title,
  nameLabel,
  fieldName,
  onFormChange,
  formValues,
}: CompanyFormProps): React.ReactElement => {
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const keyToUpdate = event.target.name;
    onFormChange({ ...formValues, [keyToUpdate]: value }, fieldName);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.infoCompanyContainer}>
        <div className={styles.formRow}>
          <Text size="m" variant="standard">
            {nameLabel}
          </Text>
        </div>

        <div className={styles.inputContainer}>
          <Input
            variant="standard"
            containerVariant="standard-container"
            value={formValues?.name || ''}
            onChange={handleInput}
            name="name"
          ></Input>
        </div>

        <div className={styles.formRowTwo}>
          <Text size="m" variant="standard">
            Street address
          </Text>
          <Text size="m" variant="standard">
            City
          </Text>
        </div>

        <div className={styles.inputContainerTwo}>
          <Input
            variant="standard"
            containerVariant="standard-container"
            value={formValues?.street_address || ''}
            onChange={handleInput}
            name="street_address"
          ></Input>
          <Input
            variant="standard"
            containerVariant="standard-container"
            value={formValues?.city || ''}
            onChange={handleInput}
            name="city"
          ></Input>
        </div>

        <div className={styles.formRowThree}>
          <Text size="m" variant="standard">
            Country
          </Text>
          <Text size="m" variant="standard">
            Postcode
          </Text>
        </div>

        <div className={styles.inputContainerThree}>
          <Input
            variant="standard"
            containerVariant="standard-container"
            value={formValues?.country || ''}
            onChange={handleInput}
            name="country"
          ></Input>
          <Input
            variant="standard"
            containerVariant="standard-container"
            value={formValues?.postcode || ''}
            onChange={handleInput}
            name="postcode"
          ></Input>
        </div>
      </div>
    </div>
  );
};

export default CompanyForm;
