// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text__DvFwB {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: var(--font-size);
  align-self: var(--align-self);
  text-align: var(--text-align);
  line-height: var(--line-height);
  max-width: var(--max-width);
  color: var(--color);
  font-weight: var(--font-weight);
  margin: 0;
  letter-spacing: 0.5px;

  --print-font-size: var(--print-font-size, unset);
}

.truncate__uP6Kj {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.title__Bnnrl {
  font-weight: 500;
  color: #3f3f3f;
}

.light__qGo0e {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: var(--font-size);
  align-self: var(--align-self);
  line-height: var(--line-height);
  color: var(--color);
  margin: 0;
  font-weight: 200;
}

@media print {
  .text__DvFwB {
    font-size: var(--print-font-size, --font-size);
  }
}
`, "",{"version":3,"sources":["webpack://./src/ui/text/style.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,2BAA2B;EAC3B,6BAA6B;EAC7B,6BAA6B;EAC7B,+BAA+B;EAC/B,2BAA2B;EAC3B,mBAAmB;EACnB,+BAA+B;EAC/B,SAAS;EACT,qBAAqB;;EAErB,gDAAgD;AAClD;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yCAAyC;EACzC,2BAA2B;EAC3B,6BAA6B;EAC7B,+BAA+B;EAC/B,mBAAmB;EACnB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE;IACE,8CAA8C;EAChD;AACF","sourcesContent":[".text {\n  font-family: 'Helvetica Neue', sans-serif;\n  font-size: var(--font-size);\n  align-self: var(--align-self);\n  text-align: var(--text-align);\n  line-height: var(--line-height);\n  max-width: var(--max-width);\n  color: var(--color);\n  font-weight: var(--font-weight);\n  margin: 0;\n  letter-spacing: 0.5px;\n\n  --print-font-size: var(--print-font-size, unset);\n}\n\n.truncate {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 100%;\n}\n\n.title {\n  font-weight: 500;\n  color: #3f3f3f;\n}\n\n.light {\n  font-family: 'Helvetica Neue', sans-serif;\n  font-size: var(--font-size);\n  align-self: var(--align-self);\n  line-height: var(--line-height);\n  color: var(--color);\n  margin: 0;\n  font-weight: 200;\n}\n\n@media print {\n  .text {\n    font-size: var(--print-font-size, --font-size);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `text__DvFwB`,
	"truncate": `truncate__uP6Kj`,
	"title": `title__Bnnrl`,
	"light": `light__qGo0e`
};
export default ___CSS_LOADER_EXPORT___;
