import React, { Fragment, useContext, useMemo } from 'react';
import Text from '../../ui/text';
import styles from './style.module.css';
import { DesignDataContext } from '../../common/context/designData';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './react-tabs.css';
import {
  cleanNumberInput,
  formatNumberPrice,
  formatPrice,
} from '../../common/helpers/number-helpers';
import { OneOffItem, OtherWeeklyRental } from '../../types';
import { nanoid } from 'nanoid';
import Input from '../../ui/input';
import Icon from '../../ui/icon';
import Button from '../../ui/button';
import ValueCard from '../../ui/value-card';

export const OtherWeeklyRentalsForm = () => {
  const { updateLogisticsDataValue, designData } =
    useContext(DesignDataContext);

  const otherWeeklyRentalsArray = useMemo(() => {
    return Object.entries(designData.logistics_data.other_weekly_rentals || {});
  }, [designData.logistics_data.other_weekly_rentals]);

  const otherWeeklyRentals = useMemo(() => {
    return designData.logistics_data.other_weekly_rentals || {};
  }, [designData.logistics_data.other_weekly_rentals]);

  const handlePriceInput = (
    event: React.FormEvent<HTMLInputElement>,
    key: string
  ) => {
    const price = cleanNumberInput(event.currentTarget.value);
    if (Number(price) > 999999999) {
      return;
    }
    handleOneOffChange({ weekly_rental_price: price }, key);
  };

  const calculateTotalItemPrice = (item: OtherWeeklyRental): string => {
    const numberPrice =
      (Number(item.weekly_rental_price) || 0) *
      (designData.logistics_data.number_of_weeks || 0);
    return formatNumberPrice(numberPrice);
  };

  const handleOneOffChange = (
    partOneOffItem: Partial<OtherWeeklyRental>,
    key: string
  ) => {
    const newOtherWeeklyRentals = {
      ...otherWeeklyRentals,
      [key]: {
        ...otherWeeklyRentals[key],
        ...partOneOffItem,
      },
    };
    updateLogisticsDataValue('other_weekly_rentals', newOtherWeeklyRentals);
  };

  const addRow = () => {
    updateLogisticsDataValue('other_weekly_rentals', {
      ...otherWeeklyRentals,
      [nanoid()]: {
        name: '',
        weekly_rental_price: '',
      },
    });
  };

  const deleteRow = (id: string) => () => {
    const newRows = { ...otherWeeklyRentals };
    delete newRows[id];
    updateLogisticsDataValue('other_weekly_rentals', newRows);
  };

  return (
    <>
      <div className={styles.otherWeeklyRentalsContainer}>
        <Text
          size="md"
          color="'dark-grey"
          fontWeight="500"
          style={{ margin: 6 }}
        >
          Item
        </Text>
        <Text size="md" color="'dark-grey" fontWeight="500">
          Weekly price
        </Text>
        <Text
          size="md"
          color="'dark-grey"
          fontWeight="500"
          style={{ gridColumnStart: 3, gridColumnEnd: 5 }}
        >
          Price
        </Text>
        <div className={styles.divider}></div>
        {otherWeeklyRentalsArray.map(([key, item], index) => {
          const totalPrice = calculateTotalItemPrice(item);
          return (
            <Fragment key={key}>
              <Input
                placeholder={`Item ${index + 1}`}
                style={{ width: '80%' }}
                value={item.name || ''}
                onChange={(event) =>
                  handleOneOffChange({ name: event?.currentTarget?.value }, key)
                }
              ></Input>
              <Input
                style={{ paddingLeft: 24, width: '50%' }}
                icon={<Text fontWeight="600">£</Text>}
                value={item.weekly_rental_price || ''}
                onChange={(event) => handlePriceInput(event, key)}
              ></Input>
              <Text>£{totalPrice}</Text>
              <Button variant="empty" onClick={deleteRow(key)}>
                <Icon name="binIconV2" height="25px" width="auto"></Icon>
              </Button>
              {index !== otherWeeklyRentalsArray.length - 1 && (
                <div className={styles.rowDivider}></div>
              )}
            </Fragment>
          );
        })}
      </div>
      <ValueCard
        price={formatPrice(
          designData.logistics_data.other_weekly_rentals_cost?.price
        )}
        label="Total price"
      ></ValueCard>
      <Button onClick={addRow} variant="secondary" style={{ marginTop: 30 }}>
        <Icon name="addIconV2" fill="white" width="22px" height="22px"></Icon>
        Add other weekly rental
      </Button>
    </>
  );
};

export const OneOffItemsForm = () => {
  const { updateLogisticsDataValue, getOneOffItems, designData } =
    useContext(DesignDataContext);

  const oneOffItemsArray = useMemo(() => {
    return Object.entries(getOneOffItems());
  }, [designData.logistics_data.one_off_items]);

  const handlePriceInput = (
    event: React.FormEvent<HTMLInputElement>,
    key: string
  ) => {
    const price = cleanNumberInput(event.currentTarget.value);
    if (Number(price) > 999999999) {
      return;
    }
    handleOneOffChange({ price: price }, key);
  };

  const handleOneOffChange = (
    partOneOffItem: Partial<OneOffItem>,
    key: string
  ) => {
    const oneOffItems = getOneOffItems();
    const newOneOffItems = {
      ...oneOffItems,
      [key]: {
        ...oneOffItems[key],
        ...partOneOffItem,
      },
    };
    updateLogisticsDataValue('one_off_items', newOneOffItems);
  };

  const addRow = () => {
    updateLogisticsDataValue('one_off_items', {
      ...getOneOffItems(),
      [nanoid()]: {
        name: '',
        price: '',
      },
    });
  };

  const deleteRow = (id: string) => () => {
    const newRows = { ...getOneOffItems() };
    delete newRows[id];
    updateLogisticsDataValue('one_off_items', newRows);
  };

  return (
    <>
      <div className={styles.oneOffItemsContainer}>
        <Text
          size="md"
          color="'dark-grey"
          fontWeight="500"
          style={{ margin: 6 }}
        >
          Item
        </Text>
        <Text
          size="md"
          color="'dark-grey"
          fontWeight="500"
          style={{ gridColumnStart: 2, gridColumnEnd: 4 }}
        >
          Price
        </Text>
        <div className={styles.divider}></div>
        {oneOffItemsArray.map(([key, item], index) => {
          return (
            <Fragment key={key}>
              <Input
                placeholder={`Item ${index + 1}`}
                style={{ width: '80%' }}
                value={item.name || ''}
                onChange={(event) =>
                  handleOneOffChange({ name: event?.currentTarget?.value }, key)
                }
              ></Input>
              <Input
                style={{ paddingLeft: 24, width: '60%' }}
                icon={<Text fontWeight="600">£</Text>}
                value={item.price || ''}
                onChange={(event) => handlePriceInput(event, key)}
              ></Input>
              <Button variant="empty" onClick={deleteRow(key)}>
                <Icon name="binIconV2" height="25px" width="auto"></Icon>
              </Button>
              {index !== oneOffItemsArray.length - 1 && (
                <div className={styles.rowDivider}></div>
              )}
            </Fragment>
          );
        })}
      </div>
      <ValueCard
        price={formatPrice(designData.logistics_data.one_off_items_cost?.price)}
        label="Total price"
      ></ValueCard>
      <Button onClick={addRow} variant="secondary" style={{ marginTop: 30 }}>
        <Icon name="addIconV2" fill="white" width="22px" height="22px"></Icon>
        Add one off item
      </Button>
    </>
  );
};

const ExtrasForm = () => {
  return (
    <div className={styles.tabsContainer}>
      <Tabs>
        <TabList>
          <div className="tabs-container">
            <Tab>Other weekly rentals</Tab>
            <Tab>One off items</Tab>
          </div>
        </TabList>
        <TabPanel>
          <OtherWeeklyRentalsForm></OtherWeeklyRentalsForm>
        </TabPanel>
        <TabPanel>
          <OneOffItemsForm></OneOffItemsForm>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ExtrasForm;
