// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transportDetailCard__djUiN {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-row-gap: 10px;
}

.divider__s4e7Y {
  grid-column: 1 / 3;
  background-color: var(--background-light-blue);
  width: 100%;
  height: 1px;
  margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/summary-transportation-component/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,8CAA8C;EAC9C,WAAW;EACX,WAAW;EACX,cAAc;AAChB","sourcesContent":[".transportDetailCard {\n  display: grid;\n  grid-template-columns: 120px 1fr;\n  grid-row-gap: 10px;\n}\n\n.divider {\n  grid-column: 1 / 3;\n  background-color: var(--background-light-blue);\n  width: 100%;\n  height: 1px;\n  margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transportDetailCard": `transportDetailCard__djUiN`,
	"divider": `divider__s4e7Y`
};
export default ___CSS_LOADER_EXPORT___;
