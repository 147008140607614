// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer__FLuT1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.container__yaj6h {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  padding-bottom: 20px;
  -moz-column-gap: 40px;
       column-gap: 40px;
}

.column__wRf5B {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
}

.title__vMm8V {
  display: flex;
  align-items: start;
  margin-bottom: 3px;
}

.dateBox__jjPNT {
  display: flex;
  gap: 8px;
  width: calc(100% - 30px);
  font-family: 'Helvetica Neue', sans-serif;
  color: var(--dark-grey);
  border: none;
  height: 35px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid var(--border-blue);
  padding: 0 10px;
  font-size: 16px;
  align-items: center;
  min-width: 140px;
}

.dateBox__jjPNT.disabled__r3hD5 {
  background-color: var(--background-grey);
  border: 1px solid var(--border-grey);
}
`, "",{"version":3,"sources":["webpack://./src/components/rental-period-form/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,6CAA6C;EAC7C,oBAAoB;EACpB,qBAAgB;OAAhB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,wBAAwB;EACxB,yCAAyC;EACzC,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,oCAAoC;EACpC,eAAe;EACf,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;EACxC,oCAAoC;AACtC","sourcesContent":[".mainContainer {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n}\n\n.container {\n  display: grid;\n  grid-template-columns: repeat(3, min-content);\n  padding-bottom: 20px;\n  column-gap: 40px;\n}\n\n.column {\n  display: flex;\n  align-items: flex-start;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.title {\n  display: flex;\n  align-items: start;\n  margin-bottom: 3px;\n}\n\n.dateBox {\n  display: flex;\n  gap: 8px;\n  width: calc(100% - 30px);\n  font-family: 'Helvetica Neue', sans-serif;\n  color: var(--dark-grey);\n  border: none;\n  height: 35px;\n  border-radius: 5px;\n  background-color: white;\n  border: 1px solid var(--border-blue);\n  padding: 0 10px;\n  font-size: 16px;\n  align-items: center;\n  min-width: 140px;\n}\n\n.dateBox.disabled {\n  background-color: var(--background-grey);\n  border: 1px solid var(--border-grey);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `mainContainer__FLuT1`,
	"container": `container__yaj6h`,
	"column": `column__wRf5B`,
	"title": `title__vMm8V`,
	"dateBox": `dateBox__jjPNT`,
	"disabled": `disabled__r3hD5`
};
export default ___CSS_LOADER_EXPORT___;
