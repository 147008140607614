import React, { useContext } from 'react';
import styles from './style.module.css';

import Text from '../../ui/text';
import Icon from '../../ui/icon';
import SummarySectionCardComponent from '../summary-section-card-component';
import { DesignDataContext } from '../../common/context/designData';

const SummaryWeeklyHireComponent = () => {
  const { derivedData } = useContext(DesignDataContext);
  return (
    <SummarySectionCardComponent
      canOpen={false}
      open={false}
      title="Weekly hire"
      infoText={{
        lineOne: `Weekly price: £${derivedData.equipmentWeeklyPrice}`,
        lineTwo: `Total price: £${derivedData.equipmentPrice}`,
      }}
      header={
        <div className={styles['summary-main-container']}>
          <div className={styles['summary-header-container-alone']}>
            <div className={styles['summary-title-container']}>
              <Text fontWeight="500" size="lg">
                Weekly Hire
              </Text>
            </div>
          </div>
          <div className={styles['second-row-container']}>
            <div className={styles['box-left']}>
              <Text size="md" fontWeight="500">
                Weekly Price: £{derivedData.equipmentWeeklyPrice}
              </Text>
            </div>

            <div className={styles['box-right']}>
              <Text size="md" fontWeight="500">
                Total Price: £{derivedData.equipmentPrice}
              </Text>
            </div>
          </div>
        </div>
      }
    ></SummarySectionCardComponent>
  );
};

export default SummaryWeeklyHireComponent;
