// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info__UxFzL {
  display: flex;
  gap: 8px;
  background: #c6eaf1;
  padding: 7px 10px;
  border-radius: 4px;
  border: 1px solid #1979ec;
  align-items: center;
}

.error__S4K44 {
  display: flex;
  gap: 8px;
  background: #f5e3d8;
  padding: 7px 10px;
  border-radius: 4px;
  border: 1px solid #e76b2f;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/ui/toast-message/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".info {\n  display: flex;\n  gap: 8px;\n  background: #c6eaf1;\n  padding: 7px 10px;\n  border-radius: 4px;\n  border: 1px solid #1979ec;\n  align-items: center;\n}\n\n.error {\n  display: flex;\n  gap: 8px;\n  background: #f5e3d8;\n  padding: 7px 10px;\n  border-radius: 4px;\n  border: 1px solid #e76b2f;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `info__UxFzL`,
	"error": `error__S4K44`
};
export default ___CSS_LOADER_EXPORT___;
