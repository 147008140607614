import { SelectedItems } from '../../types';
import { formatNumberPrice } from './number-helpers';

/**
 * This function takes an object of selected items and an array of unit series names,
 * and returns a new object containing only the selected items whose unit series names are in the array
 * and do not have an icon.
 * @param selectedItems An object containing selected items, with keys being item IDs and values being objects with item details
 * @param unitSeries An array of unit series names to filter by
 * @returns An object containing only the selected items whose unit series names are in the unitSeries array and do not have an icon
 */
export const filterAdditionalItems = (
  selectedItems: SelectedItems,
  unitSeries: string[]
): SelectedItems => {
  // Use Object.entries() to convert the selectedItems object to an array of [key, value] pairs
  // Use the Array.filter() method to remove any entries whose unit series name is not in the unitSeries array
  // and whose "has_icon" property is true
  const filteredEntries = Object.entries(selectedItems).filter(
    ([_, item]) => unitSeries.includes(item.unit_series_name) && !item.has_icon
  );
  // Use Object.fromEntries() to convert the filtered array of [key, value] pairs back to an object
  return Object.fromEntries(filteredEntries);
};

export const filterUnitItems = (
  selectedItems?: SelectedItems
): SelectedItems => {
  if (!selectedItems) return {};
  // Use Object.entries() to convert the selectedItems object to an array of [key, value] pairs
  // Use the Array.filter() method to remove any entries whose "has_icon" property is false
  const filteredEntries = Object.entries(selectedItems).filter(
    ([_, item]) => item.has_icon
  );
  // Use Object.fromEntries() to convert the filtered array of [key, value] pairs back to an object
  return Object.fromEntries(filteredEntries);
};

export const getSelectedItemsWeeklyRentalPrice = (
  selectedItems: SelectedItems
): number => {
  // Use Object.values() to convert the selectedItems object to an array of values
  // Use the Array.reduce() method to sum the prices of all the items
  return Object.values(selectedItems).reduce(
    (total, item) =>
      Number(total) + Number(item.weekly_rental_price) * Number(item.quantity),
    0
  );
};
