import React, { useEffect, useMemo, useState } from 'react';
import { ItemIconDetail } from '../../types';
import {
  PART_TYPES,
  SYSTEM_IN_ITEM,
  SYSTEM_OUT_ITEM,
} from '../config/partTypes';

type ItemIconContextType = {
  itemIcons: ItemIconDetail[];
  itemIconMap: Record<string, ItemIconDetail>;
  itemIconsLoading: boolean;
  itemIconsError: boolean;
};

export const ItemIconContext = React.createContext<ItemIconContextType>({
  itemIcons: [],
  itemIconMap: {},
  itemIconsLoading: true,
  itemIconsError: false,
});

export const ItemIconProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [itemIcons, setItemIcons] = useState<ItemIconDetail[]>([]);
  const [itemIconsLoading, setItemIconsLoading] = useState(true);
  const [itemIconsError, setItemIconsError] = useState(true);

  useEffect(() => {
    fetchItemIcons();
  }, []);

  const itemIconMap = useMemo(() => {
    return itemIcons?.reduce((itemIconMap, itemIcon) => {
      return {
        ...itemIconMap,
        [itemIcon.id]: itemIcon,
      };
    }, {});
  }, [itemIcons]);

  const fetchItemIcons = async (): Promise<void> => {
    setItemIconsLoading(true);
    setItemIconsError(false);
    const response = await fetch('/api/items/item-icons/');
    if (!response.ok) {
      // Handle this
      setItemIconsError(true);
      return;
    }
    const data = (await response.json()) as ItemIconDetail[];
    const dataWithSystem = [
      ...data,
      SYSTEM_IN_ITEM,
      SYSTEM_OUT_ITEM,
    ] as ItemIconDetail[];
    const result = dataWithSystem.map((itemIcon) => ({
      ...itemIcon,
      partType: PART_TYPES[itemIcon.componentConfigName],
    }));
    result.filter((itemIcon) => {
      if (!itemIcon.partType) {
        console.error(
          `No partType config found for ${itemIcon.componentConfigName}.`
        );
      }
      return !!itemIcon.partType;
    });
    setItemIcons(result);
    setItemIconsLoading(false);
  };

  return (
    <ItemIconContext.Provider
      value={{
        itemIcons,
        itemIconMap,
        itemIconsLoading,
        itemIconsError,
      }}
    >
      {children}
    </ItemIconContext.Provider>
  );
};
